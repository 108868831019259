import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SignedInGuard } from './guards/signed-in/signed-in.guard';
import { EmailStateGuard } from './guards/email-state/email-state.guard';
import { SignInPage } from './pages/sign-in/sign-in.page';
import { ForgotPasswordPage } from './pages/forgot-password/forgot-password.page';
import { LogoutPage } from './pages/logout/logout.page';
import { AboutPage } from './pages/about/about.page';


const routes: Routes = [
	{
		path       : '',
		redirectTo : 'signin',
		pathMatch  : 'full',
	},
	{
		path        : 'signin',
		canActivate : [
			SignedInGuard,
		],
		component : SignInPage,
	},
	{
		path        : 'forgot-password',
		canActivate : [
			SignedInGuard,
			EmailStateGuard,
		],
		component : ForgotPasswordPage,
	},
	{
		path      : 'logout',
		component : LogoutPage,
	},
	{
		path      : 'about',
		component : AboutPage,
	},
	{
		path         : '',
		loadChildren : () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
	},
];
@NgModule({
	imports : [
		RouterModule.forRoot(routes, { relativeLinkResolution : 'legacy' }),
	],
	exports : [
		RouterModule,
	],
})
export class AppRoutingModule {}
