import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ThemeColors } from '../../../constants/ThemeColors';
import { Slots } from '../../../constants/Slots';


@Component({
	selector    : 'app-toggle',
	templateUrl : './toggle.component.html',
	styleUrls   : ['./toggle.component.scss'],
})

export class ToggleComponent {
	@Input() slot?     : Slots;
	@Input() checked?  : boolean = false;
	@Input() color?    : ThemeColors = ThemeColors.SUCCESS;
	@Input() disabled? : boolean = false;

	@Output() change = new EventEmitter<any>();


	constructor() {}


	didToggle(event : Event) : void {
		this.change.emit(event);
	}
}
