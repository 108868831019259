// an array of all price bucket tags
const priceBucketTags : string[] = [
	'under $50',
	'$50 - $100',
	'$100 - $250',
	'$250 - $500',
	'$500 - $1000',
	'over $1000',
];

/**
 * A function that parses the appropriate price buckets for a given price
 * @param price: a number, in dollars, for the cost of the item
 * Returns: an array of price bucket tag strings
 */
export const GetPriceBuckets = (price : number) : string[] => {
	// prices can be in multiple price buckets (i.e. $100, $250, $500)
	const buckets : string[] = [];

	if (price < 50) {
		buckets.push(priceBucketTags[0]);
	}

	if (price >= 50 && price <= 100) {
		buckets.push(priceBucketTags[1]);
	}

	if (price >= 100 && price <= 250) {
		buckets.push(priceBucketTags[2]);
	}

	if (price >= 250 && price <= 500) {
		buckets.push(priceBucketTags[3]);
	}

	if (price >= 500 && price <= 1000) {
		buckets.push(priceBucketTags[4]);
	}

	if (price > 1000) {
		buckets.push(priceBucketTags[5]);
	}

	return buckets;
};
