// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
	production                : false,
	googleAnalyticsPropertyId : 'G-BBJPCE2DHD',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

export const firebaseConfig = {
	apiKey            : 'AIzaSyDFtF_NIT8HGbaNNniFcVzrkCShK7I89Fw',
	authDomain        : 'thrilling-dev-portal.firebaseapp.com',
	databaseURL       : 'https://thrilling-dev-portal.firebaseio.com',
	projectId         : 'thrilling-dev-portal',
	storageBucket     : 'thrilling-dev-portal.appspot.com',
	apiURL            : 'https://us-central1-thrilling-dev-portal.cloudfunctions.net/api',
	messagingSenderId : '393326953923',
	appId             : '1:393326953923:web:9490d3d4eb09a5d5892976',
	measurementId     : environment.googleAnalyticsPropertyId,
};

export const corsProxy = {
	proxyRequestHeader : 'X-Thrilling-CORS-Proxy',
	proxyDomain        : 'quiet-journey-43070.herokuapp.com',
};

export const shopifyProxy = {
	proxyRequestHeader : 'X-Thrilling-Shopify-CORS-Proxy',
	proxyDomain        : 'morning-journey-80330.herokuapp.com',
	baseUri            : 'https://shop-thrilling.myshopify.com/admin/api',
	apiVersion         : '2021-04',
	graphQLendpoint    : 'graphql.json',
};
