import { Component } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { ProductImage, ImageSource } from '@shopthrilling/thrilling-shared';

import { DownloadService } from '../../../services/download/download.service';
import { ProductImagesComponent, GroupName } from '../product-images.component';
import { BooleanMap } from '../../../types/basics';


@Component({
	selector    : 'app-image-versions-modal',
	templateUrl : './image-versions-modal.component.html',
	styleUrls   : ['./image-versions-modal.component.scss'],
})
export class ImageVersionsModalComponent {
	parent          : ProductImagesComponent;
	groupName       : GroupName;
	index           : number;
	image           : ProductImage;
	versions        : string[];
	slideOpts       : any;
	downloadClicked : BooleanMap = {};


	constructor(
		private navParams : NavParams,
		private download  : DownloadService,
	) {
		this.parent = navParams.get('parent');

		this.groupName = navParams.get('groupName');
		this.index = navParams.get('index');

		this.image = this.parent[this.groupName][this.index];

		this.initVersions();
	}


	ngAfterViewInit() : void {
		setTimeout(this.initSlideOpts.bind(this), 1);
	}

	private initVersions() : void {
		const versions = [
			'unedited',
			'cropped',
			'autoEdited',
			'manuallyEdited',
		]
			.filter(item => this.image[`${ item }Src`]);

		if (this.parent.productImages.isPending(this.image)) {
			versions.push('pendingEdit');
		}

		this.versions = versions;
	}

	private initSlideOpts() : void {
		let initialSlide : number = 0;

		if (this.parent.productImages.isPending(this.image))
			initialSlide = this.versions.length - 1;
		else {
			initialSlide = this.versions
				.map((version : string, index : number) =>
					this.image.currentSource === this.versions[index] ? index : null
				)
				.find(item => item !== null);
		}

		this.slideOpts = {
			initialSlide,
		};
	}

	dismissModal() : void {
		this.parent.versionsModal.dismiss();
	}

	titleCase(version : string) : string {
		return version.charAt(0).toUpperCase() + version.slice(1).replace('E', ' E');
	}

	getSrc(version : string) : string {
		if (version === 'pendingEdit') {
			return this.image.editSource
				? this.image[`${ this.image.editSource }Src`]
				: this.image.src;
		} else {
			return this.image[version + 'Src'];
		}
	}

	async downloadImage(version : string, src : string) : Promise<void> {
		this.downloadClicked[version] = true;

		this.download.saveFile(src, this.parent.helper.getFilename(src));

		this.downloadClicked[version] = false;
	}

	updateSrc(version : ImageSource) : void {
		this.parent.updateSrc(this.groupName, this.index, version);

		this.dismissModal();
	}
}
