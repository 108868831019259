import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, from } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestoreCollection } from '@angular/fire/compat/firestore';

import { HelperService as Helper } from '../../services/helper/helper.service';
import { LogService } from '../../services/log/log.service';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { User, AdminRole, StoreRolesMap } from '../../types/user';


@Injectable({
	providedIn : 'root',
})
export class CurrentUserService {
	private usersRef : AngularFirestoreCollection<User>;
	private cache    : any = {};

	id$   : Observable<string>;
	user$ : Observable<User>;


	constructor(
		private afAuth    : AngularFireAuth,
		private firestore : FirestoreService,
		private router    : Router,
		private log       : LogService,
	) {
		this.initId();
		this.initUser();

		this.usersRef = this.firestore.getRef('users');
	}


	private initId() : void {
		this.id$ = this.afAuth.authState.pipe(
			map(user => user?.uid ?? null),
		);
	}

	private initUser() : void {
		this.user$ = this.id$.pipe(
			switchMap(uid => {
				if (!uid)
					return of(null);

				return this.firestore.query(
					'users',
					{ where : { uid } },
				).pipe(
					map(Helper.first),
				);
			}),
		);
	}

	isSignedIn() : Observable<boolean> {
		return this.user$.pipe(
			map(user => !!user),
		);
	}

	id() : Promise<string> {
		return this.user$.pipe(
			take(1),
			map(user => user?.id ?? null),
		)
			.toPromise();
	}

	email() : Observable<string> {
		return this.user$.pipe(
			take(1),
			map(user => user?.email ?? null),
		);
	}

	emailPromise() : Promise<string> {
		return this.email().pipe(
			take(1),
		)
			.toPromise();
	}

	adminRoles() : Observable<AdminRole[]> {
		return this.user$.pipe(
			take(1),
			map(user => user?.adminRoles ?? null),
		);
	}

	adminRolesPromise() : Promise<AdminRole[]> {
		return this.adminRoles().pipe(
			take(1),
		)
			.toPromise();
	}

	storeRoles() : Observable<StoreRolesMap> {
		return this.user$.pipe(
			take(1),
			map(user => user?.store ?? null),
		);
	}

	storeRolesPromise() : Promise<StoreRolesMap> {
		return this.storeRoles().pipe(
			take(1),
		)
			.toPromise();
	}

	getLastStore() : Observable<string> {
		if (this.cache.lastStore) {
			return of(this.cache.lastStore);
		}

		return this.user$.pipe(
			switchMap(user => {
				const lastStore   : string = user?.lastStore;
				const isAdmin     : boolean = user?.adminRoles?.includes('productsAdmin') || user?.adminRoles?.includes('developer');
				const isStoreUser : boolean = !!user?.store?.[lastStore];
				const isValid     : boolean = lastStore && (isAdmin || isStoreUser);

				if (isValid) {
					this.cache.lastStore = lastStore;

					return of(lastStore);
				} else {
					return this.getAndSaveLastStore(user);
				}
			}),
		);
	}

	private getAndSaveLastStore(currentUser : User) : Observable<string> {
		const store : string = Helper.keys(currentUser.store ?? null)[0];

		return store
			? from(this.updateLastStore(store))
				.pipe(
					map(() => store),
				)
			: of(null);
	}

	updateLastStore(lastStore : string) : Promise<any> {
		return this.update({ lastStore });
	}

	async update(updates : any) : Promise<any> {
		const id = await this.id();

		updates.updatedAt = new Date().toISOString();
		updates.updatedBy = id;

		return this.usersRef.doc(id).update(updates);
	}

	async logout(goHome : boolean = true) : Promise<boolean> {
		await this.afAuth.signOut();

		this.cache = {};

		return goHome
			? this.router.navigate(['/'])
			: false;
	}
}
