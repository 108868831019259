import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, from, Subject } from 'rxjs';
import { map, switchMap, take, tap, takeUntil } from 'rxjs/operators';

import { corsProxy } from '../../../environments/environment';
import { HelperService as Helper } from '../../services/helper/helper.service';
import { ShopifyAdminService } from '../../modules/shopify-admin/shopify-admin.service';
import { DownloadService } from '../../services/download/download.service';
import { BulkQueryPayload, BulkQueryField, BulkQueryFilters } from '../../types/bulk-query';


interface MetafieldDetails {
	namespace : string;
	key       : string;
}


@Injectable({
	providedIn : 'root',
})
export class BulkQueryService implements OnDestroy {
	private cancel$ : Subject<boolean> = new Subject<boolean>();


	constructor(
		private shopifyGraphql : ShopifyAdminService,
		private download       : DownloadService,
	) {}


	ngOnDestroy() : void {
		this.cancel$.unsubscribe();
	}

	initiate(fields : BulkQueryField[], filters? : BulkQueryFilters) : Observable<BulkQueryPayload> {
		return this.query(this.parseQuery(fields, filters)).pipe(
			take(1),
			// tap(data => console.debug(data)),
		);
	}

	query(payload : BulkQueryPayload) : Observable<BulkQueryPayload> {
		return this.shopifyGraphql.bulkQuery(payload).pipe(
			take(1),
			// tap(data => console.debug(data)),
		);
	}

	private parseQuery(fields : BulkQueryField[], filters? : BulkQueryFilters) : BulkQueryPayload {
		const queryFields : string[] = [];
		const variantFields : string[] = [];

		let	meta : MetafieldDetails;
		let multiMeta : boolean = false;

		for (const field of fields) {
			if (field.class === 'default') {
				queryFields.push(field.name);
			} else if (field.class === 'meta') {
				if (meta) {
					multiMeta = true;
				} else {
					meta = {
						namespace : field.namespace,
						key       : field.name,
					};
				}
			} else if (field.class === 'variant') {
				variantFields.push(field.name);
			}
		}

		if (meta) {
			queryFields.push(this.getMetaQuery(meta, multiMeta));
		}

		if (variantFields.length) {
			queryFields.push(this.getVariantQuery(variantFields));
		}

		const query : string = `
			products${ this.parseFilters(filters) } {
				edges {
					node {
						${ queryFields.join('\n') }
					}
				}
			}
		`;

		return {
			query,
			fields,
			filters,
			hasNestedFields : multiMeta || !!variantFields.length,
			id              : null,
			success         : null,
		};
	}

	private getMetaQuery(meta : MetafieldDetails, multiMeta : boolean) : string {
		if (multiMeta) {
			return `
				metafields {
					edges {
						node {
							id
							namespace
							key
							value
							valueType
						}
					}
				}
			`;
		} else
			return `metafield(namespace: "${ meta.namespace }", key: "${ meta.key }") { value }`;
	}

	private getVariantQuery(variantFields : string[]) : string {
		return `
			variants {
				edges {
					node {
						id
						${ variantFields.join('\n') }
					}
				}
			}
		`;
	}

	private parseFilters(filters? : BulkQueryFilters) : string {
		const entries = Object.entries(filters ?? {});
		const count = entries
			.filter(([ key, value ]) => value)
			.length;

		let filterStr = '';

		if (count === 0) {
			return filterStr;
		}

		const queryArr = [];
		const queryMap = {
			createdAfter  : 'created_at:>',
			createdBefore : 'created_at:<',
			updatedAfter  : 'updated_at:>',
			updatedBefore : 'updated_at:<',
		};

		for (const [ key, value ] of entries) {
			if (queryMap[key]) {
				queryArr.push(queryMap[key] + value);
			}
		}

		if (queryArr.length) {
			filterStr = `(query: "${ queryArr.join(' AND ') }")`;
		}

		return filterStr;
	}

	getCsv(payload : BulkQueryPayload, filename : string = 'export') : Observable<BulkQueryPayload> {
		return this.getData(payload).pipe(
			map((payload : BulkQueryPayload) => {
				if (!(payload && payload.success)) {
					return payload;
				}

				this.download.csv(payload.data, filename);

				delete payload.data;

				return payload;
			}),
			// tap(data => console.debug()),
		);
	}

	getData(payload : BulkQueryPayload, logProgress : boolean = false) : Observable<BulkQueryPayload> {
		return this.getJsonl(payload, logProgress).pipe(
			takeUntil(this.cancel$),
			map((payload : BulkQueryPayload) => {
				if (!(payload && payload.success)) {
					return payload;
				}

				const rawData : any[] = this.getDataFromJsonl(payload.jsonl);

				payload.data = payload.fields
					? this.parseData(payload.fields, rawData)
					: rawData;

				delete payload.jsonl;

				return payload;
			}),
			// tap(data => console.debug(data)),
		);
	}

	private getJsonl(payload : BulkQueryPayload, logProgress : boolean = false) : Observable<BulkQueryPayload> {
		return this.getUrl(payload, logProgress).pipe(
			switchMap((payload : BulkQueryPayload) => {
				if (payload && payload.success) {
					return from(this.getFile(payload.url)).pipe(
						map(data => typeof data === 'string'
							? {
								...payload,
								jsonl : data,
							}
							: {
								...payload,
								success : false,
								error   : 'Download failure',
							}
						),
					);
				} else {
					return of(payload);
				}
			}),
			// tap(data => console.debug(data)),
		);
	}

	getUrl(payload : BulkQueryPayload, logProgress : boolean = false) : Observable<BulkQueryPayload> {
		return this.shopifyGraphql.getCurrentBulkOperation(1000, 1800000, payload, logProgress);
	}

	private async getFile(url : string) : Promise<string> {
		const headers = new Headers();

		headers.append(corsProxy.proxyRequestHeader, 'true');

		const response = await window.fetch(`https://${ corsProxy.proxyDomain }/${ url }`, {
			headers,
		});

		return await response.text();
	}

	private parseData(fields : BulkQueryField[], data : any[]) : any[] {
		const parseId = (id) => id.replace(Helper.regex.path, '');
		const parseDate = (isoDate) => new Date(isoDate).toLocaleString().replace(',', '');
		const arr = [];

		for (const item of data) {
			const arrItem : any = {};
			const tagFields : any[] = [];

			for (const field of fields) {
				if (field.hidden) {
					continue;
				}

				let val : string;

				if (field.class === 'default') {
					val = item[field.name];
				} else if (field.class === 'variant' && item.children && item.children.productvariant) {
					val = item.children.productvariant[field.name];
				} else if (field.class === 'meta') {
					if (item.metafield && item.metafield.value) {
						val = item.metafield.value;
					} else if (item.children && item.children.metafield) {
						const meta = item.children.metafield[`${ field.namespace }:${ field.name }`];

						if (meta) {
							val = meta.valueType === 'JSON_STRING' ? JSON.parse(meta.value) : meta.value;
						}
					}
				} else if (field.class === 'tag') {
					tagFields.push(field);
				}

				if (field.name === 'id') {
					val = parseId(val);
				}

				if (field.type === 'date' && val) {
					val = parseDate(val);
				}

				arrItem[field.title] = val;
			}

			if (item.tags?.length && tagFields.length) {
				// delay iterating through tag fields to insure tags have been parsed
				for (const field of tagFields) {
					arrItem[field.title] = this.parseStructuredTagValue(field.name, item.tags);
				}
			}

			arr.push(arrItem);
		}

		return arr;
	}

	private parseStructuredTagValue(tagPrefix : string, tags : string[]) : string | string[] {
		const { prefix, regex } = Helper.getTagPrefixAndRegex(tagPrefix);
		const values = (tags ?? [])
			.filter(tag => regex.test(tag))
			.map(tag => tag.replace(regex, ''));

		if (values.length === 0) {
			return undefined;
		} else if (values.length === 1) {
			return values[0];
		} else {
			return values;
		}
	}

	private getDataFromJsonl(jsonl : string) : any[] {
		const dataArr : any[] = JSON.parse('[' + jsonl.replace(/\n|\r|\r\n/gm, ',').replace(/,$/, ']'));
		const typeParser = (id) : string => id.replace(/^gid:\/\/shopify\//, '').replace(/\/.*/g, '').toLowerCase();
		const dataMap = {};

		for (const line of dataArr) {
			if (line.__parentId) {
				const type : string = typeParser(line.id);
				const key : string = line.namespace && line.key ? `${ line.namespace }:${ line.key }` : null;

				if (!dataMap[line.__parentId]) {
					dataMap[line.__parentId] = {};
				}

				if (!dataMap[line.__parentId].children) {
					dataMap[line.__parentId]['children'] = {};
				}

				if (key) {
					if (!dataMap[line.__parentId].children[type]) {
						dataMap[line.__parentId].children[type] = {};
					}

					dataMap[line.__parentId].children[type][key] = line;
				} else {
					dataMap[line.__parentId].children[type] = line;
				}
			} else {
				if (dataMap[line.id]) {
					dataMap[line.id] = {
						...dataMap[line.id],
						...line,
					};
				} else {
					dataMap[line.id] = line;
				}
			}
		}

		return Object.values(dataMap);
	}

	getCurrentPayload() : Observable<BulkQueryPayload> {
		return this.shopifyGraphql.getCurrentBulkOperation(1, 1).pipe(
			take(1),
		);
	}

	cancel(payload : BulkQueryPayload) : Observable<BulkQueryPayload> {
		this.cancel$.next(true);

		return this.shopifyGraphql.cancelBulkQuery(payload.id).pipe(
			take(1),
			switchMap(() => this.shopifyGraphql.getCurrentBulkOperation(100, 5000, payload)),
			// tap(data => console.debug(data)),
		);
	}
}
