import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ImageCropperModule } from 'ngx-image-cropper';

// inputs
import { InputClassRouterComponent } from './inputs/input-class-router/input-class-router.component';
import { InputComponent } from './inputs/input/input.component';
import { RadioInputComponent } from './inputs/radio-input/radio-input.component';
import { ImageInputComponent } from './inputs/image-input/image-input.component';
import { ImageUploadComponent } from './inputs/image-upload/image-upload.component';
import { TagInputComponent } from './inputs/tag-input/tag-input.component';
import { DefaultClassInputComponent } from './inputs/default-class-input/default-class-input.component';
import { TagClassInputComponent } from './inputs/tag-class-input/tag-class-input.component';
import { TagClassTextInputComponent } from './inputs/tag-class-text-input/tag-class-text-input.component';
import { TagClassSelectInputComponent } from './inputs/tag-class-select-input/tag-class-select-input.component';
import { MetafieldClassInputComponent } from './inputs/metafield-class-input/metafield-class-input.component';
import { EditMenuPopoverComponent } from './inputs/image-input/edit-menu-popover/edit-menu-popover.component';
import { MeasurementsInputComponent } from './inputs/measurements-input/measurements-input.component';
import { DisabledInputComponent } from './inputs/disabled-input/disabled-input.component';
// independent components
import { SpinnerComponent } from './spinner/spinner.component';
import { ProductImagesComponent } from './product-images/product-images.component';
import { ImageVersionsModalComponent } from './product-images/image-versions-modal/image-versions-modal.component';
import { CreatedUpdatedAgoComponent } from './created-updated-ago/created-updated-ago.component';
import { ExpandableCardComponent } from './expandable-card/expandable-card.component';
import { StoreSocialLinksComponent } from './store-social-links/store-social-links.component';
import { PublishToggleComponent } from './toggles/publish-toggle/publish-toggle.component';
import { ListHeaderComponent } from './list-header/list-header.component';
import { ZoomImageComponent } from './zoom-image/zoom-image.component';
import { ZoomImageModalComponent } from './zoom-image/zoom-image-modal/zoom-image-modal.component';
import { ZoomImageMenuPopoverComponent } from './zoom-image/zoom-image-modal/zoom-image-menu-popover/zoom-image-menu-popover.component';
import { ToggleComponent } from './toggles/toggle/toggle.component';
import { ModalComponent } from './modal/modal.component';
import { CopyToClipboardComponent } from './copy-to-clipboard/copy-to-clipboard.component';
import { CharCounterComponent } from './char-counter/char-counter.component';


@NgModule({
	declarations : [
		InputClassRouterComponent,
		CharCounterComponent,
		InputComponent,
		RadioInputComponent,
		ImageInputComponent,
		ImageUploadComponent,
		TagInputComponent,
		MeasurementsInputComponent,
		DefaultClassInputComponent,
		TagClassInputComponent,
		TagClassTextInputComponent,
		TagClassSelectInputComponent,
		MetafieldClassInputComponent,
		EditMenuPopoverComponent,
		ZoomImageComponent,
		ZoomImageModalComponent,
		ZoomImageMenuPopoverComponent,
		SpinnerComponent,
		CopyToClipboardComponent,
		ProductImagesComponent,
		ImageVersionsModalComponent,
		CreatedUpdatedAgoComponent,
		ExpandableCardComponent,
		StoreSocialLinksComponent,
		PublishToggleComponent,
		ListHeaderComponent,
		ToggleComponent,
		ModalComponent,
		DisabledInputComponent,
	],
	imports : [
		CommonModule,
		FormsModule,
		IonicModule,
		ImageCropperModule,
	],
	exports : [
		InputClassRouterComponent,
		CharCounterComponent,
		InputComponent,
		ImageInputComponent,
		ImageUploadComponent,
		RadioInputComponent,
		TagInputComponent,
		SpinnerComponent,
		CopyToClipboardComponent,
		ProductImagesComponent,
		CreatedUpdatedAgoComponent,
		ExpandableCardComponent,
		StoreSocialLinksComponent,
		PublishToggleComponent,
		ListHeaderComponent,
		ZoomImageComponent,
		ToggleComponent,
		ModalComponent,
		MeasurementsInputComponent,
		DisabledInputComponent,
	],
	providers : [
		ModalComponent,
	],
})
export class ComponentsModule {}
