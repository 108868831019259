import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of, throwError, from } from 'rxjs';
import { map, switchMap, take, tap, debounceTime } from 'rxjs/operators';
import { AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { sortBy } from 'lodash';
import { Store, StoreMetafieldMap, StoreMetafieldKey, SocialLink, StoreMetafield, Query } from '@shopthrilling/thrilling-shared';

import { HelperService as Helper } from '../../services/helper/helper.service';
import { LogService } from '../../services/log/log.service';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { ShopifyAdminService, ShopifyMetafieldInput } from '../../modules/shopify-admin/shopify-admin.service';
import { CurrentUserService } from '../../services/current-user/current-user.service';
import { StoreFields } from '../../models/store-fields.model';
import { StoreWithFields, StorePrimaryInputs, StoreSecondaryFields, ShopifyStoreInput } from '../../types/store';
import { StringMap, AnyMap } from '../../types/basics';
import { Metafield, ImageValue, SelectOption } from '../../types/input-fields';
import { FirestoreQuery } from '../../types/firestore';
import { StoreFieldGrouping } from '../../types/store-input-fields';
import { EventIssuer } from '../../constants/EventIssuer';
import { EventContext } from '../../constants/EventContext';


@Injectable({
	providedIn : 'root',
})
export class StoreService {
	private cache = {};

	storesRef : AngularFirestoreCollection<Store>;
	query$    : BehaviorSubject<string> = new BehaviorSubject<string>('');


	constructor(
		private log            : LogService,
		private firestore      : FirestoreService,
		private shopifyGraphql : ShopifyAdminService,
		private currentUser    : CurrentUserService,
	) {
		this.storesRef = this.firestore.getRef('stores');
	}


	/*
	 * Read Methods - new
	 * pull from Firestore
	 */
	getById(id : string) : Observable<Store> {
		return this.storesRef.doc<Store>(id).valueChanges();
	}

	getByIdPromise(id : string) : Promise<Store> {
		return this.getById(id).pipe(
			take(1),
		)
			.toPromise();
	}

	getAll(sort : boolean = true, debounce? : number) : Observable<Store[]> {
		let all = this.storesRef.valueChanges();

		if (debounce != null) {
			all = all.pipe(
				debounceTime(debounce),
				take(1),
			);
		}

		if (sort) {
			all = all.pipe(
				map(this.sortByTitle),
			);
		}

		return all;
	}

	private sortByTitle(list : any[]) : any[] {
		return sortBy(list, (item) => item.title?.toLowerCase());
	}

	getTitleMap() : Observable<StringMap> {
		const titleMap : StringMap = {};

		return this.getAll(true, 2500).pipe(
			// tap(data => console.debug(data)),
			take(1),
			map((list : Store[]) => {
				for (const store of list)
					titleMap[store.handle] = store.title;

				return titleMap;
			}),
			// tap(data => console.debug(data)),
		);
	}

	getSelectOptions() : Observable<SelectOption[]> {
		return this.getAll(false, 2500).pipe(
			// tap(data => console.debug(data)),
			take(1),
			map((list : Store[]) => list.map(store => ({
				value : store.handle,
				title : store.title,
			}))),
			map(this.sortByTitle),
			// tap(data => console.debug(data)),
		);
	}

	getFieldGroupMap() : StringMap {
		const storeFields : StoreFieldGrouping = StoreFields(),
			map : StringMap = {};

		for (const group in storeFields.groups) {
			for (const field in storeFields.groups[group].fields)
				map[field] = group;
		}

		return map;
	}

	getQueried(limit : number = 50) : Observable<any> {
		return this.getAll().pipe(
			// tap(data => console.debug(data)),
			switchMap(stores => this.query$.pipe(
				map(query => Query.matchObject(
					stores,
					query,
					[
						'title',
						'handle',
						'id',
						item => Helper.parseGraphQlNumericalId(item.graphqlAdminId),
					],
				)),
			)),
			map((list : any[]) => {
				const data : any[] = list.slice(0, limit);

				return {
					data,
					total : list.length,
					count : data.length,
				};
			}),
			// tap(data => console.debug(data)),
		);
	}

	getByHandle(handle : string, includeFields : boolean = false, compatibilityTransform : boolean = false) : Observable<Store | StoreWithFields> {
		return this.queryFirestore({ where : { handle } }).pipe(
			map((list : Store[]) => {
				if (list?.length > 1)
					this.logDupes(list, 'handle');

				return Helper.first(list);
			}),
			map((store : Store) => compatibilityTransform
				? this.firestoreShopifyCompat(store)
				: store
			),
			// tap(data => console.debug(data)),
			map((store : Store) => {
				if (store) {
					return includeFields
						? this.getStoreFields(store)
						: store;
				} else
					return null;
			}),
		);
	}

	getByTitle(title : string) : Observable<Store[]> {
		return this.queryFirestore({ where : { title } });
	}

	private firestoreShopifyCompat(store : Store) : Store {
		if (!store?.id) {
			return store;
		}

		// patch description/descriptionHtml naming inconsistency
		if (store.descriptionHtml == null) {
			store.descriptionHtml = store.description;
		}

		return store;
	}

	private queryFirestore(queries : FirestoreQuery | FirestoreQuery[]) : Observable<Store[]> {
		return this.firestore.query('stores', queries);
	}

	private logDupes(list : Store[], queryType : 'handle' | 'GraphQl id' | 'title') : void {
		this.log.error(EventIssuer.StoreService, {
			handle       : list[0].handle,
			subject      : `duplicate stores found with the same ${ queryType }`,
			eventContext : EventContext.StoreDupe,
			details      : {
				list,
				count : `expected 1; found ${ list.length }`,
			},
		});
	}

	getByGraphQlId(graphqlAdminId : string) : Observable<Store[]> {
		return this.queryFirestore({ where : { graphqlAdminId } });
	}

	private getStoreFields(storeWithoutFields : Store) : StoreWithFields {
		const store = {
			...storeWithoutFields,
			fields : StoreFields(),
		};

		this.parseFields(store);

		return store;
	}

	private parseFields(store : StoreWithFields) : void {
		for (const groupKey in store.fields.groups) {
			for (const fieldKey in store.fields.groups[groupKey].fields) {
				this.parseField(store, groupKey, fieldKey);
			}
		}
	}

	private parseField(store : StoreWithFields, groupKey : string, fieldKey : string) : void {
		const field = store.fields.groups[groupKey].fields[fieldKey];
		const fieldValue = store[field.key] ?? null;

		field.value = field.inputType === 'image'
			? this.parseImageFieldValue(fieldValue)
			: fieldValue;
	}

	parseImageFieldValue(fieldValue : any) : ImageValue {
		let parsedImageValue = fieldValue;

		if (typeof fieldValue === 'string') {
			parsedImageValue = { src : fieldValue };
		} else if (Array.isArray(fieldValue) && fieldValue.some(item => typeof item === 'string')) {
			parsedImageValue = fieldValue
				.map(src => ({ src }));
		}

		return parsedImageValue;
	}

	/*
	 * Read Methods - old
	 * pull from Shopify
	 */


	/*
	 * Write Methods
	 * push to Firestore
	 */
	private async upsert(input : any) : Promise<Store> {
		const existingStores : Store[] = input.id && await this.getByGraphQlId(input.id).pipe(
			take(1),
		)
			.toPromise();

		if (existingStores?.length > 1) {
			this.logDupes(existingStores, 'GraphQl id');

			throw new Error('duplicate stores detected');
		}

		const store : Store = existingStores?.[0] ?? new Store();

		if (store.createdBy == null)
			store.createdBy = await this.currentUser.id();

		if (store.createdAt == null)
			store.createdAt = new Date().toISOString();

		if (store.id) {
			await this.update(store.id, store);

			return store;
		}

		const id = this.firestore.createId();

		store.id = id;
		store.graphqlAdminId = input.id ?? null;
		store.handle = input.handle ?? null;
		store.templateSuffix = input.templateSuffix ?? null;
		store.title = input.title ?? null;

		await this.storesRef.doc(id).set(Helper.deepCopy(store));

		return store;
	}

	async update(id : string, updates : any = {}) : Promise<Store> {
		updates.updatedAt = new Date().toISOString();
		updates.updatedBy = await this.currentUser.id();

		try {
			await this.storesRef.doc(id).update(Helper.deepCopy(updates));
		} catch (error) {
			console.error(error);
		}

		return this.getByIdPromise(id);
	}

	delete(id : string) : Promise<void> {
		return this.storesRef.doc(id).delete();
	}

	async updateInFirestoreAndShopify(id : string, updates : StorePrimaryInputs) : Promise<Store> {
		// augment updated store data with secondary fields
		const updatePayload : StoreSecondaryFields = this.parseSecondaryFields(updates);

		// the store must be retreieved from Firestore so the data can be merged
		const currentStore = await this.getByIdPromise(id);

		const updatedData = await this.mergeUpdatedDataWithFirestoreData(updatePayload, currentStore) as Store;

		// save updated data to Firestore
		let updatedStore = await this.update(id, updatedData);

		const shopifyResponse = await this.updateStoreInShopify(updatedStore);

		const parsedShopifyResponse = this.parseShopifyResponse(shopifyResponse, updatedStore, false);

		if (parsedShopifyResponse.isFirestoreUpdateNeeded) {
			// save updated store data to Firestore
			updatedStore = await this.update(id, parsedShopifyResponse.updates);
		}

		return this.firestoreShopifyCompat(updatedStore);
	}

	private parseSecondaryFields(updates : AnyMap) : StoreSecondaryFields {
		// iterate through all keys/values, passing each to the metafield parsing function
		// to determine if descendent fields should be derived from the primary field
		for (const [ key, value ] of Object.entries(updates)) {
			this.parseMetafieldFromUpdate(updates, key, value);
		}

		return updates;
	}

	/**
	 * A static method used to parse all secondary metafields that descend from primary fields.
	 * Based on the primary field key, determines what metafield key, value, and value type is needed.
	 * @param updates: the StoreSecondaryFields object
	 * @param key: the primary field key
	 * @param value: the primary field value
	 */
	private parseMetafieldFromUpdate(updates : StoreSecondaryFields, key : string, value : any) : void {
		switch (key) {
			case 'id':
				this.stashMetafieldUpdate(updates, 'firestoreId', value as string);

				break;

			case 'announcementBarBgColor':
			case 'announcementBarText':
			case 'location':
			case 'logoImage':
			case 'primaryImage':
			case 'region':
			case 'secondaryImage':
			case 'type':
				this.stashMetafieldUpdate(updates, key, value as string);

				break;

			case 'giftWrap':
			case 'isWholesaleSupplier':
			case 'landscapeImageHeader':
			case 'verified':
				this.stashMetafieldUpdate(updates, key, value as boolean);

				break;

			case 'socialLinks':
				this.stashMetafieldUpdate(updates, key, value as SocialLink[], 'JSON_STRING');

				break;
		}
	}

	/**
	 * A static method used to stash updates for a single metafield
	 * @param updates: the StoreSecondaryFields object
	 * @param key: the metafield key to update
	 * @param value: the updated value
	 * @param valueType: the metafield value type
	 */
	private stashMetafieldUpdate(
		updates   : StoreSecondaryFields,
		key       : StoreMetafieldKey,
		value     : string | boolean | SocialLink[],
		valueType : 'STRING' | 'INTEGER' | 'JSON_STRING' = 'STRING',
	) : void {
		// if the updated value is "empty" - nullish, an empty string, or a zero length array - then
		// the metafield should be deleted rather than updated
		if (Helper.isEmpty(value, { includeEmptyString : true })) {
			return this.stashMetafieldDelete(updates, key);
		}

		const namespace : string = key === 'firestoreId'
			? 'sync'
			: 'meta';

		// metafields can only save values in string format
		// if the current value is not a string, make it so
		if (typeof value !== 'string') {
			value = JSON.stringify(value);
		}

		// insure the updates.update object exists before attempting to update it
		if (!updates.update) {
			updates.update = {};
		}

		updates.update[key] = {
			key,
			namespace,
			valueType,
			value,
		};
	}

	/**
	 * A static method used to stash deletes for a single metafield
	 * @param updates: the StoreSecondaryFields object
	 * @param key: the metafield key to delete
	 */
	private stashMetafieldDelete(updates : StoreSecondaryFields, key : StoreMetafieldKey) : void {
		// insure the updates.delete array exists before attempting to push to it
		if (!updates.delete) {
			updates.delete = [];
		}

		updates.delete.push(key);
	}

	/**
	 * A static method used to merge updated data with current store data from Firestore
	 * @param updatePayload: the StoreSecondaryFields object
	 * @param currentStore: product data retrieved from Firestore
	 * Returns: a Promise containing a map of updates
	 */
	private async mergeUpdatedDataWithFirestoreData(updatePayload : StoreSecondaryFields, currentStore : Store) : Promise<AnyMap> {
		// perform metafields update/delete and merge with current store metafields
		await this.mergeUpdatedMetafieldsWithFirestoreMetafields(updatePayload, currentStore);

		// delete the "update" and "delete" objects from updatePayload
		// so only a key/value map of updated fields are left
		delete updatePayload.update;
		delete updatePayload.delete;

		return updatePayload;
	}

	/**
	 * A static method used to merge updated metafields with current store metafields
	 * @param updatePayload: the SecondaryFields object
	 * @param currentStore: the current Store object
	 */
	private async mergeUpdatedMetafieldsWithFirestoreMetafields(updatePayload : StoreSecondaryFields, currentStore : Store) : Promise<void> {
		if (!currentStore.metafieldMap) {
			currentStore.metafieldMap = {};
		}

		// if there are metafields to delete, do so
		if (updatePayload.delete) {
			await this.deleteUpdatedMetafields(updatePayload, currentStore);
		}

		// if there are metafields to update, retrieve the respective metafield id
		// from the current product metafield map to allow the metafield to be updated in Shopify
		if (updatePayload.update) {
			for (const [ key, metafield ] of Object.entries(updatePayload.update)) {
				const id = currentStore.metafieldMap?.[key as StoreMetafieldKey]?.id;

				if (id) {
					metafield.id = id;
				}

				currentStore.metafieldMap[key as StoreMetafieldKey] = metafield;
			}
		}

		updatePayload.metafieldMap = currentStore.metafieldMap;
		updatePayload.metafields = Object.values(updatePayload.metafieldMap);
	}

	/**
	 * A static method used to delete updated metafields and remove them from the update payload
	 * @param updatePayload: the SecondaryFields object
	 * @param currentStore: the current Store object
	 */
	private async deleteUpdatedMetafields(updatePayload : StoreSecondaryFields, currentStore : Store) : Promise<void> {
		// map the list of deletable metafields to the respective metafield id
		// retrieved from the current store metafield map
		const metafieldsToDelete = (updatePayload.delete ?? [])
			.map(key => ({
				key,
				id : currentStore.metafieldMap?.[key]?.id,
			}))
			.filter((item : any) => item.id);

		if (metafieldsToDelete.length) {
			for (const { key, id } of metafieldsToDelete) {
				delete currentStore.metafieldMap?.[key];

				try {
					await this.deleteMetafield({ id } as Metafield).pipe(
						take(1),
					)
						.toPromise();
				} catch (error) {
					console.error(error);
				}
			}
		}
	}

	/**
	 * A static function that saves updated product info to Shopify
	 * @param updatedStore: the response from the Firestore document update
	 * Returns: Promise<any> the raw info returned from the request to Shopify
	 */
	private updateStoreInShopify(updatedStore : Store) : Promise<any> {
		const shopifyInput = this.parseShopifyInput(updatedStore, true);

		return this.shopifyGraphql.updateCollectionGetMetafields(shopifyInput).pipe(
			take(1),
		)
			.toPromise();
	}

	/**
	 *
	 * @param store: the document data from a firestore response
	 * @param isUpdate: A boolean for handling differences between the update and create objects
	 */
	private parseShopifyInput(store : Store, isUpdate : boolean = false) : ShopifyStoreInput {
		const input : ShopifyStoreInput = {
			title           : store.title,
			descriptionHtml : store.descriptionHtml ?? store.description,
		};

		if (isUpdate) {
			input.id = store.graphqlAdminId;
		}

		const metafields = this.parseStoreMetafields(store, isUpdate);

		if (metafields?.length) {
			input.metafields = metafields;
		}

		if (store.primaryImage) {
			input.image = {
				altText : `image of ${ store.title }`,
				src     : store.primaryImage,
			};
		}

		return input;
	}

	private parseStoreMetafields(store : Store, isUpdate : boolean = false) : StoreMetafield[] {
		const metafields = isUpdate
			? store.metafields ?? []
			: this.parseNewStoreMetafields(store);

		return metafields
			.map(metafield => new ShopifyMetafieldInput(metafield) as StoreMetafield);
	}

	private parseNewStoreMetafields(store : Store) : StoreMetafield[] {
		const metafields = store.metafields ?? [];

		metafields.push({
			key       : 'firestoreId',
			namespace : 'sync',
			valueType : 'STRING',
			value     : store.id,
		});

		return metafields;
	}

	/**
	 * A static function that parses the data returned from Shopify and
	 * determines if it needs to be saved back to Firestore
	 * @param shopifyResponse: the raw info returned from the request to Shopify
	 * @param store: the store info returned from the create or update mutation into Firestore
	 * @param isCreate: whether this is for a create or update action
	 * Returns: a custom payload with an updated store
	 * and info about whether an update is needed
	 */
	private parseShopifyResponse(shopifyResponse : any, store : Store, isCreate : boolean = true) : { updates : AnyMap, isFirestoreUpdateNeeded : boolean } {
		let didParse   : boolean = false;
		let isUpToDate : boolean = false;
		let updates    : AnyMap;

		if (shopifyResponse?.id) {
			didParse = true;

			const { id, handle, metafields } = shopifyResponse;
			const metafieldMap : StoreMetafieldMap = {};

			Helper.flattenEdgeNodeArray(metafields)
				.forEach((metafield : StoreMetafield) => {
					metafieldMap[metafield.key] = metafield;
				});

			updates = {
				metafieldMap,
				metafields : Object.values(metafieldMap),
			};

			if (isCreate) {
				updates.graphqlAdminId = id;
				updates.handle = handle;

				updates.id = store.id;
			} else {
				// check to see if there's a mismatch between metafields in Firestore & Shopify
				// and if all Firestore metafields have IDs
				isUpToDate = updates.metafields.length === store.metafields?.length
					&& updates.metafields.every((metafield : StoreMetafield) =>
						store.metafieldMap?.[metafield.key]?.id
					);
			}
		}

		return {
			updates,
			isFirestoreUpdateNeeded : didParse && (isCreate || !isUpToDate),
		};
	}


	/*
	 * Write Methods
	 * push to Shopify & Firestore
	 */
	createInShopifyAndFirestore(title : string) : Observable<any> {
		const input = {
			title,
			templateSuffix : 'store',
			...this.generateRuleSet(),
		};

		return this.getByTitle(title).pipe(
			take(1),
			tap(existingStores => {
				if (existingStores?.length >= 1) {
					this.logDupes(existingStores, 'title');

					throw new Error('duplicate stores detected');
				}
			}),
			switchMap(() => this.shopifyGraphql.createCollection(input)),
			take(1),
			map(data => data?.data?.collectionCreate?.collection),
			switchMap(store => from(this.upsert({
				...input,
				...store,
			}))),
			take(1),
			switchMap(store => this.updateShopify(
				store.graphqlAdminId,
				this.generateRuleSet(store.handle),
			)),
			take(1),
		);
	}

	private generateRuleSet(handle : string = 'Thrilling') : any {
		return {
			ruleSet : {
				appliedDisjunctively : true,
				rules                : [
					{
						column    : 'VENDOR',
						relation  : 'EQUALS',
						condition : handle,
					},
				],
			},
		};
	}


	/*
	 * Write Methods
	 * push to Shopify
	 */
	private updateShopify(id : string, updates) : Observable<any> {
		return this.shopifyGraphql.updateCollection(id, updates)
			.pipe(
				take(1),
				map(data => Helper.get(data, ['data', 'collectionUpdate', 'collection'])),
			);
	}

	deleteMetafield(metafield : Metafield) : Observable<Metafield> {
		return this.shopifyGraphql.deleteMetafield(metafield.id).pipe(
			take(1),
			map(() => {
				delete metafield.id;
				delete metafield.value;

				return metafield;
			}),
		);
	}
}
