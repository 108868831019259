import { Injectable } from '@angular/core';
import { Observable, Subject, of, from } from 'rxjs';
import { map, switchMap, take, tap, catchError } from 'rxjs/operators';
import { Product, ProductImage, ProductMetafieldKey, ListingType, Division, Department, Size, Color, Material, Era, DressSkirtLength } from '@shopthrilling/thrilling-shared';

import { firebaseConfig } from '../../../environments/environment';
import { LogService } from '../../services/log/log.service';
import { HelperService as Helper } from '../../services/helper/helper.service';
import { ImgixService } from '../../services/imgix/imgix.service';
import { StoreService } from '../../services/store/store.service';
import { ProductService } from '../../services/product/product.service';
import { ProductImagesService } from '../../services/product-images/product-images.service';
import { OptionalLogData } from '../../types/log';
import { StringMap, BooleanMap } from '../../types/basics';
import { ShopifyProductInput } from '../../types/product';
import { NewProductFieldGrouping, NewProductValue, NewProductValueMap } from '../../types/new-product';
import { SentenceFragment } from '../../types/sentence-fragment';
import { TitleDefinition, TitleAutocorrect } from '../../models/title.model';
import { DescriptionDefinition } from '../../models/description.model';
import { GetWeightByCategory } from '../../models/weight.model';
import { GetMetafieldNamespaceFromKey } from '../../models/product-fields.model';
import { GetPriceBuckets } from '../../models/price.model';
import { EventIssuer } from '../../constants/EventIssuer';
import { EventContext } from '../../constants/EventContext';
import { ShopifyError } from '../../types/shopify-error';


type FieldsPayload = {
	fieldGroupMap : StringMap;
	fields        : NewProductFieldGrouping;
};

export type SavePayload = {
	productUploaded : boolean;
	imagesUploaded  : boolean | 'partial';
	handle?         : string;
	sku?            : string;
	listingType?    : ListingType;
	errors          : ShopifyError[];
};


@Injectable({
	providedIn : 'root',
})
export class NewProductService {
	private readonly titleAutocorrect = TitleAutocorrect();
	private readonly automaticValueKeys : BooleanMap = {
		vendor          : true,
		uploadStartedAt : true,
	};
	private readonly automaticTagKeys : BooleanMap = {
		'listing type' : true,
		'meta'         : true,
	};

	private values : NewProductValueMap = {};

	getDisplaySizeFromSize  : Function;
	getListFromOptionsArray : Function;
	getDupeImportedProducts : Function;
	unsavedChangesWarning$  : Subject<boolean> = new Subject<boolean>();
	unsavedChangesResponse$ : Subject<Promise<boolean>> = new Subject<Promise<boolean>>();


	constructor(
		private log           : LogService,
		private imgix         : ImgixService,
		private store         : StoreService,
		private product       : ProductService,
		private productImages : ProductImagesService,
	) {
		// setup passthroughs to product service
		this.initPassthroughMethods();
	}


	private initPassthroughMethods() : void {
		const methods : string[] = [
			'getDisplaySizeFromSize',
			'getListFromOptionsArray',
			'getDupeImportedProducts',
		];

		for (const method of methods)
			this[method] = (args) => this.product[method](args);
	}

	getFields(listingType : ListingType) : Observable<FieldsPayload> {
		return this.product.getNewFields(listingType).pipe(
			map((fields : NewProductFieldGrouping) => ({
				fields,
				fieldGroupMap : this.parseFieldGroupMap(fields),
			})),
		);
	}

	private parseFieldGroupMap(fields : NewProductFieldGrouping) : StringMap {
		const map : StringMap = {};

		for (const group in fields.groups) {
			for (const field in fields.groups[group].fields)
				map[field] = group;
		}

		return map;
	}

	getRequiredFields(listingType : ListingType) : string[] {
		const essential = [
			'vendor',
			'price',
			'images',
		];
		const extended = [
			'category',
			'title',
			'size',
			'descriptionHtml',
		];
		const fullTaxonomy = [
			'division',
			'department',
		];

		const fields : { [ key in ListingType ] : string[] } = {
			wholesale : essential.concat(),
			basic     : essential.concat(extended),
			detailed  : essential.concat(extended),
			imported  : essential.concat(extended, fullTaxonomy),
		};

		return fields[listingType];
	}

	getClearValues() : NewProductValueMap {
		return this.values = {};
	}

	updateValues(values : NewProductValueMap) : NewProductValueMap {
		return this.values = values;
	}

	updateValue(key : string, value : NewProductValue) : NewProductValue {
		return this.values[key] = value;
	}

	removeValue(key: string) : void {
		delete this.values[key];
	}

	hasUnsavedValues() : boolean {
		// when assessing if the new product page has unsaved changes
		// don't include automatically added values
		const manualValues = this.values && Object.entries(this.values)
			.filter(([ key, value ]) => {
				if (key === 'tags') {
					return this.getManualTagCount(value as string[]) !== 0;
				} else if (key === 'sizeDiagram') {
					return this.parseDiagram(value as string[]) !== 0;
				} else {
					return !this.automaticValueKeys[key];
				}
			});

		return !!manualValues?.length;
	}

	requiredFieldsFilled(newProduct : NewProductValueMap, listingType : ListingType) : boolean {
		const requiredFields : string[] = this.getRequiredFields(listingType);

		const allValid : boolean = requiredFields.every(key => {
			const value : NewProductValue = newProduct[key];

			let isValid : boolean;

			if (key === 'images') {
				isValid = this.validProductImages(value as ProductImage[], listingType);
			} else {
				isValid = !Helper.isEmpty(value, { includeEmptyString : true });

				// insure price is a number greater than 0
				if (isValid && key === 'price') {
					isValid = typeof value === 'number' && value > 0;
				}
			}

			return isValid;
		});

		return allValid;
	}

	private validProductImages(images : ProductImage[], listingType : ListingType) : boolean {
		let result : boolean = !!images?.[0];

		// imported products only require 1 image. everything else should have at least 2.
		if (listingType !== 'imported') {
			result = result && !!images?.[1];
		}

		// wholesale products don't require edited images. everything else should have at least 1.
		if (listingType !== 'wholesale') {
			result = result && this.productImages.isEditedOrPending(images[0]);
		}

		return result;
	}

	cleanTitle(cleanTitle : string) : string {
		cleanTitle = cleanTitle.replace(',', ' ');

		for (const autocorrect of this.titleAutocorrect)
			cleanTitle = cleanTitle.replace(autocorrect.find, autocorrect.replace);

		return Helper.titleCase(
			cleanTitle,
			false,
			true,
		);
	}

	generateSentence(sentenceType : 'title' | 'description', taxonomicGroup : 'category' | 'subcategory', ingredients : StringMap = {}) : string {
		const isTitle : boolean = sentenceType === 'title';

		const optArr : SentenceFragment[] = isTitle
			? TitleDefinition(taxonomicGroup)
			: DescriptionDefinition(taxonomicGroup);

		let value : string = '';

		for (const opt of optArr) {
			const optVal = ingredients[opt.key];

			if (!optVal) {
				continue;
			}

			if (opt.preConnector) {
				value += ` ${ opt.preConnector }`;
			}

			value += ` ${ optVal }`;

			if (opt.postConnector) {
				value += ` ${ opt.postConnector }`;
			}
		}

		// remove leading & trailing spaces
		value = value.trim();

		// title: apply cleanTitle to fix typos and capitalization
		// description: capitalize the first letter and affix punctuation
		value = isTitle
			?	this.cleanTitle(value)
			: `${ value.charAt(0).toUpperCase() + value.slice(1) }.`;

		return value;
	}

	/**
	 * Convert an array of era strings into one string.
	 *
	 * @param arr - An array of era(s).
	 * @returns an era string.
	 */
	eraArrToString(arr : string[]) : string {
		const length : number = !!arr && Helper.isArray(arr) ? arr.length : 0;

		let value : string;

		if (length === 1) {
			value = arr[0];
		} else if (length === 2) {
			value = `${ arr[0] }/${ arr[1] }`;
		}

		return value;
	}

	save(listingType : ListingType) : Observable<any> {
		let productObj = new Product();

		return this.product.getNewSku().pipe(
			take(1),
			map(sku => this.updateProductObj(productObj, {
				sku,
				listingType,
				inventory : 1,
				isDraft   : false,
			})),
			map(this.parseValues.bind(this)),
			map(data => productObj = this.updateProductObj(productObj, data)),
			switchMap(this.createInFirestoreAndShopify.bind(this)),
			map(data => {
				productObj = data.product;
				return data;
			}),
			map(this.generatePayload.bind(this)),
			catchError(err => this.handleCatchError(err, productObj)),
			take(1),
			tap(this.createLog.bind(this)),
		);
	}

	createInFirestoreAndShopify(productObj : Product) : Observable<any> {
		let errors : ShopifyError[];

		return this.productImages.createProduct(productObj).pipe(
			take(1),
			map(data => productObj = this.updateProductObjWithFirestoreData(productObj, data)),
			map(this.parseShopifyProductInput.bind(this)),
			switchMap((data : ShopifyProductInput) => this.product.createInShopify(data)),
			take(1),
			tap(data => errors = data.errors),
			map(data => this.updateProductObjWithShopifyData(productObj, data.product)),
			switchMap(this.updateFirestoreWithShopifyData.bind(this)),
			take(1),
			tap(this.initAsyncShopifyImageUpload.bind(this)),
			map(product => ({
				product,
				errors,
			})),
		);
	}

	private updateProductObj(productObj : Product, data : any) : Product {
		productObj = {
			...productObj,
			...data,
		};

		return productObj;
	}

	private updateProductObjWithFirestoreData(productObj : Product, data : any) : Product {
		if (!productObj?.id && data?.id) {
			this.addMetafield(productObj, 'firestoreId', data.id);
		}

		return this.updateProductObj(productObj, data);
	}

	private updateProductObjWithShopifyData(productObj : Product, data : any = {}) : Product {
		return this.updateProductObj(productObj, {
			graphqlAdminId : data.id,
			handle         : data.handle,
		});
	}

	private updateFirestoreWithShopifyData(product : Product) : Observable<Product> {
		return from(this.product.update(product.id, {
			graphqlAdminId : product.graphqlAdminId,
			handle         : product.handle,
		})).pipe(
			map(() => product),
		);
	}

	initAsyncShopifyImageUpload(product : Product) : void {
		try {
			fetch(`${ firebaseConfig.apiURL }/products/images/upload`, {
				method  : 'POST',
				headers : {
					'Content-Type' : 'application/json',
				},
				body : JSON.stringify({
					id     : product.graphqlAdminId ?? product.id,
					images : product.images,
				}),
			});
		} catch (error) {
			console.error(error);
		}
	}

	private handleCatchError(error : any, product? : any) : Observable<any> {
		const handle  : string = product?.handle;
		const sku     : string = product?.sku;
		const logData : OptionalLogData = {
			handle,
			sku,
			subject : 'new product',
			details : {
				error,
				product,
				values          : this.values,
				productUploaded : !!handle,
			},
		};

		if (error.toString().includes('Unable to retrieve store with handle')) {
			logData.eventContext = EventContext.CantGetStore;
		} else if (error.toString().includes('Unable to retrieve short code for store with handle')) {
			logData.eventContext = EventContext.CantGetShortCode;
		}

		this.log.error(EventIssuer.NewProductService, logData);

		return of(this.generatePayload(product ?? { handle, sku }));
	}

	private generatePayload(data : any = {}) : SavePayload {
		const product = data.product;
		const productUploaded    : boolean = !!product?.handle;
		const uploadedImageCount : number = ((product?.images) ?? []).length;

		let imagesUploaded : boolean | 'partial' = !!uploadedImageCount;

		if (imagesUploaded) {
			if (
				uploadedImageCount !== (this.values.images as ProductImage[]).length
				|| uploadedImageCount !== this.productImages.parseAttachedImages(product.images).length
			) {
				imagesUploaded = 'partial';
			}
		}

		return {
			imagesUploaded,
			productUploaded,
			sku         : product.sku,
			handle      : product.handle,
			listingType : product.listingType,
			errors      : data.errors,
		};
	}

	public getValues() : NewProductValueMap {
		return this.values;
	}

	private parseValues(product : Product) : Product {
		for (const fieldKey in this.values) {
			const value : NewProductValue = this.values[fieldKey];

			if (Helper.isEmpty(value, { includeEmptyString : true })) {
				continue;
			}

			this.parseProduct(product, fieldKey, value);
			this.parseTag(product, fieldKey, value);
			this.parseMetafield(product, fieldKey, value);
		}

		this.parseTitle(product);
		this.parseWeight(product);
		this.parseNoReturns(product);

		return product;
	}

	private parseProduct(product : Product, fieldKey : string, value : NewProductValue) : Product {
		switch (fieldKey) {
			case 'category':
				product[fieldKey] = product.listingType === 'wholesale'
					? 'wholesale'
					: value as string;

				break;

			case 'division':
				product[fieldKey] = value as Division;

				break;

			case 'department':
				product[fieldKey] = value as Department;

				break;

			case 'title':
			case 'brand':
				product[`display${ Helper.titleCase(fieldKey) }`] = value as string;

			case 'subcategory':
			case 'vendor':
			case 'displaySize':
			case 'tagSize':
			case 'displayColor':
			case 'displayMaterial':
			case 'condition':
			case 'importedOriginalTitle':
				product[fieldKey] = value as string;

				break;

			case 'dressSkirtLength':
				product[fieldKey] = value as DressSkirtLength;

				break;

			case 'tags':
				this.addTag(product, value as string[]);

				break;

			case 'size':
				product.sizes = value as Size[];

				break;

			case 'color':
				product.colors = value as Color[];

				break;

			case 'material':
				product.materials = value as Material[];

				break;

			case 'era':
				product.eras = value as Era[];

				break;

			case 'price':
				product[fieldKey] = value as number;

				break;

			case 'images':
				product[fieldKey] = Helper.removeEmptyItems(value as ProductImage[]);

				break;

			case 'descriptionHtml':
				product[fieldKey] = Helper.sanitizeHtmlToSave(value as string);

				break;

			case 'uploadStartedAt':
				this.parseUploadTime(product, value as string);

				break;
		}

		return product;
	}

	private parseTag(product : Product, fieldKey : string, value : NewProductValue) : Product {
		switch (fieldKey) {
			case 'price':
				this.addTag(product, GetPriceBuckets(value as number), fieldKey);

				break;

			case 'brand':
			case 'size':
			case 'color':
			case 'material':
			case 'era':
			case 'division':
			case 'department':
			case 'category':
			case 'subcategory':
				this.addTag(product, value as string | string[], fieldKey);

				break;

			case 'dressSkirtLength':
				this.addTag(product, value as string, 'dress/skirt length');

				break;
		}

		return product;
	}

	private addTag(product : Product, value : string | string[], structuredTagKey? : string) : void {
		let tags = Helper.listify(value);

		if (structuredTagKey) {
			tags = tags.map(tag => `${ structuredTagKey }:${ tag }`);
		}

		product.tags.push(...tags);
	}

	private parseMetafield(product : Product, fieldKey : string, value : NewProductValue) : Product {
		switch (fieldKey) {
			case 'condition':
			case 'displayBrand':
			case 'displayColor':
			case 'displayMaterial':
			case 'displaySize':
			case 'displayTitle':
			case 'tagSize':
				this.addMetafield(product, fieldKey, value as string);

				break;

			case 'armLength':
			case 'bodyLength':
			case 'bootHeight':
			case 'braceletLength':
			case 'bust':
			case 'depth':
			case 'eyeToEyeLensLength':
			case 'faceDiameter':
			case 'height':
			case 'heightOfLens':
			case 'hem':
			case 'heelHeight':
			case 'hip':
			case 'inseam':
			case 'insideCircumference':
			case 'legOpening':
			case 'length':
			case 'lengthOfSole':
			case 'maxSizeLength':
			case 'minSizeLength':
			case 'overallLength':
			case 'pendantDiameter':
			case 'pendantLength':
			case 'rise':
			case 'shoulder':
			case 'strapLength':
			case 'waist':
			case 'width':
				this.addMetafield(product, fieldKey, value as string, 'INTEGER');

				break;

			case 'sizeDiagram':
				this.parseDiagram(value as string[], product);

				break;
		}

		return product;
	}

	private addMetafield(product : Product, key : ProductMetafieldKey, value : string | string[], valueType : 'STRING' | 'INTEGER' | 'JSON_STRING' = 'STRING') : void {
		const namespace : string = GetMetafieldNamespaceFromKey(key);

		if (!namespace) {
			return;
		}

		if (typeof value !== 'string') {
			value = JSON.stringify(value);
		}

		if (!product.metafieldMap) {
			product.metafieldMap = {};
		}

		product.metafieldMap[key] = {
			key,
			namespace,
			valueType,
			value,
		};

		product.metafields = Object.values(product.metafieldMap);
	}

	private parseTitle(product : Product) : void {
		if (!product.title) {
			product.title = product.sku;
		} else if (product.brand && product.brand !== 'Vintage') {
			product.title = `${ product.title } by ${ product.brand }`;
		}
	}

	private parseWeight(product : Product) : void {
		product.weight = GetWeightByCategory(product.category);
	}

	private parseNoReturns(product : Product) : void {
		if (product.division === 'home') {
			this.addTag(product, 'no-returns');
		}
	}

	private parseDiagram(value : string[], product? : Product) : number {
		const diagramArr : string[] = value.filter(item => !!item.trim());

		if (product != null) {
			this.addMetafield(product, 'sizeDiagram', diagramArr, 'JSON_STRING');
		}

		return diagramArr.length;
	}

	private getManualTagCount(tags : string[] = []) : number {
		return tags
			.filter(tag => {
				const key : string = tag.split(':')?.[0];

				return !this.automaticTagKeys[key];
			})
			.length;
	}

	private parseUploadTime(product : Product, value : string) : void {
		product.uploadStartedAt = value;

		const uploadStartedDate = new Date(product.uploadStartedAt);
		const nowDate = new Date();

		product.createdAt = nowDate.toISOString();

		if (uploadStartedDate) {
			product.uploadDuration = nowDate.getTime() - uploadStartedDate.getTime();
		}
	}

	private parseShopifyProductInput(product : Product) : ShopifyProductInput {
		return {
			title           : product.title,
			vendor          : product.vendor,
			descriptionHtml : product.descriptionHtml,
			productType     : product.category,
			status          : 'ACTIVE',
			tags            : product.tags,
			metafields      : product.metafields,
			variants        : [
				{
					sku           : product.sku,
					price         : product.price,
					weight        : product.weight,
					weightUnit    : 'OUNCES',
					inventoryItem : {
						tracked : true,
					},
					inventoryQuantities : [
						{
							locationId        : 'gid://shopify/Location/18620514363',
							availableQuantity : product.inventory,
						},
					],
				},
			],
		};
	}

	private createLog(payload : SavePayload) : void {
		if (!(payload && payload.productUploaded === true))
			return;

		this.log.create(EventIssuer.NewProductService, {
			subject      : 'new product',
			eventContext : EventContext.NewProduct,
			handle       : payload.handle,
			sku          : payload.sku,
			details      : {
				listingType : payload.listingType,
			},
		});

		if (payload.imagesUploaded === true)
			return;

		this.log.error(EventIssuer.NewProductService, {
			subject      : `${ payload.imagesUploaded ? 'partial ' : '' }image upload failure`,
			eventContext : EventContext.NewProduct,
			handle       : payload.handle,
			sku          : payload.sku,
			details      : {
				listingType : payload.listingType,
			},
		});
	}
}
