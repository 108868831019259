import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { Device } from '@ionic-native/device/ngx';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule, SETTINGS as FIRESTORE_SETTINGS } from '@angular/fire/compat/firestore';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireRemoteConfigModule, DEFAULTS as RemoteConfigDefaults, SETTINGS as RemoteConfigSettings } from '@angular/fire/compat/remote-config';
import { Product } from '@shopthrilling/thrilling-shared';

import { firebaseConfig, environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './components/components.module';
import { PipesModule } from './pipes/pipes.module';
import { ShopifyAdminModule } from './modules/shopify-admin/shopify-admin.module';
import { ImgixService } from './services/imgix/imgix.service';
import { FirestoreService } from './services/firestore/firestore.service';
import { FileStorageService } from './services/file-storage/file-storage.service';
import { CollectionService } from './services/collection/collection.service';
import { StoreService } from './services/store/store.service';
import { OrderService } from './services/order/order.service';
import { ProductService } from './services/product/product.service';
import { ProductImagesService } from './services/product-images/product-images.service';
import { EditedImagesService } from './services/edited-images/edited-images.service';
import { NewProductService } from './services/new-product/new-product.service';
import { BulkQueryService } from './services/bulk-query/bulk-query.service';
import { DownloadService } from './services/download/download.service';
import { WebhookService } from './services/webhook/webhook.service';
import { RemoteConfigService } from './services/remote-config/remote-config.service';
import { SignInPage } from './pages/sign-in/sign-in.page';
import { ForgotPasswordPage } from './pages/forgot-password/forgot-password.page';
import { LogoutPage } from './pages/logout/logout.page';
import { AboutPage } from './pages/about/about.page';
import { ImageUploadComponent } from './components/inputs/image-upload/image-upload.component';
import { RemoteConfigDefaultValues } from './constants/remote-config-default-values';


@NgModule({
	declarations : [
		AppComponent,
		SignInPage,
		ForgotPasswordPage,
		LogoutPage,
		AboutPage,
	],
	imports : [
		BrowserModule,
		FormsModule,
		IonicModule.forRoot({
			rippleEffect : true,
		}),
		IonicStorageModule.forRoot(),
		AppRoutingModule,
		AngularFireAuthModule, // imports firebase/auth, needed for auth features
		AngularFirestoreModule, // imports firebase/firestore, needed for database features
		AngularFireModule.initializeApp(firebaseConfig),
		AngularFireStorageModule,
		AngularFireRemoteConfigModule,
		ComponentsModule,
		PipesModule,
		ShopifyAdminModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled : environment.production,
		}),
	],
	providers : [
		{
			provide  : RouteReuseStrategy,
			useClass : IonicRouteStrategy,
		},
		{
			provide  : FIRESTORE_SETTINGS,
			useValue : {},
		},
		{
			provide  : RemoteConfigDefaults,
			useValue : RemoteConfigDefaultValues,
		},
		{
			provide    : RemoteConfigSettings,
			useFactory : () => environment.production
				? { minimumFetchIntervalMillis : 3_600_000 } // 1 hr
				: { minimumFetchIntervalMillis : 720_000 }, // 12 min
		},
		Device,
		FileStorageService,
		ImgixService,
		FirestoreService,
		CollectionService,
		StoreService,
		OrderService,
		ProductService,
		ProductImagesService,
		EditedImagesService,
		NewProductService,
		BulkQueryService,
		DownloadService,
		WebhookService,
		ImageUploadComponent,
		Product,
		RemoteConfigService,
	],
	bootstrap : [
		AppComponent,
	],
})
export class AppModule {}
