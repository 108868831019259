import { Component, Input } from '@angular/core';

import { InputField } from '../../../types/input-fields';


@Component({
	selector    : 'app-disabled-input',
	templateUrl : './disabled-input.component.html',
	styleUrls   : ['./disabled-input.component.scss'],
})
export class DisabledInputComponent {
	@Input() field?        : InputField;
	@Input() title?        : string;
	@Input() value?        : string;
	@Input() copyDisabled? : boolean;


	constructor() {}
}
