import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take, tap, map } from 'rxjs/operators';

import { HelperService as Helper } from '../../services/helper/helper.service';
import { ShopifyAdminService } from '../../modules/shopify-admin/shopify-admin.service';


@Injectable({
	providedIn : 'root',
})
export class WebhookService {
	constructor(
		private shopifyGraphql : ShopifyAdminService,
	) {}


	getAll() : Observable<any[]> {
		return this.shopifyGraphql.getWebhooks().pipe(
			// tap(data => console.debug(data)),
			map(data =>
				Helper.get(data, ['data', 'webhookSubscriptions', 'edges'])
					.map(item => item.node)
			),
			// tap(data => console.debug(data)),
		);
	}

	create(topic : string, callbackUrl : string, includeFields : string[], metafieldNamespaces : string[]) : Observable<any> {
		return this.shopifyGraphql.createWebhook(topic, callbackUrl, includeFields, metafieldNamespaces).pipe(
			// tap(data => console.debug(data)),
			map(data =>
				Helper.get(data, ['data', 'webhookSubscriptionCreate', 'userErrors', 0, 'message'])
			),
			// tap(data => console.debug(data)),
		);
	}

	delete(id : string) : Observable<any> {
		return this.shopifyGraphql.deleteWebhook(id).pipe(
			// tap(data => console.debug(data)),
		);
	}
}
