import { Material, Department } from '@shopthrilling/thrilling-shared';

import { HelperService as Helper } from '../services/helper/helper.service';

import * as ExportedMaterials from './exported-data/materials_2022-04-27T11_13_37.json';


function getMaterialMap() : any {
	return (ExportedMaterials as any)?.default;
}

export function GetMaterialsByDepartment(department? : Department) : Material[] {
	const materialMap = getMaterialMap();

	const clothingDepts : Department[] = [
		'clothing',
	];
	const accessoryDepts : Department[] = [
		'accessories',
		'bags',
		'jewelry',
	];
	const homeDepts : Department[] = [
		'tableware',
		'decor',
		'textiles',
	];

	const options = [ ...materialMap.base ];

	if (clothingDepts.concat(homeDepts).includes(department)) {
		options.push(...materialMap.clothing);
	}

	if (accessoryDepts.concat(homeDepts).includes(department)) {
		options.push(...materialMap.accessories);
	}

	if (homeDepts.includes(department)) {
		options.push(...materialMap.home);
	}

	// filter out invalid options
	// such as the empty strings currently found in home materials array export
	const cleanOptions = options
		.filter(item => item);

	return Helper.uniqueArray(cleanOptions).sort();
}
