import { ProductMetafieldKey } from '@shopthrilling/thrilling-shared';

import { ProductFieldGrouping } from '../types/product-input-fields';

import { CategoryOptions, DivisionOptions, Colors, GetMaterialOptionsByDepartment, Eras, Conditions, DressSkirtLengths } from './product-options.model';



export function ProductFields() : ProductFieldGrouping {
	return {
		order : [
			'main',
			// 'published',
			// 'tags',
			// 'details',
		],
		groups : {
			main : {
				key   : 'main',
				title : 'Main Info',
				order : [
					'vendor',
					'displayTitle',
					'sku',
					'price',
					'descriptionHtml',
					'images',
					'taxonomyHeader',
					'division',
					'department',
					'category',
					'subcategory',
					'dressSkirtLength',
					'brandHeader',
					'brand',
					'sizesHeader',
					'sizes',
					'displaySize',
					'tagSize',
					'measurementsHeader',
					'measurements',
					'colorsHeader',
					'colors',
					'displayColor',
					'materialsHeader',
					'materials',
					'displayMaterial',
					'erasHeader',
					'eras',
					'conditionHeader',
					'condition',
				],
				fields : {
					vendor : {
						key          : 'vendor',
						title        : 'Store',
						inputType    : 'select',
						hidden       : true,
						readOnly     : true,
						inputOptions : {
							options  : [],
							multiple : false,
						},
					},
					displayTitle : {
						key       : 'displayTitle',
						title     : 'Title (displayTitle)',
						inputType : 'text',
						deletable : false,
					},
					title : {
						key       : 'title',
						title     : 'Title',
						inputType : 'text',
						hidden    : true,
						readOnly  : true,
					},
					sku : {
						key       : 'sku',
						title     : 'SKU',
						inputType : 'text',
						readOnly  : true,
					},
					descriptionHtml : {
						key       : 'descriptionHtml',
						title     : 'Description',
						inputType : 'textarea',
					},
					price : {
						key          : 'price',
						title        : 'Price',
						inputType    : 'number',
						inputOptions : {
							decimalPlaces : 2,
						},
					},
					images : {
						key          : 'images',
						title        : 'Images',
						inputType    : 'image',
						deletable    : true,
						inputOptions : {
							multiple  : true,
							orderable : true,
						},
					},
					division : {
						key          : 'division',
						title        : 'Division',
						inputType    : 'select',
						deletable    : false,
						inputOptions : {
							options  : DivisionOptions(),
							multiple : false,
						},
					},
					department : {
						key          : 'department',
						title        : 'Department',
						inputType    : 'select',
						deletable    : false,
						inputOptions : {
							options  : [],
							multiple : false,
						},
					},
					category : {
						key          : 'category',
						title        : 'Category',
						inputType    : 'select',
						deletable    : false,
						inputOptions : {
							options  : CategoryOptions(),
							multiple : false,
						},
					},
					subcategory : {
						key          : 'subcategory',
						title        : 'Subcategory',
						inputType    : 'select',
						readOnly     : true,
						deletable    : true,
						inputOptions : {
							options  : [],
							multiple : false,
						},
					},
					dressSkirtLength : {
						key          : 'dressSkirtLength',
						title        : 'Dress/Skirt Length',
						inputType    : 'select',
						deletable    : true,
						hidden       : true,
						inputOptions : {
							options  : DressSkirtLengths(),
							multiple : false,
						},
					},
					brand : {
						key       : 'brand',
						title     : 'Brand',
						inputType : 'text',
					},
					sizes : {
						key          : 'sizes',
						title        : 'Sizes',
						description  : 'Select up to 2 consecutive sizes',
						inputType    : 'select',
						inputOptions : {
							options  : [],
							multiple : true,
						},
					},
					displaySize : {
						key       : 'displaySize',
						title     : 'Display Size',
						inputType : 'text',
						deletable : false,
						hidden    : true,
					},
					tagSize : {
						key       : 'tagSize',
						title     : 'Size From Tag',
						inputType : 'text',
						deletable : false,
						hidden    : true,
					},
					measurements : {
						key       : 'measurements',
						title     : 'Measurements',
						inputType : 'measurements',
						hidden    : true,
					},
					colors : {
						key          : 'colors',
						title        : 'Colors',
						description  : 'Select up to 3 colors',
						inputType    : 'select',
						inputOptions : {
							options  : Colors(),
							multiple : true,
						},
					},
					displayColor : {
						key       : 'displayColor',
						title     : 'Display Color',
						inputType : 'text',
						deletable : false,
						hidden    : true,
					},
					materials : {
						key          : 'materials',
						title        : 'Materials',
						description  : 'Select up to 3 materials',
						inputType    : 'select',
						inputOptions : {
							options  : GetMaterialOptionsByDepartment(),
							multiple : true,
						},
					},
					displayMaterial : {
						key       : 'displayMaterial',
						title     : 'Display Material',
						inputType : 'text',
						deletable : false,
						hidden    : true,
					},
					eras : {
						key          : 'eras',
						title        : 'Eras',
						description  : 'Select up to 2 consecutive eras',
						inputType    : 'select',
						inputOptions : {
							options  : Eras(),
							multiple : true,
						},
					},
					condition : {
						key          : 'condition',
						title        : 'Condition',
						inputType    : 'select',
						hidden       : true,
						inputOptions : {
							options  : Conditions(),
							multiple : false,
						},
					},
				},
			},
			published : {
				key   : 'published',
				title : 'Published',
				order : [
					'published',
					'publishedAt',
				],
				fields : {
					published : {
						key       : 'published',
						title     : 'Published',
						inputType : 'boolean',
					},
					publishedAt : {
						key       : 'publishedAt',
						title     : 'Published to Store',
						inputType : 'date',
						readOnly  : true,
					},
				},
			},
			tags : {
				key   : 'tags',
				title : 'Tags',
				order : [
					'tags',
				],
				fields : {
					tags : {
						key        : 'tags',
						title      : 'Tags',
						inputClass : 'tag',
						inputType  : 'text',
					},
				},
			},
			details : {
				key   : 'details',
				title : 'Details',
				order : [
					'weight',
					'inventory',
					'handle',
					'id',
					'graphqlAdminId',
					'createdAt',
					'soldInStoreAt',
					'soldOnlineAt',
				],
				fields : {
					weight : {
						key          : 'weight',
						title        : 'Weight (oz)',
						inputType    : 'number',
						inputOptions : {
							decimalPlaces : 1,
						},
					},
					inventory : {
						key          : 'inventory',
						title        : 'Available Inventory',
						inputType    : 'number',
						readOnly     : true,
						inputOptions : {
							decimalPlaces : 0,
						},
					},
					handle : {
						key       : 'handle',
						title     : 'Handle',
						inputType : 'text',
						readOnly  : true,
					},
					id : {
						key       : 'id',
						title     : 'Firestore ID',
						inputType : 'text',
						readOnly  : true,
					},
					graphqlAdminId : {
						key       : 'graphqlAdminId',
						title     : 'Shopify ID',
						inputType : 'text',
						readOnly  : true,
					},
					createdAt : {
						key       : 'createdAt',
						title     : 'Created At',
						inputType : 'date',
						readOnly  : true,
					},
					soldInStoreAt : {
						key       : 'soldInStoreAt',
						title     : 'Sold In Store At',
						inputType : 'date',
						readOnly  : true,
					},
					soldOnlineAt : {
						key       : 'soldOnlineAt',
						title     : 'Sold Online At',
						inputType : 'date',
						readOnly  : true,
					},
				},
			},
		},
	};
}


const metafieldNamespaceMap : { [ key in ProductMetafieldKey ]? : string } = {
	armLength           : 'size',
	bodyLength          : 'size',
	bootHeight          : 'size',
	braceletLength      : 'size',
	bust                : 'size',
	condition           : 'condition',
	depth               : 'size',
	displayBrand        : 'brand',
	displayColor        : 'color',
	displayMaterial     : 'material',
	displaySize         : 'size',
	displayTitle        : 'main',
	eyeToEyeLensLength  : 'size',
	faceDiameter        : 'size',
	firestoreId         : 'sync',
	heelHeight          : 'size',
	height              : 'size',
	heightOfLens        : 'size',
	hem                 : 'size',
	hip                 : 'size',
	inseam              : 'size',
	insideCircumference : 'size',
	legOpening          : 'size',
	length              : 'size',
	lengthOfSole        : 'size',
	maxSizeLength       : 'size',
	minSizeLength       : 'size',
	overallLength       : 'size',
	pendantDiameter     : 'size',
	pendantLength       : 'size',
	rise                : 'size',
	shoulder            : 'size',
	sizeDiagram         : 'images',
	strapLength         : 'size',
	tagSize             : 'size',
	waist               : 'size',
	width               : 'size',
};

export function GetMetafieldNamespaceFromKey(key : ProductMetafieldKey) : string {
	return metafieldNamespaceMap[key];
}
