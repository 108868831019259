import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';

import { LogService } from '../../services/log/log.service';
import { UserService } from '../../services/user/user.service';


@Component({
	selector    : 'app-forgot-password',
	templateUrl : './forgot-password.page.html',
	styleUrls   : ['./forgot-password.page.scss'],
})
export class ForgotPasswordPage implements OnInit {
	email             : string;
	sent              : boolean = false;
	sendButtonClicked : boolean = false;


	constructor(
		private router : Router,
		private toast  : ToastController,
		private log    : LogService,
		private user   : UserService,
	) {}


	ngOnInit() : void {
		this.initEmail();
	}

	private initEmail() {
		this.email = this.router.getCurrentNavigation()?.extras?.state?.email;
	}

	backToSignIn() : void {
		this.router.navigate(['/signin']);
	}

	onClick() : void {
		this.sendButtonClicked = true;

		this.user
			.sendPasswordResetEmail(this.email)
			.then(this.handlePasswordReset.bind(this));
	}

	private handlePasswordReset(response) : void {
		this.sendButtonClicked = false;

		if (response?.status === 'success')
			this.sent = true;
		else
			this.handleError();
	}

	private handleError() : void {
		this.showToast('Unable to send password reset email. Please try again.', true);
	}

	private async showToast(
		message   : string,
		isError   : boolean = false,
		isWarning : boolean = false,
	) {
		const toast = await this.toast.create({
			message,
			duration : 5000,
			color    : isError ? 'danger' : isWarning ? 'warning' : 'dark',
		});

		toast.present();
	}
}
