import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { kebabCase } from 'lodash';

import { HelperService as Helper } from '../../../services/helper/helper.service';
import { ImgixService } from '../../../services/imgix/imgix.service';
import { FileStorageService } from '../../../services/file-storage/file-storage.service';
import { ProductImagesService } from '../../../services/product-images/product-images.service';
import { ImageValue } from '../../../types/input-fields';


export interface SelectedImage {
	file               : File;
	dataUrl            : string;
	safeUrl?           : SafeUrl;
	uploading?         : boolean;
	percentageChanges? : Observable<number>;
	uploaded?          : boolean;
}


@Component({
	selector    : 'app-image-upload',
	templateUrl : './image-upload.component.html',
	styleUrls   : ['./image-upload.component.scss'],
})
export class ImageUploadComponent {
	@Input() defaultFilename : string;
	@Input() multiple        : boolean = true;
	@Input() showHeader      : boolean = false;

	@Output() upload = new EventEmitter<ImageValue[]>();

	@ViewChild('fileInput', { static : true }) fileInput;

	helper         = Helper;
	selectedImages : SelectedImage[] = [];


	constructor(
		private sanitizer     : DomSanitizer,
		private fileStorage   : FileStorageService,
		private productImages : ProductImagesService,
		public imgix          : ImgixService,
	) {}


	public selectFiles() : void {
		this.fileInput.nativeElement.click();
	}

	async filesChanged(event) : Promise<void> {
		for (const file of event.target.files) {
			let dataUrl : string;

			try {
				dataUrl = await Helper.getDataUrlFromFile(file);
			} catch (error) {
				console.error(error);
			}

			try {
				dataUrl = await this.productImages.resizeAndConvertToMediaType(dataUrl, file.type);
			} catch (error) {
				console.error(error);
			}

			this.selectedImages.push({
				file,
				dataUrl,
			});
		}

		if (this.selectedImages.length)
			this.uploadImages();
	}

	private async uploadImages() : Promise<void> {
		let saveCount : number = 0;

		const imageSrcs : string[] = [];

		for (const image of this.selectedImages) {
			let url : string;

			try {
				url = await this.uploadImage(
					image,
					this.parseFilename(image, this.defaultFilename),
				);
			} catch (error) {
				console.error(error);
			}

			imageSrcs.push(url);

			image['uploaded'] = true;

			saveCount++;

			if (saveCount === this.selectedImages.length)
				this.postUploadImages(imageSrcs);
		}
	}

	private uploadImage(image : SelectedImage, filename : string) : Promise<string> {
		const task = this.fileStorage.uploadDataUrl(
			image.dataUrl,
			filename,
		);

		image['percentageChanges'] = task.percentageChanges();
		image['uploading'] = true;

		return task.getDownloadURL().toPromise();
	}

	public parseFilename(image : SelectedImage, filename? : string) : string {
		filename = kebabCase(filename || image.file.name || 'uploaded image');

		if (Helper.parsePath(filename).fileExtension)
			return filename;
		else
			return `${ filename }.${ image.file.type.replace(Helper.regex.path, '') }`;
	}

	private postUploadImages(imageSrcs : string[]) : void {
		this.upload.emit(imageSrcs.map(src => ({ src })));

		this.selectedImages = [];
	}

	public getSrc(image : SelectedImage) : SafeUrl {
		if (!image.safeUrl)
			image['safeUrl'] = this.sanitizer.bypassSecurityTrustUrl(image.dataUrl);

		return image.safeUrl;
	}
}
