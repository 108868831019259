import { Injectable } from '@angular/core';
import { Observable, of, empty, interval, throwError } from 'rxjs';
import {
	tap,
	take,
	map,
	switchMap,
	delay,
	expand,
	last,
	catchError,
	takeWhile,
} from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { ProductImage } from '@shopthrilling/thrilling-shared';

import { LogService } from '../../services/log/log.service';
import { HelperService as Helper } from '../../services/helper/helper.service';
import { AnyMap } from '../../types/basics';
import { MetafieldInput } from '../../types/input-fields';
import { BulkQueryPayload } from '../../types/bulk-query';
import { OptionalLogData } from '../../types/log';
import { EventIssuer } from '../../constants/EventIssuer';
import { EventContext } from '../../constants/EventContext';


interface WatchQueryOptions {
	query        : any;
	variables?   : AnyMap;
	fetchPolicy? : 'no-cache';
}

interface MutateOptions {
	mutation       : string;
	variables?     : AnyMap;
	refetchQueries : WatchQueryOptions[];
}


export class ShopifyProductImageInput {
	public id?      : string;
	public src?     : string;
	public altText? : string;

	constructor(data : ProductImage) {
		if (data.graphqlAdminId != null) {
			this.id = data.graphqlAdminId;
		}

		if (!this.id) {
			this.src = data.src;
		}

		if (data.altText != null) {
			this.altText = data.altText;
		}
	}
}

export class ShopifyMetafieldInput {
	public id?        : string;
	public key?       : string;
	public namespace? : string;
	public valueType? : string;
	public value?     : string;

	constructor(data : any) {
		if (data.id != null) {
			this.id = data.id;
		}

		if (data.key != null) {
			this.key = data.key;
		}

		if (data.namespace != null) {
			this.namespace = data.namespace;
		}

		if (data.valueType != null) {
			this.valueType = data.valueType;
		}

		if (data.value != null) {
			this.value = data.value;
		}
	}
}


@Injectable({
	providedIn : 'root',
})
export class ShopifyAdminService {
	private queries;
	private customFieldQueries;
	private mutations;
	private customFieldMutations;


	constructor(
		private apollo : Apollo,
		private log    : LogService,
	) {
		this.initQueries();
		this.initMutations();
	}


	private initQueries(): void {
		this.queries = {
			Collections : gql`
				query Collections($first: Int!, $after: String) {
					collections(first: $first, after: $after) {
						pageInfo {
							hasNextPage
						}
						edges {
							cursor
							node {
								id
								handle
								title
								templateSuffix
							}
						}
					}
				}
			`,
			CollectionsExtendedInfo : gql`
				query CollectionsExtendedInfo($first: Int!, $after: String) {
					collections(first: $first, after: $after) {
						pageInfo {
							hasNextPage
						}
						edges {
							cursor
							node {
								id
								handle
								title
								templateSuffix
								descriptionHtml
								productsCount
								image {
									transformedSrc
								}
							}
						}
					}
				}
			`,
			CollectionsMetafields : gql`
				query CollectionsMetafields($first: Int!, $after: String) {
					collections(first: $first, after: $after) {
						pageInfo {
							hasNextPage
						}
						edges {
							cursor
							node {
								id
								handle
								templateSuffix
								metafields(first: 10) {
									edges {
										node {
											id
											namespace
											key
											value
											valueType
										}
									}
								}
							}
						}
					}
				}
			`,
			CollectionByHandle : gql`
				query CollectionByHandle($handle: String!) {
					collectionByHandle(handle: $handle) {
						id
						handle
						title
						templateSuffix
						descriptionHtml
						productsCount
						publicationCount
						image {
							transformedSrc
						}
						metafields(first: 20) {
							edges {
								node {
									id
									namespace
									key
									value
									valueType
								}
							}
						}
					}
				}
			`,
			CollectionByHandleProductCount : gql`
				query CollectionByHandleProductCount($handle: String!) {
					collectionByHandle(handle: $handle) {
						id
						title
						productsCount
					}
				}
			`,
			CollectionProducts : gql`
				query CollectionProducts($handle: String!, $first: Int!, $after: String) {
					collectionByHandle(handle: $handle) {
						id
						products(first: $first, after: $after, sortKey: CREATED, reverse: true) {
							pageInfo {
								hasNextPage
							}
							edges {
								cursor
								node {
									id
									handle
									title
									totalInventory
									featuredImage {
										transformedSrc
									}
									variants(first: 2) {
										edges {
											node {
												id
												sku
											}
										}
									}
								}
							}
						}
					}
				}
			`,
			CollectionProductsWithPrice : gql`
				query CollectionProductsWithPrice($handle: String!, $first: Int!, $after: String) {
					collectionByHandle(handle: $handle) {
						id
						products(first: $first, after: $after, sortKey: CREATED, reverse: true) {
							pageInfo {
								hasNextPage
							}
							edges {
								cursor
								node {
									id
									handle
									title
									totalInventory
									featuredImage {
										transformedSrc
									}
									variants(first: 5) {
										edges {
											node {
												id
												sku
												price
											}
										}
									}
								}
							}
						}
					}
				}
			`,
			Products : gql`
				query Products($first: Int!, $after: String) {
					products(first: $first, after: $after) {
						pageInfo {
							hasNextPage
						}
						edges {
							cursor
							node {
								id
								title
								tags
								metafields(first: 20) {
									edges {
										node {
											id
											namespace
											key
											value
											valueType
										}
									}
								}
							}
						}
					}
				}
			`,
			CustomProducts : gql`
				query CustomProducts($first: Int!, $after: String) {
					products(first: $first, after: $after) {
						pageInfo {
							hasNextPage
						}
						edges {
							cursor
							node {
								id
								title
								tags
							}
						}
					}
				}
			`,
			Product : gql`
				query Product($id : ID!) {
					product(id : $id) {
						id
						handle
						createdAt
						tags
					}
				}
			`,
			Order : gql`
				query Order($id : ID!) {
					order(id : $id) {
						id
						createdAt
						tags
						customer {
							id
							tags
						}
						lineItems(first : 25) {
							edges {
								node {
									id
									product {
										id
									}
								}
							}
						}
					}
				}
			`,
			CustomProduct : gql`
				query GetProduct($id : ID!) {
					product(id : $id) {
						id
						tags
						variants(first: 10) {
							edges {
								node {
									id
									sku
								}
							}
						}
					}
				}
			`,
			ProductImages : gql`
				query ProductImages($id : ID!) {
					product(id : $id) {
						id
						handle
						images(first : 20) {
							edges {
								node {
									id
									altText
									transformedSrc
								}
							}
						}
					}
				}
			`,
			ProductVariants : gql`
				query ProductVariants($id: ID!) {
					product(id: $id) {
						id
						handle
						variants(first: 10) {
							edges {
								node {
									id
								}
							}
						}
					}
				}
			`,
			ProductByHandle : gql`
				query ProductByHandle($handle: String!) {
					productByHandle(handle: $handle) {
						id
						handle
						title
						descriptionHtml
						productType
						vendor
						tags
						createdAt
						publishedAt
						publicationCount
						variants(first: 10) {
							edges {
								node {
									id
									sku
									price
									weight
									inventoryItem {
										id
										inventoryLevels(first: 5) {
											edges {
												node {
													id
													available
												}
											}
										}
									}
								}
							}
						}
						metafields(first: 20) {
							edges {
								node {
									id
									namespace
									key
									value
									valueType
								}
							}
						}
					}
				}
			`,
			InventoryLevelIdByVariantId : gql`
				query InventoryLevelIdByVariantId($id : ID!) {
					productVariant(id : $id) {
						id
						inventoryItem {
							id
							inventoryLevels(first: 5) {
								edges {
									node {
										id
									}
								}
							}
						}
					}
				}
			`,
			ProductsByQuery : gql`
				query Products($first: Int!, $query: String!, $sortKey: ProductSortKeys, $after: String) {
					products(first: $first, query: $query, sortKey: $sortKey, after: $after) {
						pageInfo {
							hasNextPage
						}
						edges {
							cursor
							node {
								id
								handle
								title
								vendor
								totalInventory
								featuredImage {
									transformedSrc
								}
								variants(first: 1) {
									edges {
										node {
											id
											sku
										}
									}
								}
							}
						}
					}
				}
			`,
			CurrentBulkOperation : gql`
				query CurrentBulkOperation {
					currentBulkOperation {
						id
						status
						url
					}
				}
			`,
			WebhookSubscriptions : gql`
				query WebhookSubscriptions {
					webhookSubscriptions(first: 25) {
						edges {
							node {
								id
								topic
								endpoint {
									... on WebhookHttpEndpoint {
										callbackUrl
									}
								}
								includeFields
								metafieldNamespaces
								createdAt
								updatedAt
							}
						}
					}
				}
			`,
			Publications : gql`
				query Publications {
					publications(first: 25) {
						edges {
							cursor
							node {
								id
								name
							}
						}
					}
				}
			`,
		};

		this.customFieldQueries = {
			CollectionProducts : (customFields: string) => gql`
				query CollectionProducts($handle: String!, $first: Int!, $after: String) {
					collectionByHandle(handle: $handle) {
						id
						products(first: $first, after: $after, sortKey: CREATED, reverse: true) {
							pageInfo {
								hasNextPage
							}
							edges {
								cursor
								node {
									id
									${ customFields }
								}
							}
						}
					}
				}
			`,
		};
	}

	private initMutations() : void {
		this.mutations = {
			CollectionCreate : gql`
				mutation CollectionCreate($input: CollectionInput!) {
					collectionCreate(input: $input) {
						collection {
							id
							handle
						}
						userErrors {
							field
							message
						}
					}
				}
			`,
			CollectionUpdate : gql`
				mutation CollectionUpdate($input : CollectionInput!) {
					collectionUpdate(input : $input) {
						collection {
							id
							handle
						}
						userErrors {
							field
							message
						}
					}
				}
			`,
			CollectionUpdateGetMetafields : gql`
				mutation CollectionUpdate($input: CollectionInput!) {
					collectionUpdate(input: $input) {
						collection {
							id
							metafields(first: 20) {
								edges {
									node {
										id
										namespace
										key
										value
										valueType
									}
								}
							}
						}
						userErrors {
							field
							message
						}
					}
				}
			`,
			ProductUpdate : gql`
				mutation ProductUpdate($input : ProductInput!) {
					productUpdate(input : $input) {
						product {
							id
							handle
							title
						}
						userErrors {
							field
							message
						}
					}
				}
			`,
			ProductUpdateReturnImages : gql`
				mutation ProductUpdateReturnImages($input : ProductInput!) {
					productUpdate(input : $input) {
						product {
							id
							handle
							title
							images(first : 20) {
								edges {
									node {
										id
										altText
										transformedSrc
									}
								}
							}
						}
						userErrors {
							field
							message
						}
					}
				}
			`,
			ProductImageUpdate : gql`
				mutation ProductImageUpdate($productId : ID!, $image : ImageInput!) {
					productImageUpdate(productId : $productId, image : $image) {
						image {
							id
							altText
							transformedSrc
						}
						userErrors {
							field
							message
						}
					}
				}
			`,
			ProductUpdateGetMetafields : gql`
				mutation ProductUpdate($input: ProductInput!) {
					productUpdate(input: $input) {
						product {
							id
							metafields(first: 20) {
								edges {
									node {
										id
										namespace
										key
										value
										valueType
									}
								}
							}
						}
						userErrors {
							field
							message
						}
					}
				}
			`,
			ProductUpdateGetMetafieldsAndImages : gql`
				mutation ProductUpdate($input: ProductInput!) {
					productUpdate(input: $input) {
						product {
							id
							metafields(first: 20) {
								edges {
									node {
										id
										namespace
										key
										value
										valueType
									}
								}
							}
							images(first : 20) {
								edges {
									node {
										id
										altText
										transformedSrc
									}
								}
							}
						}
						userErrors {
							field
							message
						}
					}
				}
			`,
			ProductVariantUpdate : gql`
				mutation ProductVariantUpdate($input: ProductVariantInput!) {
					productVariantUpdate(input: $input) {
						product {
							id
						}
						productVariant {
							id
						}
						userErrors {
							field
							message
						}
					}
				}
			`,
			InventoryAdjustQuantity : gql`
				mutation InventoryAdjustQuantity($input: InventoryAdjustQuantityInput!) {
					inventoryAdjustQuantity(input: $input) {
						inventoryLevel {
							id
							available
						}
						userErrors {
							field
							message
						}
					}
				}
			`,
			ProductCreate : gql`
				mutation ProductCreate($input : ProductInput!) {
					productCreate(input : $input) {
						product {
							id
							handle
							title
							images(first : 20) {
								edges {
									node {
										id
										altText
										transformedSrc
									}
								}
							}
						}
						userErrors {
							field
							message
						}
					}
				}
			`,
			PublicationsPublish : gql`
				mutation PublicationsPublish($id: ID!, $input: [PublicationInput!]!) {
					publishablePublish(id: $id, input: $input) {
						publishable {
							publicationCount
							availablePublicationCount
						}
						userErrors {
							field
							message
						}
					}
				}
			`,
			MetafieldDelete : gql`
				mutation MetafieldDelete($input: MetafieldDeleteInput!) {
					metafieldDelete(input: $input) {
						deletedId
						userErrors {
							field
							message
						}
					}
				}
			`,
			BulkOperationCancel : gql`
				mutation BulkOperationCancel($id: ID!) {
					bulkOperationCancel(id: $id) {
						bulkOperation {
							id
						}
						userErrors {
							field
							message
						}
					}
				}
			`,
			WebhookSubscriptionCreate : gql`
				mutation WebhookSubscriptionCreate($topic: WebhookSubscriptionTopic!, $webhookSubscription: WebhookSubscriptionInput!) {
					webhookSubscriptionCreate(topic: $topic, webhookSubscription: $webhookSubscription) {
						userErrors {
							field
							message
						}
						webhookSubscription {
							id
						}
					}
				}
			`,
			WebhookSubscriptionDelete : gql`
				mutation WebhookSubscriptionDelete($id: ID!) {
					webhookSubscriptionDelete(id: $id) {
						deletedWebhookSubscriptionId
						userErrors {
							field
							message
						}
					}
				}
			`,
			CollectionAddProducts : gql`
				mutation CollectionAddProducts($id: ID!, $productIds: [ID!]!) {
					collectionAddProducts(id: $id, productIds: $productIds) {
						collection {
							id
						}
						userErrors {
							field
							message
						}
					}
				}
			`,
		};

		this.customFieldMutations = {
			BulkQuery : (customFields: string) => gql`
				mutation BulkOperationRunQuery {
					bulkOperationRunQuery(
						query: """
							{
								${ customFields }
							}
						"""
					) {
						bulkOperation {
							id
							status
						}
						userErrors {
							field
							message
						}
					}
				}
			`,
		};
	}


	private doQuery(
		query       : string | any,
		variables?  : AnyMap,
		first?      : number,
		paginate    : boolean = false,
		parentPath? : string[],
		increment   : number = 50,
		throttle    : number = 100,
		incremental : boolean = false,
	) : Observable<any> {
		if (!query)
			return throwError('No query was provided');
		else if (typeof query === 'string')
			query = this.queries[query];

		if (variables == null)
			variables = {};

		if (paginate) {
			if (!(parentPath != null && Array.isArray(parentPath) && parentPath.length))
				return throwError('A paginated query must specify a path to paginated child data');
			else if (parentPath[0] !== 'data')
				parentPath.unshift('data');

			if (first != null && first < increment)
				variables['first'] = first;
			else
				variables['first'] = increment;
		} else if (first != null)
			variables['first'] = first;

		return this.apollo
			.watchQuery(this.getQueryOptions(query, variables))
			.valueChanges
			.pipe(
				// tap(data => console.debug(data)),
				switchMap(data => {
					if (paginate) {
						return this.paginate(
							data,
							query,
							parentPath,
							first,
							throttle,
							incremental,
							variables,
						);
					} else
						return of(data);
				}),
				catchError(this.handleError.bind(this)),
				// tap(data => console.debug(data)),
			);
	}

	private getQueryOptions(query : any, variables : any) : any {
		const queryName : string = Helper.get(query, ['definitions', 0, 'name', 'value']),
			queryOptions : any = {
				query,
				variables,
			};

		if (['Collections', 'CurrentBulkOperation'].includes(queryName))
			queryOptions['fetchPolicy'] = 'no-cache';

		return queryOptions;
	}

	private handleError(error : any): Observable<any> {
		console.error(error);

		const errorStr : string = error.toString();
		const isThrottled = /Throttled/.test(errorStr);
		const message = `Encountered error: ${ isThrottled ? 'throttled' : errorStr }`;
		const payload : OptionalLogData = {
			subject : message,
			details : error,
		};

		if (isThrottled) {
			payload.eventContext = EventContext.ShopifyThrottled;

			console.debug('THROTTLED');
		}

		this.log.error(EventIssuer.ShopifyAdminService, payload);

		return of({ error, message });
	}

	private paginate(
		data,
		query       : any,
		parentPath  : string[],
		first?      : number,
		throttle    : number = 100,
		incremental : boolean = false,
		variables?  : AnyMap,
		pageLimit   : number = 250,
	) : Observable<any> {
		const  allItems : any[] = [];

		let page : number = 0;
		let stream$ : Observable<any> = of(data).pipe(
			expand(data => {
				console.info(`${ incremental ? 'incremental ' : '' }page ${ ++page }`);

				const parent = Helper.get(data, parentPath);
				const lastEdge = parent.edges[parent.edges.length - 1];
				const limitReached : boolean = first != null && allItems.length >= first;
				const getMore : boolean = (
					!limitReached
					&& Helper.get(parent, ['pageInfo', 'hasNextPage']) === true
					&& !!lastEdge
					&& !!lastEdge.cursor
				);

				if (getMore) {
					if (page === 1) {
						allItems.push(...parent.edges);
					}

					variables.after = lastEdge.cursor;

					return of(null).pipe(
						delay(throttle),
						switchMap(() => this.apollo
							.watchQuery({
								query,
								variables,
							})
							.valueChanges.pipe(
								take(1),
								map((data : any) => {
									const payload = Helper.deepCopy(data);
									const parent = Helper.get(payload, parentPath);

									allItems.push(...parent.edges);

									parent.edges = allItems;

									return payload;
								}),
							)
						),
					);
				} else {
					if (limitReached) {
						parent.edges.splice(first);
					}

					return empty();
				}
			}),
			take(pageLimit),
		);

		if (!incremental) {
			stream$ = stream$.pipe(
				last(),
			);
		}

		return stream$;
	}

	getCollections(
		first?       : number,
		paginate     : boolean = true,
		increment    : number = 250,
		throttle?    : number,
		extendedInfo : boolean = false,
		incremental  : boolean = false,
	) : Observable<any> {
		let queryName: string = 'Collections';

		if (extendedInfo)
			queryName = 'CollectionsExtendedInfo';

		return this.doQuery(
			queryName,
			null,
			first,
			paginate,
			['collections'],
			increment,
			throttle,
			incremental,
		);
	}

	getCollectionsMetafields(): Observable<any> {
		return this.doQuery(
			'CollectionsMetafields',
			null,
			null,
			true,
			['collections'],
			25,
			1000,
		);
	}

	getCollectionByHandle(handle: string): Observable<any> {
		return this.doQuery(
			'CollectionByHandle',
			{ handle },
		);
	}

	getCollectionByHandleProductCount(handle: string): Observable<any> {
		return this.doQuery(
			'CollectionByHandleProductCount',
			{ handle },
		);
	}

	getProducts(
		increment   : number = 50,
		throttle    : number = 250,
		paginate    : boolean = false,
		incremental : boolean = false,
		customInfo  : boolean = false,
		variables?  : any,
	) : Observable<any> {
		let queryName: string = 'Products';

		if (customInfo)
			queryName = 'CustomProducts';

		return this.doQuery(
			queryName,
			variables,
			null,
			paginate,
			['products'],
			increment,
			throttle,
			incremental
		);
	}

	getCollectionProducts(
		handle      : string,
		withPrice   : boolean = false,
		first?      : number,
		increment   : number = 50,
		throttle    : number = 250,
		incremental : boolean = true,
	) : Observable<any> {
		let queryName: string = 'CollectionProducts';

		if (withPrice)
			queryName = 'CollectionProductsWithPrice';

		return this.doQuery(
			queryName,
			{ handle },
			first,
			true,
			['collectionByHandle', 'products'],
			increment,
			throttle,
			incremental,
		);
	}

	getCollectionProductsCustomFields(
		handle       : string,
		increment    : number = 50,
		throttle     : number = 250,
		customFields : string,
		incremental  : boolean = true,
	) : Observable<any> {
		return this.doQuery(
			this.customFieldQueries.CollectionProducts(customFields),
			{ handle },
			null,
			true,
			['collectionByHandle', 'products'],
			increment,
			throttle,
			incremental,
		);
	}

	getProductsByQuery(
		query        : string,
		sortKey      : string = 'UPDATED_AT',
		first?       : number,
		paginate     : boolean = false,
		increment    : number = 25,
		throttle     : number = 250,
		incremental? : boolean,
	) : Observable<any> {
		return this.doQuery(
			'ProductsByQuery',
			{ query, sortKey },
			first,
			paginate,
			['products'],
			increment,
			throttle,
			incremental,
		);
	}

	getProductByID(id : string, customInfo : boolean = false): Observable<any> {
		let queryName : string = 'Product';

		if (customInfo)
			queryName = 'CustomProduct';

		return this.doQuery(
			queryName,
			{ id },
		);
	}

	getProductImages(id: string): Observable<any> {
		return this.doQuery(
			'ProductImages',
			{ id },
		);
	}

	getProductVariants(id : string) : Observable<any> {
		return this.doQuery(
			'ProductVariants',
			{ id },
		);
	}

	getInventoryLevelIdByVariantId(id : string) : Observable<any> {
		return this.doQuery(
			'InventoryLevelIdByVariantId',
			{ id },
		);
	}

	getCurrentBulkOperation(
		pollInterval : number = 100,
		timeout      : number = 20000,
		payload?     : BulkQueryPayload,
		logProgress  : boolean = false,
	) : Observable<BulkQueryPayload> {
		const isRunning = (data) : boolean => data && (data.status === 'RUNNING' || data.status === 'CANCELING');

		let counter : number;

		return interval(pollInterval).pipe(
			map(count => counter = count),
			switchMap(() => this.doQuery('CurrentBulkOperation')),
			// tap(data => console.debug(data)),
			map(response => Helper.get(response, ['data', 'currentBulkOperation'])),
			tap(data => logProgress ? console.info(counter, data) : null),
			takeWhile(() => counter < (timeout / pollInterval), true),
			takeWhile(isRunning, true),
			last(),
			map(data => {
				payload = {
					...payload,
					success : !isRunning(data),
				};

				if (!payload.id)
					payload['id'] = data.id;

				if (payload.success)
					payload['url'] = data.url;
				else
					payload['error'] = 'Operation timed out';

				return payload;
			}),
		);
	}

	getWebhooks(): Observable<any> {
		return this.doQuery('WebhookSubscriptions');
	}

	getPublications(): Observable<any> {
		return this.doQuery('Publications');
	}



	private doMutate(mutation : any, input : any = {}, refetchQueries : any[] = [], otherVars : AnyMap = {}) : Observable<any> {
		if (!mutation)
			return throwError('No query was provided');
		else if (typeof mutation === 'string')
			mutation = this.mutations[mutation];

		return this.apollo.mutate(this.getMutateOptons(mutation, input, refetchQueries, otherVars));
	}

	private getMutateOptons(mutation : any, input : any = {}, refetchQueries : any[] = [], otherVars : AnyMap = {}) : any {
		const mutationName : string = Helper.get(mutation, ['definitions', 0, 'name', 'value']),
			options : any = {
				mutation,
				variables : {
					input,
					...otherVars,
				},
				refetchQueries,
			};

		if (['BulkOperationRunQuery'].includes(mutationName))
			options['fetchPolicy'] = 'no-cache';

		return options;
	}

	private createCollectionOrProduct(type : 'collection' | 'product', input : any) : Observable<any> {
		return this.doMutate(`${ Helper.titleCase(type) }Create`, input);
	}

	private updateCollectionOrProduct(type : 'collection' | 'product', id : string, updates : any, handle? : string, returnImages? : boolean) : Observable<any> {
		const titleType : string = Helper.titleCase(type),
			query : string = `${ titleType }Update${ type === 'product' && returnImages ? 'ReturnImages' : '' }`,
			refetchQueries : WatchQueryOptions[] = [];

		if (handle) {
			refetchQueries.push({
				query     : this.queries[`${ titleType }ByHandle`],
				variables : {
					handle,
				},
			});
		}

		return this.doMutate(
			query,
			{
				id,
				...updates,
			},
			refetchQueries
		);
	}

	createCollection(input : any) : Observable<any> {
		return this.createCollectionOrProduct('collection', input);
	}

	updateCollection(id : string, updates : any) : Observable<any> {
		return this.updateCollectionOrProduct('collection', id, updates);
	}

	createProduct(input) : Observable<any> {
		return this.createCollectionOrProduct('product', input);
	}

	updateProduct(id : string, updates, handle? : string, returnImages? : boolean) : Observable<any> {
		return this.updateCollectionOrProduct('product', id, updates, handle, returnImages);
	}

	updateProductImage(productId : string, imageId : string, src? : string, altText? : string) : Observable<any> {
		const image = { id : imageId };

		if (src) {
			image['src'] = src;
		}

		if (altText) {
			image['altText'] = altText;
		}

		return this.doMutate('ProductImageUpdate', null, null, {
			productId,
			image,
		});
	}

	updateProductVariant(id : string, updates, handle? : string) : Observable<any> {
		const refetchQueries : WatchQueryOptions[] = [];

		if (handle) {
			refetchQueries.push({
				query     : this.queries.ProductByHandle,
				variables : {
					handle,
				},
			});
		}

		return this.doMutate(
			'ProductVariantUpdate',
			{
				id,
				...updates,
			},
			refetchQueries
		);
	}

	updateProductInventoryQuantity(inventoryLevelId : string, availableDelta : number) : Observable<any> {
		return this.doMutate('InventoryAdjustQuantity', {
			inventoryLevelId,
			availableDelta,
		});
	}

	publish(id : string) : Observable<any> {
		return this.updatePublications(id, true);
	}

	unpublish(id : string) : Observable<any> {
		return this.updatePublications(id, false);
	}

	private updatePublications(id : string, publish : boolean) : Observable<any> {
		const api : string[] = [
			'27699380283', // Storefront & Admin API
		];
		const allChannels : string[] = [
			'77125976217', // Emotive
			'61827252377', // Facebook
			'29969481787', // Google
			'56204853401', // Microsoft
			'27483275323', // Online Store
			'67043000473', // Pinterest
			'30067589179', // Point of Sale
		];
		const channelList : string[] = [].concat(api);

		if (publish) {
			channelList.push(...allChannels);
		}

		const channels : Array<{ publicationId : string }> = channelList
			.map(id => ({
				publicationId : `gid://shopify/Publication/${ id }`,
			}));

		return this.doMutate(
			'PublicationsPublish',
			channels,
			null,
			{ id }
		);
	}

	deleteMetafield(id : string) : Observable<any> {
		return this.doMutate('MetafieldDelete', { id });
	}

	updateCollectionGetMetafields(input : any) : Observable<any> {
		return this.doMutate('CollectionUpdateGetMetafields', input).pipe(
			// tap(data => console.debug(data)),
			map(data => {
				const collection = data?.data?.collectionUpdate?.collection;

				collection.metafields = Helper.flattenEdgeNodeArray(collection?.metafields);

				return collection;
			}),
			// tap(data => console.debug(data)),
		);
	}

	upsertProductMetafield(id : string, metafield : MetafieldInput) : Observable<MetafieldInput> {
		const metafields = Helper.listify(metafield);

		return this.doMutate(`ProductUpdateGetMetafields`, {
			id,
			metafields,
		}).pipe(
			// tap(data => console.debug(data)),
			map(data => Helper.get(data, ['data', 'productUpdate', 'product', 'metafields', 'edges'])),
			map(list => list?.map(item => item.node)),
			map(list => list?.filter(item => item.key === metafield.key)),
			map(list => list?.length ? list[0] : null),
			// tap(data => console.debug(data)),
		);
	}

	updateProductGetMetafieldsAndImages(input : any) : Observable<any> {
		return this.doMutate('ProductUpdateGetMetafieldsAndImages', input).pipe(
			// tap(data => console.debug(data)),
			map(data => {
				const product = data?.data?.productUpdate?.product;

				product.metafields = Helper.flattenEdgeNodeArray(product?.metafields);

				product.images = Helper.flattenEdgeNodeArray(product?.images);

				return product;
			}),
			// tap(data => console.debug(data)),
		);
	}

	bulkQuery(payload : BulkQueryPayload) : Observable<BulkQueryPayload> {
		return this.doMutate(this.customFieldMutations.BulkQuery(payload.query)).pipe(
			take(1),
			// tap(data => console.debug(data)),
			map((response : any) => {
				const bulkOperation : any = Helper.get(response, ['data', 'bulkOperationRunQuery', 'bulkOperation']);

				return {
					...payload,
					id      : bulkOperation && bulkOperation.id ? bulkOperation.id : null,
					success : !!bulkOperation && bulkOperation.status === 'CREATED',
				};
			}),
		);
	}

	cancelBulkQuery(id : string) : Observable<any> {
		return this.doMutate('BulkOperationCancel', null, null, { id });
	}

	createWebhook(topic : string, callbackUrl : string, includeFields : string[], metafieldNamespaces : string[] = []) : Observable<any> {
		return this.doMutate(
			'WebhookSubscriptionCreate',
			null,
			[{ query : this.queries.WebhookSubscriptions }],
			{
				topic,
				webhookSubscription : {
					callbackUrl,
					includeFields,
					metafieldNamespaces,
					format : 'JSON',
				},
			},
		);
	}

	deleteWebhook(id: string) : Observable<any> {
		return this.doMutate(
			'WebhookSubscriptionDelete',
			null,
			[{ query : this.queries.WebhookSubscriptions }],
			{ id },
		);
	}

	getOrderById(id : string) : Observable<any> {
		return this.doQuery(
			'Order',
			{ id },
		);
	}

	addProductsToCollection(id : string, productIds : string[]) : Observable<any> {
		return this.doMutate('CollectionAddProducts', null, null, {
			id,
			productIds,
		});
	}
}
