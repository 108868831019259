import { Component, Input, Output, EventEmitter } from '@angular/core';

import { InputField } from '../../../types/input-fields';


@Component({
	selector    : 'app-input-class-router',
	templateUrl : './input-class-router.component.html',
	styleUrls   : ['./input-class-router.component.scss'],
})
export class InputClassRouterComponent {
	@Input() field        : InputField;
	@Input() loading      : boolean;
	@Input() itemCategory : string;

	@Output() save = new EventEmitter<any>();


	constructor() {}


	onSave(value : any) : void {
		this.save.emit(value);
	}
}
