import { Injectable } from '@angular/core';
import _ from 'lodash';

import { firebaseConfig } from '../../../environments/environment';


@Injectable({
	providedIn : 'root',
})
export class ImgixService {
	private imgixDomains : string[] = [
		'thrill-goog-prod.imgix.net',
		'thrill-goog-dev.imgix.net',
		'thrilling.imgix.net',
		'thrilling-1.imgix.net',
		'thrilling-2.imgix.net',
		'thrilling-3.imgix.net',
	];
	private domainMap = {
		'storage.googleapis.com' : {
			imgixDomain : /dev/.test(firebaseConfig.projectId)
				? this.imgixDomains[1]
				: this.imgixDomains[0],
			stripPath : true,
		},
		'firebasestorage.googleapis.com' : {
			containsRules : {
				'thrilling-portal.appspot.com' : {
					imgixDomain : this.imgixDomains[0],
					stripPath   : true,
				},
				'thrilling-dev-portal.appspot.com' : {
					imgixDomain : this.imgixDomains[1],
					stripPath   : true,
				},
			},
		},
		'cdn.shopify.com' : {
			imgixDomain : this.imgixDomains[2],
			stripPath   : false,
		},
	};
	private defaultOpts = {
		auto : 'compress',
		fm   : 'pjpg',
		fit  : 'crop',
		crop : 'faces,edges',
		w    : '768',
		bg   : 'fff',
		dpr  : '2',
	};
	private colors = {
		'white'         : 'fff',
		'black'         : '000',
		'accent'        : 'ffea00',
		'turquoise'     : '1abc9c',
		'green_sea'     : '16a085',
		'emerald'       : '2ecc71',
		'nephritis'     : '27ae60',
		'peter_river'   : '3498db',
		'belize_hole'   : '2980b9',
		'amethyst'      : '9b59b6',
		'wisteria'      : '8e44ad',
		'wet_asphalt'   : '34495e',
		'midnight_blue' : '2c3e50',
		'sun_flower'    : 'f1c40f',
		'orange'        : 'f39c12',
		'carrot'        : 'e67e22',
		'pumpkin'       : 'd35400',
		'alizarin'      : 'e74c3c',
		'pomegranate'   : 'c0392b',
		'clouds'        : 'ecf0f1',
		'silver'        : 'bdc3c7',
		'concrete'      : '95a5a6',
		'asbestos'      : '7f8c8d',
	};

	constructor() {
		this.initWebpSupport();
	}

	private async initWebpSupport() : Promise<void> {
		this.defaultOpts['fm'] = await this.checkWebpSupport() ? 'webp' : 'pjpg';
	}

	private checkWebpSupport() : Promise<boolean> {
		return new Promise((resolve) => {
			const lossyImg = "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
				img = new Image();

			img.onload = () => resolve(img.width > 0 && img.height > 0);

			img.onerror = () => resolve(false);

			img.src = "data:image/webp;base64," + lossyImg;
		});
	}

	private queryBuilder(opts, ratio? : string) : string {
		const blendedOpts = _.assign({}, this.defaultOpts, opts);

		// replace known color strings with their hex value
		if (blendedOpts.bg && this.colors[blendedOpts.bg])
			blendedOpts.bg = this.colors[blendedOpts.bg];

		// add height, based on width
		if (ratio) {
			if (ratio === 'landscape')
				blendedOpts['h'] = (parseFloat(blendedOpts.w) * 2 / 3).toString();
			else if (ratio === 'portrait')
				blendedOpts['h'] = (parseFloat(blendedOpts.w)  * 1.5).toString();
			else if (ratio !== 'auto')
				blendedOpts['h'] = blendedOpts.w;
		} else 
			blendedOpts['h'] = blendedOpts.w;
		

		let query : string = '';

		// add all opts to query
		for (const opt in blendedOpts) 
			query += `&${ opt }=${ blendedOpts[opt] }`;
		
		return query.replace(/^&/, '?');
	}

	private isValidImgixUrl(url : string) : boolean {
		// if not valid URL, shut it down
		if (!/^(https?):\/\/[^\s$.?#].[^\s]*$/.test(url))
			return false;

		const domain : string = url.split(/\/\//)[1].split(/\//)[0].split(/\?/)[0];

		return this.imgixDomains.includes(domain) || !!this.domainMap[domain];
	}

	image(url : string, width? : number, ratio? : 'landscape' | 'portrait' | 'square' | 'auto', opts?) : string {
		// prevent invalid URLs from proceeding
		if (!this.isValidImgixUrl(url))
			return url;

		if (!opts)
			opts = {};

		if (width && !opts.w)
			opts['w'] = width.toString();

		return this.url(url) + this.queryBuilder(opts, ratio);
	}

	bgImage(url : string, width? : number, ratio? : 'landscape' | 'portrait' | 'square' | 'auto', opts?) : string {
		return `url(${ this.image(url, width, ratio, opts) })`;
	}

	url(url : string) : string {
		// if URL is not a valid URL, things will break
		// prevent invalid URLs from proceeding
		if (!this.isValidImgixUrl(url))
			return url;

		url = url.split(/\/\//)[1].split(/\?/)[0];

		const domain : string = url.split(/\//)[0];

		url = 'https://' + url;

		let swappedDomain = this.domainMap[domain];

		if (!swappedDomain)
			return url;

		if (!swappedDomain.imgixDomain && swappedDomain.containsRules) {
			for (const rule in swappedDomain.containsRules) {
				if (url.includes(rule)) {
					swappedDomain = swappedDomain.containsRules[rule];

					break;
				}
			}

			if (!swappedDomain.imgixDomain)
				return url;
		}

		if (swappedDomain.stripPath) {
			let substrings = url.split(/\//),
				imagePath = '';

			if ('storage.googleapis.com' === domain) {
				substrings = substrings.slice(4);
				imagePath = substrings.join('/');
			} else
				imagePath = substrings.pop();

			url = `https://${ domain }/${ imagePath }`;
		}

		return url.replace(domain, swappedDomain.imgixDomain || domain);
	}
}
