import { Division, Department } from '@shopthrilling/thrilling-shared';

import { SelectOption } from '../types/input-fields';

import { Divisions, Departments, Categories, Subcategories, GetDepartmentsByDivision, GetDepartmentByCategory, GetCategoriesByDepartment, GetCategoriesByTaxonomy, GetSubcategoriesByTaxonomy, GetSubcategoriesByCategory } from './taxonomy.model';
import { GetSizeList } from './size.model';
import { GetTagGroup } from './tags.model';
import { GetMaterialsByDepartment } from './material.model';


export function SelectOptionify(list : string[] | any[]) : SelectOption[] {
	return typeof list?.[0] === 'string'
		? (list as string[]).map(value => ({ value }))
		: (list as any[] ?? []).map(item => ({
			value : item.value,
			title : item.title,
		}));
}

export function DivisionOptions() : SelectOption[] {
	return SelectOptionify(Divisions());
}

export function DepartmentOptions() : SelectOption[] {
	return SelectOptionify(Departments());
}

export function CategoryOptions() : SelectOption[] {
	return SelectOptionify(Categories());
}

export function SubcategoryOptions() : SelectOption[] {
	return SelectOptionify(Subcategories());
}

export function DressSkirtLengths() : SelectOption[] {
	return SelectOptionify(GetTagGroup('dress/skirt length'));
}

export function SubcategoriesByCategory(category : string) : SelectOption[] {
	return SelectOptionify(GetSubcategoriesByCategory(category));
}

export function GetDepartmentOptionsByDivision(division : Division) : SelectOption[] {
	return SelectOptionify(GetDepartmentsByDivision(division));
}

export function GetCategoryOptionsByDepartment(department : Department) : SelectOption[] {
	return SelectOptionify(GetCategoriesByDepartment(department));
}

export function GetCategoryOptionsByTaxonomy(division? : Division, department? : Department) : SelectOption[] {
	return SelectOptionify(GetCategoriesByTaxonomy(division, department));
}

export function GetSubcategoryOptionsByTaxonomy(division? : Division, department? : Department, category? : string) : SelectOption[] {
	return SelectOptionify(GetSubcategoriesByTaxonomy(division, department, category));
}


export const SizeOptions = (department? : Department) : SelectOption[] => SelectOptionify(GetSizeList(department));

export function GetSizeOptionsByCategory(category : string) : SelectOption[] {
	const department : Department = GetDepartmentByCategory(category);

	return SizeOptions(department);
}


export function Colors() : SelectOption[] {
	return SelectOptionify([
		'beige',
		'black',
		'blue',
		'brown',
		'gold',
		'gray',
		'green',
		'multicolor',
		'off-white',
		'orange',
		'pink',
		'purple',
		'red',
		'silver',
		'white',
		'yellow',
	]);
}


export function GetMaterialOptionsByDepartment(department? : Department) : SelectOption[] {
	return SelectOptionify(GetMaterialsByDepartment(department));
}


export function Eras() : SelectOption[] {
	return SelectOptionify([
		'2000s',
		'1990s',
		'1980s',
		'1970s',
		'1960s',
		'1950s',
		'1940s',
		'1930s',
		'1920s',
		'1910s',
		'edwardian',
		'victorian',
	]);
}

export function Conditions() : SelectOption[] {
	return [
		{
			value : 'new',
			title : 'New (with tags or never worn)',
		},
		{
			value : 'used_like_new',
			title : 'Excellent (no visible signs of wear)',
		},
		{
			value : 'used_good',
			title : 'Very Good (minimal signs of wear)',
		},
		{
			value : 'used_fair',
			title : 'Good (some holes or marks on the item)',
		},
		{
			value : 'used',
			title : 'Worn (needs some love to restore to optimal condition)',
		},
	];
}
