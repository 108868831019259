import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Metafield , ImageValue } from '../../../types/input-fields';


@Component({
	selector    : 'app-metafield-class-input',
	templateUrl : './metafield-class-input.component.html',
	styleUrls   : ['./metafield-class-input.component.scss'],
})
export class MetafieldClassInputComponent {
	@Input() field   : Metafield;
	@Input() loading : boolean;

	@Output() save = new EventEmitter<any>();

	deleteDisabled : boolean = false;


	constructor() {}


	parseBoolean(value : string) : boolean {
		return /^true$/i.test(value);
	}

	onUpdateToggle(event : any) : void {
		this.onSave(!!event.detail.checked);
	}

	delete() : void {
		this.deleteDisabled = true;

		this.onSave(null);
	}

	onSave(newValue : any) : void {
		if (this.field.inputType === 'image') {
			newValue = this.flattenImageValue(newValue);
		}

		this.save.emit(newValue);
	}

	private flattenImageValue(newValue) : string | string[] {
		if (this.field.inputOptions?.multiple)
			return newValue?.map(image => image.src);
		else
			return newValue?.src;
	}
}
