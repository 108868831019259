import { HelperService as Helper } from '../services/helper/helper.service';
import { TagMap, TagCategories, GeneralTagCategory, SpecificTagCategory } from '../types/tag';


const tagsDictionary : { [ key in GeneralTagCategory | SpecificTagCategory ] : string[] } = {
	'neckline' : [
		'v-neck',
		'scoop',
		'henley',
		'crew',
		'turtleneck',
		'cowl',
		'halter',
		'boat neck',
		'strapless',
		'sweetheart',
		'polo',
		'square',
		'collared',
		'mock neck',
		'off-the-shoulder',
		'cold shoulder',
	],
	'tshirt type' : [
		'crop',
		'oversized',
		'tunic',
		'corset',
		'band tee',
	],
	'sleeve length' : [
		'long sleeve',
		'short sleeve',
		'3/4 length sleeve',
		'sleeveless',
		'camisole',
		'one shoulder',
		'bell sleeve',
		'dolman',
		'spaghetti strap',
	],
	'jacket type' : [
		'bomber',
		'flight',
		'double-breasted',
		'track',
		'puffer',
		'trench',
		'trucker',
		'bolero',
		'cargo',
		'windbreaker',
		'shrug',
		'varsity',
		'single-breasted',
		'moto',
		'parka',
	],
	'dress type' : [
		'shift dress',
		'babydoll',
		'fit and flare',
		'shirt dress',
		'gown',
		'a-line',
		'wrap',
		'drop waist',
		'pencil (skirt)',
		'empire waist',
		'bodycon',
	],
	'dress/skirt length' : [
		'high low',
		'knee length',
		'maxi',
		'midi',
		'mini',
	],
	'sweater/sweatshirt type' : [
		'hoodie',
		'pull over',
		'crewneck',
		'quarter zip up',
		'zip up',
	],
	'pant type' : [
		'trouser',
		'culotte',
		'capri',
		'bermuda shorts',
		'sweatpant',
		'cargo',
		'bike shorts',
	],
	'pant rise' : [
		'mid',
		'low',
		'high',
	],
	'pant fit' : [
		'skinny',
		'straight',
		'bootcut',
		'crop',
		'wide leg',
		'flare',
		'tapered',
		'bell bottom',
		'palazzo',
	],
	'print name' : [
		'plaid',
		'paisley',
		'floral',
		'argyle',
		'striped',
		'tie dye',
		'leopard',
		'brocade',
		'chevron',
		'checkered',
		'abstract',
		'polka dot',
		'geometric',
		'cheetah',
		'zebra',
		'giraffe',
		'snakeskin',
		'animal print',
	],
	'print/trim type' : [
		'graphic',
		'embroidery',
		'sequin',
		'eyelet',
		'lace',
		'beaded',
		'jeweled',
		'feather',
		'studded',
		'patchwork',
		'quilted',
		'appliqué',
	],
	'fabric type' : [
		'jacquard',
		'corduroy',
		'velvet',
		'flannel',
		'tweed',
		'lurex',
		'crochet',
		'knit',
		'seersucker',
		'houndstooth',
		'herringbone',
		'gingham',
		'tulle',
		'ribbed',
		'pointelle',
		'denim',
	],
	'details' : [
		'puff sleeve',
		'milk maid',
		'bow',
		'ruffle',
		'pleated',
		'buttoned',
		'tie',
		'shoulder pads',
		'elastic waist',
		'elastic cuff',
		'elastic hem',
		'fringe',
		'adjustable straps',
		'peplum',
		'belted',
		'ripped',
		'distressed',
		'ruched',
		'pocket',
		'zip up',
		'backless',
		'lace up',
		'sheer',
		'cuffed',
	],
	'season' : [
		'Spring',
		'Fall',
		'Summer',
		'Winter',
	],
	'collection' : [
		'western',
		'colorblock',
		'pastel',
		'nautical',
		'spring florals',
		'prom',
		'caftan',
		'animal print',
		'men',
		'unisex',
		'antique',
		'plus size',
		'Banana Republic',
		'Mona May',
		'holiday gift guide',
	],
	'occasion' : [
		'festival',
		'work',
		'party',
		'holiday',
		'casual',
		'formal',
		'lingerie',
		'bridal',
	],
	'meta' : [
		'designer',
		'basic product',
		'detailed product',
		'imported product',
		'etsy import',
		'wholesale product',
	],
	'shoe type' : [
		'clog',
		'combat',
		'kitten heel',
		'lace up',
		'peep toe',
		'platform',
		'pump',
		'slingback',
		'stiletto',
		'strappy',
		't-strap',
		'wedge',
	],
	'bag type' : [
		'baguette',
		'duffle',
		'fanny pack',
		'hobo',
		'saddle',
		'wristlet',
	],
	'jewelry type' : [
		'bangle',
		'beaded',
		'bolo',
		'cameo',
		'chain',
		'charm',
		'choker',
		'clip on',
		'collar',
		'cuff',
		'dangle',
		'gemstone',
		'gold',
		'hoop',
		'pearl',
		'pendant',
		'rhinestone',
		'silver',
		'stud',
		'turquoise',
		'rose gold',
	],
	'collar type' : [
		'notch collar',
		'collared',
		'shawl collar',
		'mandarin collar',
		'peak lapel',
	],
};

export function GetTagGroup(tagCategory : string) : string[] {
	return tagsDictionary[tagCategory] ?? [];
}

let cachedTagMap : TagMap;

export function GetTagMap() : TagMap {
	if (cachedTagMap) {
		return Helper.deepCopy(cachedTagMap);
	}

	const tagMap : TagMap = {};

	for (const category in tagsDictionary) {
		tagMap[category] = {
			value : category,
			tags  : {},
		};

		for (const tag of tagsDictionary[category]) {
			tagMap[category].tags[tag] = {
				value           : tag,
				structuredValue : `${ category }:${ tag }`,
			};
		}
	}

	cachedTagMap = tagMap;

	return GetTagMap();
}

const productCategoryMap : { [ key : string ] : SpecificTagCategory[] } = {
	'apron'             : [ 'fabric type' ],
	'backpack'          : [ 'fabric type', 'bag type' ],
	'belt'              : [ 'fabric type' ],
	'boots'             : [ 'fabric type', 'shoe type' ],
	'bracelet'          : [ 'fabric type', 'jewelry type' ],
	'briefcase'         : [ 'fabric type', 'bag type' ],
	'bucket bag'        : [ 'fabric type', 'bag type' ],
	'clutch'            : [ 'fabric type', 'bag type' ],
	'crossbody bag'     : [ 'fabric type', 'bag type' ],
	'cuff links'        : [ 'jewelry type' ],
	'dress'             : [ 'fabric type', 'dress type', 'dress/skirt length', 'neckline', 'sleeve length' ],
	'earrings'          : [ 'fabric type', 'jewelry type' ],
	'flats'             : [ 'fabric type', 'shoe type' ],
	'glasses'           : [ 'jewelry type' ],
	'gloves'            : [ 'fabric type' ],
	'hair clip'         : [ 'fabric type', 'jewelry type' ],
	'hat'               : [ 'fabric type' ],
	'headband'          : [ 'fabric type', 'jewelry type' ],
	'heels'             : [ 'fabric type', 'shoe type' ],
	'intimates'         : [ 'fabric type'],
	'jewelry set'       : [ 'jewelry type' ],
	'jumpsuit & romper' : [ 'collar type', 'fabric type', 'tshirt type', 'sweater/sweatshirt type', 'neckline', 'pant fit', 'pant rise', 'pant type', 'sleeve length' ],
	'kimono'            : [ 'fabric type', 'sleeve length' ],
	'luggage & travel'  : [ 'bag type' ],
	'mask'              : [ 'fabric type' ],
	'messenger bag'     : [ 'fabric type', 'bag type' ],
	'mini purse'        : [ 'fabric type', 'bag type' ],
	'mules'             : [ 'fabric type', 'shoe type' ],
	'necklace'          : [ 'fabric type', 'jewelry type' ],
	'outerwear'         : [ 'jacket type', 'fabric type' ],
	'pants & jeans'     : [ 'fabric type', 'pant fit', 'pant rise', 'pant type' ],
	'pin'               : [ 'fabric type', 'jewelry type' ],
	'ring'              : [ 'fabric type', 'jewelry type' ],
	'sandals'           : [ 'fabric type', 'shoe type' ],
	'scarf'             : [ 'fabric type' ],
	'shawl'             : [ 'fabric type' ],
	'shorts'            : [ 'fabric type', 'pant fit', 'pant rise', 'pant type' ],
	'shoulder bag'      : [ 'fabric type', 'bag type' ],
	'skirt'             : [ 'fabric type', 'dress type', 'dress/skirt length' ],
	'sneakers'          : [ 'fabric type', 'shoe type' ],
	'socks'             : [ 'fabric type' ],
	'suiting'           : [ 'fabric type' ],
	'sunglasses'        : [ 'jewelry type' ],
	'sweater'           : [ 'collar type', 'fabric type', 'sweater/sweatshirt type', 'neckline', 'sleeve length' ],
	'sweatshirt'        : [ 'fabric type', 'sweater/sweatshirt type', 'neckline', 'sleeve length' ],
	'swimwear'          : [ 'fabric type', 'neckline', 'sleeve length' ],
	't-shirt'           : [ 'collar type', 'fabric type', 'tshirt type', 'neckline', 'sleeve length' ],
	'tie clip'          : [ 'fabric type' ],
	'ties'              : [ 'fabric type' ],
	'top'               : [ 'sleeve length', 'neckline', 'fabric type', 'collar type' ],
	'tote'              : [ 'fabric type', 'bag type' ],
	'wallet'            : [ 'fabric type' ],
	'watch'             : [ 'fabric type', 'jewelry type' ],
};

const getTagCategoriesByProductCategory = (productCategory : string) : SpecificTagCategory[] =>
	productCategoryMap[productCategory] ?? [];

export function GetTagCategories(itemCategory : string) : TagCategories {
	const specific : SpecificTagCategory[] = getTagCategoriesByProductCategory(itemCategory).sort();
	const general  : GeneralTagCategory[]  = [
		'collection',
		'details',
		'meta',
		'occasion',
		'print name',
		'print/trim type',
		'season',
	];

	return {
		general,
		specific,
	};
}
