import { NgModule } from '@angular/core';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import { environment, shopifyProxy } from '../../../environments/environment';

import { ShopifyAdminService } from './shopify-admin.service';


@NgModule({
	imports : [
		ApolloModule,
		HttpClientModule,
	],
	providers : [
		ShopifyAdminService,
		{
			provide    : APOLLO_OPTIONS,
			useFactory : (httpLink : HttpLink) => {
				const headers : HttpHeaders = new HttpHeaders()
					.set(shopifyProxy.proxyRequestHeader, 'true');

				if (!environment.production) {
					headers.set('X-GraphQL-Cost-Include-Fields', 'true');
				}

				const middleware = new ApolloLink((operation, forward) => {
					operation.setContext({ headers });

					return forward(operation);
				});
				const http = httpLink.create({
					uri : `https://${ shopifyProxy.proxyDomain }/${ shopifyProxy.baseUri }/${ shopifyProxy.apiVersion }/${ shopifyProxy.graphQLendpoint }`,
				});

				return {
					link  : middleware.concat(http),
					cache : new InMemoryCache(),
				};
			},
			deps : [ HttpLink ],
		},
	],
})
export class ShopifyAdminModule {}
