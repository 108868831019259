export enum ThemeColors {
		SUCCESS='success',
		WARNING='warning',
		DANGER='danger',
		THRILLING_BLUE="thrilling-blue",
		THRILLING_ORANGE="thrilling-orange",
		THRILLING_PINK="thrilling-pink",
		THRILLING_LIME="thrilling-lime",
		SYSTEM_RED="system-red",
		SYSTEM_BLUE="system-blue",
		SYSTEM_PURPLE="system-purple",
		BLACK="black",
		WHITE="white",
		GRAY_DARKER="gray-darker",
		GRAY_DARK="gray-dark",
		GRAY_MEDIUM="gray-medium",
		GRAY_LIGHT="gray-light",
		GRAY_LIGHTER="gray-lighter",
		GRAY_LIGHTEST="gray-lightest",
}
