import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
	name : 'default',
})
export class DefaultPipe implements PipeTransform {
	transform(value : string, defaultVal : string) : string {
		return value != null ? value : defaultVal;
	}
}
