import { Component } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { ImageInputComponent } from '../image-input.component';


@Component({
	selector    : 'app-edit-menu-popover',
	templateUrl : './edit-menu-popover.component.html',
	styleUrls   : ['./edit-menu-popover.component.scss'],
})
export class EditMenuPopoverComponent {
	parent : ImageInputComponent;

	constructor(
		private navParams : NavParams
	) {
		this.parent = navParams.get('parent');
	}

	replace() : void {
		this.parent.popover.dismiss();

		this.parent.replaceImage();
	}

	delete() : void {
		this.parent.popover.dismiss();

		this.parent.deleteImage();
	}
}
