import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { take, tap, map, switchMap } from 'rxjs/operators';
import { Product, ProductImage, Query } from '@shopthrilling/thrilling-shared';

import { HelperService as Helper } from '../../services/helper/helper.service';
import { LogService } from '../../services/log/log.service';
import { ProductImagesService } from '../../services/product-images/product-images.service';
import { EditedImage } from '../../types/edited-images';


type IndeterminateStatus =
	| 'pending'
	| 'all'
	| 'completed'
	;

type Event =
	| 'created'
	| 'updated'
	| 'reordered'
	| 'deleted'
	;


@Injectable({
	providedIn : 'root',
})
export class EditedImagesService {
	loading         : boolean = false;
	selectedStatus$ : BehaviorSubject<string> = new BehaviorSubject<IndeterminateStatus>('pending');
	query$          : BehaviorSubject<string> = new BehaviorSubject<string>('');
	page$           : BehaviorSubject<number> = new BehaviorSubject<number>(1);


	constructor(
		private log           : LogService,
		private productImages : ProductImagesService,
	) {}


	getCountByStatus(status : IndeterminateStatus) : Observable<number> {
		return this.getByStatus(status).pipe(
			// tap(data => console.debug(data)),
			map(images => images && images.length ? images.length : null),
			// tap(data => console.debug(data)),
		);
	}

	getByStatus(status : IndeterminateStatus) : Observable<EditedImage[]> {
		return this.productImages.getProductsByEditRequested(status === 'pending').pipe(
			map((list : Product[]) => this.parseEditedImagesByStatus(list, status)),
		);
	}

	private parseEditedImagesByStatus(list : Product[], status : IndeterminateStatus) : EditedImage[] {
		const editedImages = [],
			conformsToStatus = (image) => {
				const statusMap = {
					all       : true,
					pending   : !image.editCompletedAt,
					completed : !!image.editCompletedAt,
				};

				return statusMap[status];
			};

		for (const product of list) {
			product.images
				.filter(image =>
					!!image.editRequestedAt
					&& !!image.graphqlAdminId
					&& !image.detachedFromShopify
					&& conformsToStatus(image)
				)
				.forEach((image, index, array) => {
					const total : number = array.length,
						editedImage = this.parseEditedImage(product, image);

					if (total > 1)
						editedImage['disambiguation'] = `${ index + 1 } of ${ total }`;

					editedImages.push(editedImage);
				});
		}

		return editedImages;
	}

	private parseEditedImage(product : Product, image : ProductImage) : EditedImage {
		return {
			productId : product.graphqlAdminId,
			handle    : product.handle,
			title     : product.title,
			sku       : product.sku,
			store     : product.vendor,
			image,
		};
	}

	getByStatusAndQuery(limit : number = 50) : Observable<any> {
		this.toggleLoading(true);

		return this.selectedStatus$.pipe(
			// tap(data => console.debug(data)),
			switchMap(this.getByStatus.bind(this)),
			// tap(data => console.debug(data)),
			switchMap((list : EditedImage[]) => this.query$.pipe(
				tap(() => this.toggleLoading(true)),
				map(query => Query.matchObject(
					list,
					query,
					[
						'id',
						'title',
						'sku',
						'store',
						item => Helper.parseGraphQlNumericalId(item.productId),
					],
				)),
			)),
			switchMap((list : EditedImage[]) =>
				this.page$.pipe(
					map((page : number) => {
						const getTime = (editedimage : EditedImage) => new Date(editedimage.image.updatedAt ?? editedimage.image.createdAt).getTime(),
							total   : number = list.length,
							startAt : number = limit * (page - 1),
							endAt   : number = limit * page,
							data    : any[] = list
								.sort((a, b) => getTime(a) - getTime(b))
								.slice(startAt, endAt);

						return {
							data,
							page,
							total,
							count      : data.length,
							totalPages : Math.ceil(total / limit),
						};
					}),
				)
			),
			tap(() => this.toggleLoading(false)),
			// tap(data => console.debug(data)),
		);
	}

	getCurrentImage(editedImage : EditedImage) : string {
		const src : string = editedImage.image.currentSource && editedImage.image[`${ editedImage.image.currentSource }Src`];

		return src || editedImage.image.src;
	}

	getByIds(productId : string, imageId : string) : Observable<EditedImage> {
		if (!Helper.regex.path.test(productId))
			productId = `gid://shopify/Product/${ productId }`;

		if (!Helper.regex.path.test(imageId))
			imageId = `gid://shopify/ProductImage/${ imageId }`;

		return this.productImages.getProductByShopifyId(productId).pipe(
			// tap(data => console.debug(data)),
			map(product => {
				let image : ProductImage;

				if (product && product.images && Array.isArray(product.images)) {
					image = product.images.find(image => image.graphqlAdminId === imageId);

					return this.parseEditedImage(product, image);
				} else
					return null;
			}),
			// tap(data => console.debug(data)),
		);
	}

	private toggleLoading(forceState? : boolean) : void {
		this.loading = forceState == null ? !this.loading : forceState;
	}

	toggleEditStarted(editedImage : EditedImage) : Observable<any> {
		const timestamp : string = new Date().toISOString();

		editedImage.image = {
			...editedImage.image,
			editStartedAt : editedImage.image.editStartedAt ? null : timestamp,
			updatedAt     : timestamp,
		};

		return this.updateImage(editedImage).pipe(
			take(1),
		);
	}

	private updateImage(editedImage : EditedImage) : Observable<any> {
		return this.getUpdatedImages(editedImage).pipe(
			take(1),
			switchMap(images => this.productImages.upsertProduct({
				images,
				graphqlAdminId : editedImage.productId,
				handle         : editedImage.handle,
				sku            : editedImage.sku,
				title          : editedImage.title,
			})),
			take(1),
		);
	}

	private getUpdatedImages(editedImage : EditedImage) : Observable<ProductImage[]> {
		return this.productImages.getByShopifyId(editedImage.productId).pipe(
			take(1),
			map(images =>
				images.map(image =>
					image.graphqlAdminId === editedImage.image.graphqlAdminId ? editedImage.image : image
				)
			),
		);
	}

	save(editedImage : EditedImage, manuallyEditedSrc : string) : Observable<any> {
		const timestamp : string = new Date().toISOString();

		editedImage.image = {
			...editedImage.image,
			manuallyEditedSrc,
			src             : manuallyEditedSrc,
			currentSource   : 'manuallyEdited',
			editCompletedAt : timestamp,
			updatedAt       : timestamp,
		};

		return this.updateProductAndImage(editedImage).pipe(
			take(1),
		);
	}

	private updateProductAndImage(editedImage : EditedImage) : Observable<any> {
		return this.getUpdatedImages(editedImage).pipe(
			take(1),
			switchMap(images => this.productImages.upsertProduct({
				images,
				graphqlAdminId : editedImage.productId,
				handle         : editedImage.handle,
				sku            : editedImage.sku,
				title          : editedImage.title,
			})),
			take(1),
			map(images => this.removeId(images, editedImage)),
			switchMap(images => this.productImages.updateInShopifyAndFirestore(
				editedImage.productId,
				editedImage.sku,
				images,
			)),
			take(1),
		);
	}

	private removeId(images : ProductImage[], editedImage : EditedImage) : ProductImage[] {
		return images.map(image => {
			if (image.graphqlAdminId === editedImage.image.graphqlAdminId)
				image.graphqlAdminId = null;

			return image;
		});
	}

	getCroppedImageSrcFromDataUrl(dataUrl : string, currentSrc : string = 'edited image') : Promise<string> {
		return this.productImages.getCroppedImageSrcFromDataUrl(dataUrl, currentSrc);
	}
}
