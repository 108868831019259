import { Component, Input, Output, EventEmitter } from '@angular/core';


@Component({
	selector    : 'app-list-header',
	templateUrl : './list-header.component.html',
	styleUrls   : ['./list-header.component.scss'],
})
export class ListHeaderComponent {
  @Input() headerText         : string;
  @Input() buttonText         : string;
  @Input() buttonSize         : 'small' | 'default' | 'large' | undefined = 'small';
  @Input() buttonFill         : 'clear' | 'default' | 'outline' | 'solid' | undefined;
  @Input() buttonColor        : string;
  @Input() buttonIcon         : string;
  @Input() buttonIconPosition : 'start' | 'icon-only' | 'end' = 'end';
  @Input() showButton         : boolean = false;
  @Input() suppressTopMargin  : boolean = true;

  @Output() buttonClick = new EventEmitter<void>();

  constructor() {}
}
