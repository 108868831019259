import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { sortBy } from 'lodash';
import { Collection } from '@shopthrilling/thrilling-shared';

import { FirestoreService } from '../../services/firestore/firestore.service';
import { ShopifyAdminService } from '../../modules/shopify-admin/shopify-admin.service';


@Injectable({
	providedIn : 'root',
})
export class CollectionService {
	private collectionsRef : AngularFirestoreCollection<Collection>;


	constructor(
		private firestore      : FirestoreService,
		private shopifyGraphql : ShopifyAdminService,
	) {
		this.collectionsRef = this.firestore.getRef('collections');
	}


	/*
	 * Read Methods - new
	 * pull from Firestore
	 */
	getAll() : Observable<Collection[]> {
		return this.collectionsRef.valueChanges().pipe(
			// tap(data => console.debug(data)),
			map(list => sortBy(list, ['title'])),
			// tap(data => console.debug(data)),
		);
	}


	/*
	 * Read Methods - old
	 * pull from Shopify
	 */


	/*
	 * Write Methods
	 * push to Firestore
	 */
	delete(id : string) : Promise<void> {
		return this.collectionsRef.doc(id).delete();
	}


	/*
	 * Write Methods
	 * push to Shopify
	 */
	addProductsToCollection(id : string, productIds : string[]) : Observable<any> {
		return this.shopifyGraphql.addProductsToCollection(id, productIds);
	}
}
