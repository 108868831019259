import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, filter, tap } from 'rxjs/operators';
import { AngularFireRemoteConfig, Parameter } from '@angular/fire/compat/remote-config';

import { environment } from '../../../environments/environment';
import { RemoteConfigDefaultValues } from '../../constants/remote-config-default-values';


type Config = {
	key   : string;
	value : string | boolean | number;
};


@Injectable({
	providedIn : 'root',
})
export class RemoteConfigService {
	private consoleTitle : string[] = [
		'%cRemoteConfigService',
		'color: #1437cf; font-family:monospace; font-size: 14px',
	];


	constructor(
		private remoteConfig : AngularFireRemoteConfig,
	) {}


	getAll() : Observable<Config> {
		return this.remoteConfig.changes.pipe(
			tap((param : Parameter) => {
				if (this.canSquawk())
					console.info(...this.consoleTitle, param);
			}),
			map((param : Parameter) => ({
				key   : param.key,
				value : this.coercedValue(param),
			})),
		);
	}

	private coercedValue(param : Parameter) : string | number | boolean {
		const defaultValue = RemoteConfigDefaultValues[param.key],
			defaultType = typeof defaultValue;

		if (defaultType === 'boolean')
			return param.asBoolean();
		else if (defaultType === 'number')
			return param.asNumber();
		else
			return param.asString();
	}

	private canSquawk() : boolean {
		return !environment.production || /(?:print_logs|squawk)=true/.test(window.location.search);
	}

	getByKey(key : string) : Observable<string | number | boolean> {
		return RemoteConfigDefaultValues[key] == null
			? of(null)
			: this.getAll().pipe(
				filter(config => config.key === key),
				map(config => config.value),
			);
	}

	getByKeyPromise(key : string) : Promise<string | number | boolean> {
		return this.getByKey(key).toPromise();
	}
}
