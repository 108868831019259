import { Component } from '@angular/core';

import { CurrentUserService } from '../../services/current-user/current-user.service';


@Component({
	selector    : 'app-logout',
	templateUrl : './logout.page.html',
	styleUrls   : ['./logout.page.scss'],
})
export class LogoutPage {
	constructor(
		private currentUser : CurrentUserService,
	) {
		currentUser.logout();
	}
}
