import { Injectable } from '@angular/core';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router,
	UrlTree,
} from '@angular/router';



@Injectable({
	providedIn : 'root',
})
export class EmailStateGuard implements CanActivate {
	constructor(
		private router : Router,
	) {}


	canActivate(
		next  : ActivatedRouteSnapshot,
		state : RouterStateSnapshot
	) : boolean | UrlTree {
		return this.router.getCurrentNavigation()?.extras?.state?.email
			? true
			: this.accessDenied();
	}

	private accessDenied() : UrlTree {
		console.info('access denied');

		return this.router.parseUrl('/');
	}
}
