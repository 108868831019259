import { Component, Input } from '@angular/core';


@Component({
	selector    : 'app-copy-to-clipboard',
	templateUrl : './copy-to-clipboard.component.html',
	styleUrls   : ['./copy-to-clipboard.component.scss'],
})
export class CopyToClipboardComponent {
	@Input() text     : string;


	constructor() {}


	copyToClipboard(text : string) : void {
		navigator.clipboard.writeText(text);
	}
}
