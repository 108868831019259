import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, of, from } from 'rxjs';
import { tap, take, map, switchMap, filter } from 'rxjs/operators';
import { OAuthProvider } from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Query } from '@shopthrilling/thrilling-shared';

import { HelperService as Helper } from '../../services/helper/helper.service';
import { LogService } from '../../services/log/log.service';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { CurrentUserService } from '../../services/current-user/current-user.service';
import { User, UserStatus, Provider } from '../../types/user';
import { FirestoreQuery } from '../../types/firestore';
import { EventIssuer } from '../../constants/EventIssuer';
import { EventContext } from '../../constants/EventContext';


interface LoginPayload {
	oAuthResponse : any;
	userStatus    : UserStatus;
}

type Segment =
	| 'all'
	| 'active'
	| 'prospective'
	;


@Injectable({
	providedIn : 'root',
})
export class UserService {
	private usersRef    : AngularFirestoreCollection<User>;
	private statusesRef : AngularFirestoreCollection<UserStatus>;
	private deletedRef  : AngularFirestoreCollection<User>;

	selectedSegment$ : BehaviorSubject<string> = new BehaviorSubject<Segment>('all');
	query$           : BehaviorSubject<string> = new BehaviorSubject<string>('');


	constructor(
		private router      : Router,
		private afAuth      : AngularFireAuth,
		private firestore   : FirestoreService,
		private log         : LogService,
		private currentUser : CurrentUserService,
	) {
		this.initRefs();
		this.initOAuthRedirectResult();
	}


	private initRefs() : void {
		this.usersRef    = this.firestore.getRef('users');
		this.statusesRef = this.firestore.getRef('userStatuses');
		this.deletedRef  = this.firestore.getRef('deletedUsers');
	}

	private initOAuthRedirectResult() : void {
		from(this.afAuth.getRedirectResult()).pipe(
			filter(response => !!response.user),
			switchMap((response : any) =>
				this.getStatusByEmail(response.additionalUserInfo.profile.email).pipe(
					take(1),
					map(userStatus => ({
						userStatus,
						oAuthResponse : response,
					})),
				)
			),
			filter((payload : LoginPayload) => {
				if (payload.userStatus)
					return true;
				else {
					this.router.navigate(['signin'], {
						queryParams : {
							invaliduser : true,
						},
					});

					this.currentUser.logout(false);

					return false;
				}
			}),
		)
			.subscribe(this.handleLoginSuccess.bind(this));
	}

	private getSegmentedUsers(storeUsersOnly : boolean = false) : Observable<User[]> {
		return this.selectedSegment$.pipe(
			switchMap(this[ storeUsersOnly ? 'storeUsersSegmentStream' : 'segmentStream' ].bind(this)),
			map(this.filterIncompleteUsers),
			map(this.sortUsers)
		);
	}

	private segmentStream(segment : Segment) : Observable<User[]> {
		let users$ : Observable<User[]>;

		if (segment === 'all')
			users$ = this.getAll();
		else {
			users$ = segment === 'active'
				? this.getActive()
				: this.getProspective();
		}

		return users$.pipe(
			map((list : User[]) => list),
		);
	}

	private storeUsersSegmentStream(segment : Segment) : Observable<User[]> {
		const queries : FirestoreQuery[] = [
			{ orderBy : [ 'store' ] },
			{ startAfter : null },
		];

		return this.queryFirestore(queries).pipe(
			map((list : User[]) => segment === 'all'
				? list
				: list
					.filter(user =>
						Boolean(user.uid) === (segment === 'active')
					)
			),
		);
	}

	private queryFirestore(queries : FirestoreQuery | FirestoreQuery[]) : Observable<User[]> {
		return this.firestore.query('users', queries);
	}

	private filterIncompleteUsers(arr : User[]) : User[] {
		return arr.filter(user => user.displayName || user.email);
	}

	private sortUsers(users : User[]) : User[] {
		return users.sort((a, b) => {
			const A = (a.displayName || a.email).toLowerCase(),
				B = (b.displayName || b.email).toLowerCase();

			return A < B ? -1 : A > B ? 1 : 0;
		});
	}

	getAll() : Observable<User[]> {
		return this.usersRef.valueChanges();
	}

	getActive() : Observable<User[]> {
		const queries : FirestoreQuery[] = [
			{ orderBy : [ 'uid' ] },
			{ startAfter : null },
		];

		return this.queryFirestore(queries);
	}

	getProspective() : Observable<User[]> {
		const queries : FirestoreQuery[] = [
			{ orderBy : [ 'uid' ] },
			{ endAt : null },
		];

		return this.queryFirestore(queries);
	}

	getQueried(limit : number = 50, storeUsersOnly : boolean = false) : Observable<any> {
		return this.getSegmentedUsers(storeUsersOnly).pipe(
			switchMap(list => this.query$.pipe(
				map(query => Query.matchObject(
					list,
					query,
					[
						'email',
						'displayName',
						'id',
					],
				)),
			)),
			map((list : any[]) => {
				const data : any[] = list.slice(0, limit);

				return {
					data,
					total : list.length,
					count : data.length,
				};
			}),
			// tap(data => console.debug(data)),
		);
	}

	getByID(id : string) : Observable<User> {
		return this.usersRef.doc<User>(id).valueChanges();
	}

	getEmailByID(id : string) : Observable<string> {
		return this.getByID(id).pipe(
			take(1),
			map(user => user.email),
		);
	}

	getByEmail(email : string) : Observable<User> {
		return this.queryFirestore({ where : { email } }).pipe(
			map(Helper.first),
		);
	}

	getByProvider(provider : Provider) : Observable<User[]> {
		return this.queryFirestore({ where : { provider } });
	}

	private async activate(user, provider : Provider) : Promise<void> {
		const updates = {
			uid         : user.uid,
			provider,
			displayName : user.displayName || user.email,
			image       : user.photoURL,
		};

		return this.updateByEmail(user.email, updates);
	}

	private async updateUserData(user, provider : Provider) : Promise<void> {
		const updates = {
			provider,
			image : user.photoURL,
		};

		return this.updateByEmail(user.email, updates);
	}

	async updateByEmail(email: string, updates) : Promise<void> {
		const user = await this.getByEmail(email).pipe(
			take(1),
		).toPromise();

		return this.update(user.id, updates);
	}

	async update(id : string, updates) : Promise<void> {
		updates.updatedAt = new Date().toISOString();
		updates.updatedBy = await this.currentUser.id();

		return this.usersRef.doc<User>(id).update(updates);
	}

	async create(email : string) : Promise<void> {
		const id : string = this.firestore.createId(),
			timestamp : string = new Date().toISOString(),
			currentUserID : string = await this.currentUser.id(),
			newUser : User = {
				id,
				email,
				uid       : null,
				createdAt : timestamp,
				createdBy : currentUserID,
				updatedAt : timestamp,
				updatedBy : currentUserID,
			};

		await this.createStatus(email);

		return this.usersRef.doc(id).set(Helper.deepCopy(newUser));
	}

	deleteByEmail(email : string) : Observable<boolean> {
		return this.saveDeleted(email).pipe(
			switchMap(user => user ? this.delete(user) : of(false)),
		);
	}

	private saveDeleted(email : string) : Observable<User> {
		let user : User = null;

		return this.getByEmail(email).pipe(
			take(1),
			tap((returnedUser : User) => {
				if (returnedUser?.id)
					user = returnedUser;
			}),
			switchMap((returnedUser : User) => {
				return returnedUser.uid
					? from(this.doSaveDeleted(user))
					: of(null);
			}),
			map(() => user),
		);
	}

	private async doSaveDeleted(user : User) : Promise<void> {
		const deletedUser : User = user,
			id : string = this.firestore.createId(),
			timestamp : string = new Date().toISOString(),
			currentUserID : string = await this.currentUser.id();

		deletedUser.id = id;
		deletedUser.deletedAt = timestamp;
		deletedUser.updatedAt = timestamp;
		deletedUser.deletedBy = currentUserID;
		deletedUser.updatedBy = currentUserID;

		// coerce data to JSON and back to remove undefined values and custom class objects, which Firestore will choke on
		return this.deletedRef.doc(id).set(Helper.deepCopy(deletedUser));
	}

	private delete(user : User) : Observable<boolean> {
		return this.deleteStatusByEmail(user.email).pipe(
			switchMap((statusDeleted : boolean) => statusDeleted ? from(this.doDelete(user.id)) : of(false)),
		);
	}

	private doDelete(userID : string) : Promise<boolean> {
		return this.usersRef.doc(userID).delete()
			.then(
				() => Promise.resolve(true),
				() => Promise.resolve(false)
			);
	}


	/*
		UserStatus methods
	*/
	getStatusByEmail(email : string) : Observable<UserStatus> {
		return this.firestore.query('userStatuses', { where : { email } }).pipe(
			map(Helper.first),
		);
	}

	async createStatus(email : string, provider? : Provider) : Promise<void> {
		const id : string = this.firestore.createId(),
			timestamp : string = new Date().toISOString(),
			currentUserID : string = await this.currentUser.id(),
			newStatus : UserStatus = {
				id,
				email,
				createdAt : timestamp,
				createdBy : currentUserID,
				updatedAt : timestamp,
				updatedBy : currentUserID,
			};

		if (provider != null)
			newStatus.provider = provider;

		// coerce data to JSON and back to remove undefined values and custom class objects, which Firestore will choke on
		return this.statusesRef.doc(id).set(Helper.deepCopy(newStatus));
	}

	async updateStatusByEmail(email : string, updates : any) : Promise<boolean> {
		const id : string = await this.getStatusByEmail(email).pipe(
			take(1),
			map(userStatus => userStatus.id),
		)
			.toPromise();

		return this.updateStatusById(id, updates);
	}

	private async updateStatusById(id : string, updates : any) : Promise<boolean> {
		updates.updatedAt = new Date().toISOString();
		updates.updatedBy = await this.currentUser.id();

		try {
			await this.statusesRef.doc(id).update(updates);
		} catch (error) {
			return false;
		}

		return true;
	}

	private deleteStatusByEmail(email : string) : Observable<boolean> {
		return this.getStatusByEmail(email).pipe(
			take(1),
			switchMap(userStatus => userStatus?.id
				? from(this.deleteStatusByID(userStatus.id))
				: of(false)
			),
		);
	}

	async deleteStatusByID(id : string) : Promise<boolean> {
		try {
			await this.statusesRef.doc(id).delete();
		} catch (error) {
			return false;
		}

		return true;
	}

	getAllStatuses() : Observable<UserStatus[]> {
		return this.statusesRef.valueChanges();
	}


	/*
		authentication methods
	*/
	emailPasswordSignIn(email : string, password : string, signIn : boolean = true) : Promise<any> {
		return this.afAuth[`${ signIn ? 'signIn' : 'createUser' }WithEmailAndPassword`](email, password)
			.then(
				this.handleEmailLoginSuccess.bind(this),
				this.handleEmailLoginError.bind(this)
			);
	}

	private async handleEmailLoginSuccess(response) : Promise<any> {
		const provider : Provider = Helper.get(response, ['additionalUserInfo', 'providerId']),
			userStatus : UserStatus = await this.getStatusByEmail(response.user.email).pipe(
				take(1),
			).toPromise();

		if (userStatus.provider)
			await this.updateUserData(response.user, provider);
		else
			await this.handleFirstSignIn(response.user, provider);

		return {
			status : 'success',
			data   : response,
		};
	}

	private async handleEmailLoginError(data) : Promise<any> {
		this.log.error(EventIssuer.UserService, {
			subject      : 'email/password sign in/up',
			eventContext : EventContext.UserLogin,
			details      : data,
		});

		return {
			status : 'error',
			data,
		};
	}

	providerSignIn(providerName : 'google' | 'apple') : void {
		const provider = new OAuthProvider(`${ providerName }.com`);

		provider.addScope('email');

		this.oAuthLogin(provider);
	}

	// this function cannot return anything
	// the redirect will immediately navigate away from the app
	private oAuthLogin(provider) : void {
		this.afAuth.signInWithRedirect(provider);
	}

	private async handleLoginSuccess(payload : LoginPayload) : Promise<any> {
		const provider : Provider = Helper.get(payload.oAuthResponse, ['additionalUserInfo', 'providerId']),
			user : any = { ...payload.oAuthResponse.user, ...payload.oAuthResponse.additionalUserInfo.profile };

		if (payload.userStatus.provider)
			await this.handleUpdateUser(user, provider);
		else
			await this.handleFirstSignIn(user, provider);

		this.router.navigate(['pages', 'home']);

		return {
			status : 'success',
			data   : payload.oAuthResponse,
		};
	}

	private async handleFirstSignIn(user, provider : Provider) : Promise<void> {
		// activate user
		await this.activate(user, provider);

		// update UserStatus with active provider
		await this.updateStatusByEmail(user.email, {
			provider,
		});

		return;
	}

	private async handleUpdateUser(user, provider : Provider) : Promise<void> {
		await this.updateUserData(user, provider);

		await this.updateStatusByEmail(user.email, {
			provider,
		});

		return;
	}

	sendPasswordResetEmail(email : string) : Promise<any> {
		return this.afAuth.sendPasswordResetEmail(email)
			.then(
				this.handleEmailSendSuccess.bind(this),
				this.handleEmailSendError.bind(this)
			);
	}

	private async handleEmailSendSuccess(data) : Promise<any> {
		return {
			status : 'success',
			data,
		};
	}

	private async handleEmailSendError(data) : Promise<any> {
		this.log.error(EventIssuer.UserService, {
			subject : 'unable to send password reset email',
			details : data,
		});

		return {
			status : 'error',
			data,
		};
	}
}
