import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { HelperService as Helper } from '../../services/helper/helper.service';
import { ImgixService } from '../../services/imgix/imgix.service';


@Component({
	selector    : 'app-expandable-card',
	templateUrl : './expandable-card.component.html',
	styleUrls   : ['./expandable-card.component.scss'],
})
export class ExpandableCardComponent implements OnInit {
	@Input() imageTop          : string | string[];
	@Input() fitTopImage       : boolean;
	@Input() title             : string;
	@Input() topSubtitle       : string;
	@Input() bottomSubtitle    : string;
	@Input() collapsible       : boolean;
	@Input() initiallyExpanded : boolean;
	@Input() hasBodyContent    : boolean;

	@Output() expand   = new EventEmitter<void>();
	@Output() collapse = new EventEmitter<void>();

	helper    = Helper;
	expanded  : boolean;
	slideOpts = {};
	imageOpts = {
		bg : 'faf5ee',
	};


	constructor(
		public imgix  : ImgixService,
	) {}


	ngOnInit() : void {
		if (this.collapsible == null)
			this.collapsible = true;

		if (this.hasBodyContent == null)
			this.hasBodyContent = true;

		if (this.collapsible === false)
			this.expanded = true;
		else
			this.expanded = !!this.initiallyExpanded;

		if (this.imageTop && Helper.isArray(this.imageTop) && this.imageTop.length > 1) {
			this.slideOpts = {
				loop     : true,
				autoplay : {
					delay : 4000,
				},
			};
		}

		if (this.fitTopImage === true) {
			this.imageOpts['fit'] = 'fill';
			this.imageOpts['pad'] = 20;
		}
	}

	headerClick() : void {
		this.expanded = !this.expanded;

		this[this.expanded ? 'expand' : 'collapse'].emit();
	}
}
