import { Component } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

import { ZoomImageComponent } from '../zoom-image.component';

import { ZoomImageMenuPopoverComponent } from './zoom-image-menu-popover/zoom-image-menu-popover.component';


@Component({
	selector    : 'app-zoom-image-modal',
	templateUrl : './zoom-image-modal.component.html',
	styleUrls   : ['./zoom-image-modal.component.scss'],
})
export class ZoomImageModalComponent {
	parent : ZoomImageComponent;


	constructor(
		private navParams : NavParams,
		public popover    : PopoverController,
	) {
		this.parent = navParams.get('parent');
	}


	getSrc() : string {
		return this.parent.imgix.image(this.parent.src, window.innerWidth, 'auto');
	}

	dismissModal() : void {
		this.parent.modal.dismiss();
	}

	async showMenuPopover(event : Event) : Promise<void> {
		event.stopPropagation();

		const popover = await this.popover.create({
			component      : ZoomImageMenuPopoverComponent,
			componentProps : {
				parent : this,
			},
			event,
		});

		await popover.present();
	}
}
