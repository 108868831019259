import { StoreFieldGrouping } from '../types/store-input-fields';


export function StoreFields() : StoreFieldGrouping {
	return {
		order : [
			'main',
			// 'published',
			'page',
			'social',
			// 'announcementBar',
		],
		groups : {
			main : {
				key   : 'main',
				title : 'Main Info',
				order : [
					'title',
					'handle',
					'type',
					'region',
					'verified',
					'giftWrap',
					'isWholesaleSupplier',
				],
				fields : {
					title : {
						key        : 'title',
						title      : 'Title',
						inputClass : 'default',
						inputType  : 'text',
						hidden 	   : true,
					},
					handle : {
						key        : 'handle',
						title      : 'Handle',
						inputClass : 'default',
						inputType  : 'text',
						readOnly   : true,
						hidden     : true,
					},
					type : {
						key          : 'type',
						title        : 'Store Type',
						inputClass   : 'meta',
						namespace    : 'meta',
						valueType    : 'STRING',
						inputType    : 'select',
						deletable    : false,
						hidden       : true,
						inputOptions : {
							options : [
								{
									value : 'physical store',
								},
								{
									value : 'individual seller',
								},
								{
									value : 'institutional partner',
								},
							],
							multiple : false,
						},
					},
					region : {
						key          : 'region',
						title        : 'Region',
						inputClass   : 'meta',
						namespace    : 'meta',
						valueType    : 'STRING',
						inputType    : 'select',
						deletable    : false,
						hidden       : true,
						inputOptions : {
							options : [
								{
									value : 'Los Angeles',
								},
								{
									value : 'New York',
								},
							],
							multiple : false,
						},
					},
					verified : {
						key        : 'verified',
						title      : 'Verified Store',
						inputClass : 'meta',
						namespace  : 'meta',
						valueType  : 'STRING',
						inputType  : 'boolean',
						hidden     : true,
					},
					giftWrap : {
						key        : 'giftWrap',
						title      : 'Provide Gift Wrap Service',
						inputClass : 'meta',
						namespace  : 'meta',
						valueType  : 'STRING',
						inputType  : 'boolean',
						hidden     : true,
					},
					isWholesaleSupplier : {
						key        : 'isWholesaleSupplier',
						title      : 'Wholesale Supplier',
						inputClass : 'meta',
						namespace  : 'meta',
						valueType  : 'STRING',
						inputType  : 'boolean',
						hidden     : true,
					},
				},
			},
			published : {
				key   : 'published',
				title : 'Published',
				order : [
					'published',
				],
				fields : {
					published : {
						key       : 'published',
						title     : 'Published',
						inputType : 'boolean',
					},
				},
			},
			page : {
				key   : 'page',
				title : 'Page Info',
				order : [
					'landscapeImageHeader',
					'primaryImage',
					'secondaryImage',
					'logoImage',
					'descriptionHtml',
					'location',
				],
				fields : {
					landscapeImageHeader : {
						key        : 'landscapeImageHeader',
						title      : 'Landscape Image Header',
						inputClass : 'meta',
						namespace  : 'meta',
						valueType  : 'STRING',
						inputType  : 'boolean',
						hidden     : true,
					},
					primaryImage : {
						key          : 'primaryImage',
						title        : 'Primary Image',
						inputClass   : 'meta',
						namespace    : 'meta',
						valueType    : 'STRING',
						inputType    : 'image',
						hidden       : true,
						deletable    : true,
						inputOptions : {
							multiple : false,
						},
					},
					secondaryImage : {
						key          : 'secondaryImage',
						title        : 'Secondary Image',
						inputClass   : 'meta',
						namespace    : 'meta',
						valueType    : 'STRING',
						inputType    : 'image',
						hidden       : true,
						deletable    : true,
						inputOptions : {
							multiple : false,
						},
					},
					logoImage : {
						key          : 'logoImage',
						title        : 'Logo Image',
						inputClass   : 'meta',
						namespace    : 'meta',
						valueType    : 'STRING',
						inputType    : 'image',
						hidden       : true,
						deletable    : true,
						inputOptions : {
							multiple : false,
						},
					},
					descriptionHtml : {
						key        : 'descriptionHtml',
						title      : 'Description',
						inputClass : 'default',
						inputType  : 'textarea',
					},
					location : {
						key        : 'location',
						title      : 'Location',
						inputClass : 'meta',
						namespace  : 'meta',
						valueType  : 'STRING',
						inputType  : 'text',
						deletable  : true,
					},
				},
			},
			social : {
				key   : 'social',
				title : 'Social Links',
				order : [
					'socialLinks',
				],
				fields : {
					socialLinks : {
						key          : 'socialLinks',
						title        : 'Social Links',
						inputClass   : 'meta',
						namespace    : 'meta',
						valueType    : 'JSON_STRING',
						inputType    : 'text',
						inputOptions : {
							options : [
								{
									value     : 'instagram',
									title     : 'Instagram Handle',
									inputType : 'text',
								},
								{
									value     : 'pinterest',
									title     : 'Pinterest Link',
									inputType : 'url',
								},
								{
									value     : 'twitter',
									title     : 'Twitter Handle',
									inputType : 'text',
								},
								{
									value     : 'facebook',
									title     : 'Facebook Link',
									inputType : 'url',
								},
								{
									value     : 'tumblr',
									title     : 'Tumblr Link',
									inputType : 'url',
								},
								{
									value     : 'snapchat',
									title     : 'Snapchat Handle',
									inputType : 'text',
								},
								{
									value     : 'youtube',
									title     : 'YouTube Link',
									inputType : 'url',
								},
								{
									value     : 'vimeo',
									title     : 'Vimeo Link',
									inputType : 'url',
								},
							],
							orderable : true,
						},
					},
				},
			},
			announcementBar : {
				key   : 'announcementBar',
				title : 'Announcement Bar',
				order : [
					'announcementBarText',
					'announcementBarBgColor',
				],
				fields : {
					announcementBarText : {
						key        : 'announcementBarText',
						title      : 'Text',
						inputClass : 'meta',
						namespace  : 'meta',
						valueType  : 'STRING',
						inputType  : 'text',
						deletable  : true,
					},
					announcementBarBgColor : {
						key          : 'announcementBarBgColor',
						title        : 'Background Color',
						inputClass   : 'meta',
						namespace    : 'meta',
						valueType    : 'STRING',
						inputType    : 'select',
						deletable    : true,
						inputOptions : {
							options : [
								{
									value : 'none',
									title : 'none',
								},
								{
									value : 'white',
									title : 'white',
								},
								{
									value : 'black',
									title : 'black',
								},
								{
									value : 'accent',
									title : 'accent',
								},
							],
							multiple : false,
						},
					},
				},
			},
		},
	};
}
