import { StringMap } from '../types/basics';
import { SentenceFragment } from '../types/sentence-fragment';
import { Autocorrect } from '../types/autocorrect';


export function TitleDefinition(grouping : string) : SentenceFragment[] {
	return [
		{
			key : 'era',
		},
		{
			key : 'color',
		},
		{
			key : 'material',
		},
		{
			key : grouping,
		},
	];
}

export function TitleAutocorrect() : Autocorrect[] {
	const preRendered : StringMap = {
		't[ -]?shirt'                 : 'T-Shirt',
		'v[ -]?neck'                  : 'V-Neck',
		'a[ -]?line'                  : 'A-Line',
		'multi[ -]?colou?r'           : 'Multicolor',
		'multipattern'                : 'Multi Pattern',
		'cut[ -]?out'                 : 'Cutout',
		'polka[ -]?dot'               : 'Polka Dot',
		// eslint-disable-next-line no-useless-escape
		'3\/4 sleeve'                 : 'Quarter Length Sleeve',
		'gr[ae]y'                     : 'Gray',
		// eslint-disable-next-line no-useless-escape
		'w(?:\/|ith?)'                : 'with',
		' [&+] '                      : ' and ',
		'(?:two|2)[ -]?piece'         : 'Two Piece',
		'(?:one|1)[ -]?piece'         : 'One Piece',
		'zip[ -]?up'                  : 'Zip Up',
		'half[ -]?zip'                : 'Half Zip',
		'off[ -]?white'               : 'Off White',
		'high[ -]low'                 : 'High Low',
		'off[ -](?:the[ -])?shoulder' : 'Off the Shoulder',
		'button[ -]?up'               : 'Button Up',
		'jewelled'                    : 'Jeweled',
		'criss[ -]?cross'             : 'Crisscross',
		'snake[ -]?skin'              : 'Snakeskin',
		'color[ -]?block'             : 'Colorblock',
		'zi-?g[ -]?zag'               : 'Zig Zag',
		'by'                          : 'by',
		'for'                         : 'for',
		'y2k'                         : '00s',
		"(18|19|20)?(\\d0)['’]?s"     : '$1$2s', // must be 1st date smoothing autocorrect
		'18(\\d0s)'                   : 'Victorian',
		'1900s'                       : 'Edwardian',
		'(?:19|20)(\\d0s)'            : '$1',
	};

	return Object.keys(preRendered)
		.map(key => ({
			find    : new RegExp(`\\b${ key }\\b`, 'gi'),
			replace : preRendered[key],
		}));
}
