import { Component } from '@angular/core';

import { version } from '../../../environments/version';


@Component({
	selector    : 'app-about',
	templateUrl : './about.page.html',
	styleUrls   : ['./about.page.scss'],
})
export class AboutPage {
	ver : string = version;

	constructor() {}
}
