export enum EventContext {
	CantGetProduct      = 'cant_get_product',
	CantGetRemoteConfig = 'cant_get_remote_config',
	CantGetShortCode    = 'cant_get_short_code',
	CantGetStore        = 'cant_get_store',
	NewFile             = 'new_file',
	NewProduct          = 'new_product',
	ProductDupe         = 'product_dupe',
	ProductImage        = 'product_image',
	ProductImageCrop    = 'product_image_crop',
	ProductImporter     = 'product_importer',
	PublishToggle       = 'publish_toggle',
	ShopifyThrottled    = 'shopify_throttled',
	SoldOut             = 'sold_out',
	StoreDupe           = 'store_dupe',
	UserLogin           = 'user_login',
	UserSignup          = 'user_signup',
}
