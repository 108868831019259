import { Component, OnInit, Input } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { tap, take } from 'rxjs/operators';

import { LogService } from '../../../services/log/log.service';
import { StoreService } from '../../../services/store/store.service';
import { ProductService } from '../../../services/product/product.service';
import { BooleanField } from '../../../types/input-fields';
import { EventIssuer } from '../../../constants/EventIssuer';
import { EventContext } from '../../../constants/EventContext';


@Component({
	selector    : 'app-publish-toggle',
	templateUrl : './publish-toggle.component.html',
	styleUrls   : ['./publish-toggle.component.scss'],
})
export class PublishToggleComponent implements OnInit {
	@Input() field      : BooleanField;
	@Input() parentType : 'product' | 'store';
	@Input() parentID   : string;

	private confirmPublish : boolean = true;
	private unit           : 'Item' | 'Store';


	constructor(
		private alert   : AlertController,
		private log     : LogService,
		private store   : StoreService,
		private product : ProductService,
	) {}


	ngOnInit() : void {
		this.unit = this.parentType === 'product' ? 'Item' : 'Store';
	}

	private async showAlert(header : string, message? : string, confirmCallback : Function = () => {}, cancelCallback : Function = () => {}) : Promise<void> {
		const alert = await this.alert.create({
			header,
			message,
			buttons : [
				{
					text     : 'Cancel',
					role     : 'cancel',
					cssClass : 'secondary',
					handler  : cancelCallback.bind(this),
				},
				{
					text    : 'Confirm',
					handler : confirmCallback.bind(this),
				},
			],
		});

		alert.present();
	}

	updatePublish(publish : boolean) : void {
		this.field.value = publish;

		if (this.confirmPublish) {
			this.showAlert(
				`Confirm ${ publish ? 'P' : 'Unp' }ublish ${ this.unit }`,
				`${ this.unit } will immediately be ${ publish ? 'published to' : 'removed from' } Shop Thrilling and other social channels.`,
				() => this.doUpdatePublish(publish),
				() => {
					this.confirmPublish = false;

					this.field = {
						...this.field,
						value : !publish,
					};
				}
			);
		} else
			this.confirmPublish = true;
	}

	private doUpdatePublish(publish : boolean) : void {
		this[this.parentType][`${ publish ? '' : 'un' }publish`](this.parentID).pipe(
			take(1),
			// tap(data => console.debug(data)),
			tap(data => this.log.update(EventIssuer.PublishToggleComponent, {
				subject      : `${ publish ? '' : 'un' }publish ${ this.parentType }`,
				eventContext : EventContext.PublishToggle,
				itemId       : this.parentID,
				details      : {
					data,
					field : this.field,
				},
			})),
		)
			.subscribe();
	}
}
