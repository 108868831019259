import { ProductMeasurementFieldKey, Department } from '@shopthrilling/thrilling-shared';

import { HelperService as Helper } from '../services/helper/helper.service';
import { StringMap } from '../types/basics';

import * as ExportedMeasurements from './exported-data/measurements_2022-08-02T11_38_23.json';
import { GetCategoriesByDepartment, GetCategoryBySubcategory } from './taxonomy.model';


function getMeasurementMap() : any {
	return (ExportedMeasurements as any)?.default;
}

export function SizeToMeasurement() : any {
	return {
		size  : ['XS', 'XS', 'XS', 'XS', 'S', 'M', 'M', 'L', 'L', 'XL', 'XL', '2XL', '2XL', '3XL', '3XL', '4XL'],
		bust  : [ 31,   32,   33,   34,  35,  36,  37,  38,  39.5, 41,  43.5,  46,   48.5,   51,   53.5,   56  ],
		waist : [ 23,   24,   25,   26,  27,  28,  29,  30,  31.5, 33,  35.5,  38,   40.5,   43,   45.5,   48  ],
		hip   : [ 33,   34,   35,   36,  37,  38,  39,  40,  41.5, 43,  45.5,  48,   50.5,   53,   55.5,   58  ],
	};
}

export function SizeToTag() : StringMap {
	return {
		'XS'  : 'XS (000 - 2)',
		'S'   : 'S (4)',
		'M'   : 'M (6 - 8)',
		'L'   : 'L (10 - 12)',
		'XL'  : 'XL (14 - 16)',
		'2XL' : '2XL (18 - 20)',
		'3XL' : '3XL (22 - 24)',
		'4XL' : '4XL+ (26+)',
	};
}

export function SizeTagToDisplaySize() : StringMap {
	return {
		'XS (000 - 2)'  : 'extra small',
		'S (4)'         : 'small',
		'M (6 - 8)'     : 'medium',
		'L (10 - 12)'   : 'large',
		'XL (14 - 16)'  : 'extra large',
		'2XL (18 - 20)' : '2XL',
		'3XL (22 - 24)' : '3XL',
		'4XL+ (26+)'    : '4XL+',
	};
}

export function GetMeasurements(value : string, type : 'category' | 'subcategory' = 'category') : ProductMeasurementFieldKey[] {
	const measurementMap = getMeasurementMap();

	let mapEntry = measurementMap[value];

	if (!mapEntry && type === 'subcategory') {
		const cat = GetCategoryBySubcategory(value);

		mapEntry = measurementMap[cat];
	}

	return mapEntry
		? mapEntry.required.concat(mapEntry.optional)
		: [];
}

export function GetOneSizeCategories() : string[] {
	const onseSizeDepts : Department[] = [
		'bags',
		'accessories',
		'tableware',
		'decor',
		'textiles',
	];

	const mappedCats = onseSizeDepts
		.map(dept => GetCategoriesByDepartment(dept));

	const combinedCats = [].concat(...mappedCats);

	return Helper.uniqueArray(combinedCats).sort();
}

const ClothingSizeList : Function = () : string[] =>
	Object.values(SizeToTag()).concat('OS');

const ShoesSizeList : Function = () : string[] => [
	'0',
	'1/2',
	'1',
	'1 1/2',
	'2',
	'2 1/2',
	'3',
	'3 1/2',
	'4',
	'4 1/2',
	'5',
	'5 1/2',
	'6',
	'6 1/2',
	'7',
	'7 1/2',
	'8',
	'8 1/2',
	'9',
	'9 1/2',
	'10',
	'10 1/2',
	'11',
	'11 1/2',
	'12',
	'12 1/2',
	'13',
	'13 1/2',
];

export const GetSizeList = (department? : Department) : string[] => {
	if (department === 'clothing')
		return ClothingSizeList();
	else if (department === 'shoes')
		return ShoesSizeList();
	else
		return ClothingSizeList().concat(ShoesSizeList());
};
