import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
	AngularFirestore,
	AngularFirestoreCollection,
	CollectionReference,
	QueryFn,
	Query,
} from '@angular/fire/compat/firestore';
import {
	Product,
	Collection,
	Store,
} from '@shopthrilling/thrilling-shared';

import { HelperService as Helper } from '../../services/helper/helper.service';
import {
	WhereFilterOp,
	FirestoreQuery,
	FirestoreQueryWhere,
	FirestoreQueryOrderBy,
	FirestoreCollection,
} from '../../types/firestore';
import { Log } from '../../types/log';
import { User, UserStatus } from '../../types/user';


type QueryMethods = {
	where      : (refOrQuery : RefOrQuery, queryWhere : FirestoreQueryWhere) => Query;
	orderBy    : (refOrQuery : RefOrQuery, queryOrderBy : FirestoreQueryOrderBy) => Query;
	limit      : (refOrQuery : RefOrQuery, limit : number) => Query;
	startAfter : (refOrQuery : RefOrQuery) => Query;
	endAt      : (refOrQuery : RefOrQuery) => Query;
};

type RefOrQuery =
	| CollectionReference
	| Query
	;


@Injectable({
	providedIn : 'root',
})
export class FirestoreService {
	private readonly queryMethods : QueryMethods = {
		orderBy    : (refOrQuery, queryOrderBy) => refOrQuery.orderBy(queryOrderBy[0], queryOrderBy[1] ?? 'asc'),
		limit      : (refOrQuery, limit) => refOrQuery.limit(limit),
		startAfter : (refOrQuery) => refOrQuery.startAfter(null),
		endAt      : (refOrQuery) => refOrQuery.endAt(null),
		where      : (refOrQuery, queryWhere) => {
			const filter : WhereFilterOp = queryWhere.FilterOp ?? '==';

			delete queryWhere.FilterOp;

			const key = Object.keys(queryWhere)[0];

			return refOrQuery.where(key, filter, queryWhere[key]);
		},
	};


	constructor(
		private ngFirestore : AngularFirestore,
	) {}


	/*
	 * Read Methods - new
	 * pull from Firestore
	 */
	getRef(collection : FirestoreCollection) : AngularFirestoreCollection<any> {
		const ref = this.ngFirestore.collection(collection);

		if (collection === 'logs')
			return ref as AngularFirestoreCollection<Log>;
		else if (collection === 'products')
			return ref as AngularFirestoreCollection<Product>;
		else if (collection === 'stores')
			return ref as AngularFirestoreCollection<Store>;
		else if (collection === 'collections')
			return ref as AngularFirestoreCollection<Collection>;
		else if (collection === 'users')
			return ref as AngularFirestoreCollection<User>;
		else if (collection === 'userStatuses')
			return ref as AngularFirestoreCollection<UserStatus>;
		else if (collection === 'deletedUsers')
			return ref as AngularFirestoreCollection<User>;
	}

	query(
		collection  : FirestoreCollection,
		queriesOrFn : FirestoreQuery | FirestoreQuery[] | QueryFn,
	) : Observable<any[]> {
		const queryFn : QueryFn = typeof queriesOrFn === 'function'
			? queriesOrFn
			: this.parseQueryFn(queriesOrFn);

		return this.ngFirestore
			.collection(collection, queryFn)
			.valueChanges()
			.pipe(
				map((list : any[]) => {
					if (collection === 'logs')
						return list as Log[];
					else if (collection === 'products')
						return list as Product[];
					else if (collection === 'stores')
						return list as Store[];
					else if (collection === 'collections')
						return list as Collection[];
					else if (collection === 'users')
						return list as User[];
					else if (collection === 'userStatuses')
						return list as UserStatus[];
					else if (collection === 'deletedUsers')
						return list as User[];
				}),
			);
	}

	private parseQueryFn(queries : FirestoreQuery | FirestoreQuery[]) : QueryFn {
		const queriesArr = Helper.listify(queries);

		return (ref : CollectionReference) => {
			let firestoreQuery : Query;

			for (const query of queriesArr) {
				const type = Object.keys(query)[0];

				firestoreQuery = this.queryMethods[type](firestoreQuery ?? ref, query[type]);
			}

			return firestoreQuery;
		};
	}

	createId() : string {
		return this.ngFirestore.createId();
	}

	excludeSoldOutQuery(queries : FirestoreQuery[], excludeSoldOut : boolean) : FirestoreQuery[] {
		if (excludeSoldOut) {
			queries.push({
				where : {
					FilterOp  : '>',
					inventory : 0,
				},
			});
		}

		return queries;
	}
}
