import { Component, Input, Output, EventEmitter } from '@angular/core';

import { InputField } from '../../../types/input-fields';


@Component({
	selector    : 'app-default-class-input',
	templateUrl : './default-class-input.component.html',
	styleUrls   : ['./default-class-input.component.scss'],
})
export class DefaultClassInputComponent {
	@Input() field   : InputField;
	@Input() loading : boolean;

	@Output() save = new EventEmitter<any>();


	constructor() {}


	onSave(newValue : any) : void {
		this.save.emit(newValue);
	}
}
