import { Component, OnInit, OnDestroy, OnChanges, Input, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { AlertController, PopoverController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';


import { HelperService as Helper } from '../../../services/helper/helper.service';
import { ImgixService } from '../../../services/imgix/imgix.service';
import { FileStorageService } from '../../../services/file-storage/file-storage.service';
import { ProductImagesService } from '../../../services/product-images/product-images.service';
import { ImageUploadComponent, SelectedImage } from '../image-upload/image-upload.component';
import { ImageValue, SingleImage, MultiImage, MetaSingleImage, MetaMultiImage } from '../../../types/input-fields';

import { EditMenuPopoverComponent } from './edit-menu-popover/edit-menu-popover.component';



@Component({
	selector    : 'app-image-input',
	templateUrl : './image-input.component.html',
	styleUrls   : ['./image-input.component.scss'],
})
export class ImageInputComponent implements OnInit, OnDestroy, OnChanges {
	@Input() field           : SingleImage | MultiImage | MetaSingleImage | MetaMultiImage;
	@Input() loading         : boolean;
	@Input() defaultFilename : string;
	@Input() preventSave     : boolean;
	@Input() preventDelete   : boolean;
	@Input() customIcon      : string;
	@Input() customIconColor : string;
	@Input() immediateUpload : boolean;
	@Input() hideHeader      : boolean;
	@Input() disabled        : boolean;
	@Input() outputImgixUrl  : boolean = true;
	@Input() buttonFill      : 'clear' | 'default' | 'outline' | 'solid';
	@Input() buttonSize      : 'default' | 'large' | 'small';
	@Input() didSave$        : Subject<any>;
	@Input() didDelete$      : Subject<any>;

	@Output() save        = new EventEmitter<ImageValue | ImageValue[]>();
	@Output() delete      = new EventEmitter<void>();
	@Output() customEvent = new EventEmitter<void | number>();

	@ViewChild('fileInput', { static : true }) fileInput;

	private stop$ : Subject<boolean> = new Subject<boolean>();

	helper         = Helper;
	currentImages  : ImageValue[] = [];
	selectedImages : SelectedImage[] = [];
	preSaveValue   : string[];
	multi          : boolean;
	orderable      : boolean;
	deletable      : boolean;
	saveClicked    : boolean = false;


	constructor(
		private alert         : AlertController,
		private fileStorage   : FileStorageService,
		private imgix         : ImgixService,
		private productImages : ProductImagesService,
		public popover        : PopoverController,
		public imageUpload    : ImageUploadComponent,
	) {}


	ngOnInit() : void {
		this.saveMulti();
		this.saveOrderable();
		this.saveDeletable();
		this.saveFieldValue();

		this.initPostSave();
		this.initPostDelete();
	}

	ngOnDestroy() : void {
		this.stop$.next(true);
		this.stop$.unsubscribe();
	}

	ngOnChanges(changes : SimpleChanges) : void {
		if (changes.field && changes.field.firstChange === false) {
			const currentField = changes.field.currentValue,
				previousField = changes.field.previousValue,
				currentInputOptions = currentField.inputOptions || {},
				previousInputOptions = previousField.inputOptions || {};

			if (currentInputOptions.multiple !== previousInputOptions.multiple)
				this.saveMulti(currentInputOptions.multiple);

			if (currentInputOptions.orderable !== previousInputOptions.orderable)
				this.saveOrderable(currentInputOptions.orderable);

			if (currentField.readOnly !== previousField.readOnly || currentField.deletable !== previousField.deletable)
				this.saveDeletable(currentField.readOnly, currentField.deletable);

			if (currentField.value !== previousField.value)
				this.saveFieldValue(currentField.value);
		}

		if (changes.preventDelete && changes.preventDelete.firstChange === false) {
			if (changes.preventDelete.currentValue !== changes.preventDelete.previousValue)
				this.saveDeletable();
		}
	}

	private saveMulti(multi? : boolean) : void {
		this.multi = !!(multi || this.field.inputOptions.multiple);
	}

	private saveOrderable(orderable? : boolean) : void {
		this.orderable = !!(orderable || (<MultiImage | MetaMultiImage> this.field).inputOptions.orderable);
	}

	private saveDeletable(readOnly? : boolean, deletable? : boolean) : void {
		this.deletable = this.preventDelete !== true && (!(readOnly || this.field.readOnly) && (deletable || this.field.deletable));
	}

	private saveFieldValue(value? : ImageValue | ImageValue[]) : void {
		if (typeof value === 'string') {
			value = { src : value };
		}

		const incomingValue = value ?? this.field.value ?? [];
		const incomingArray : ImageValue[] = Helper.listify(incomingValue);

		this.currentImages = Helper.deepCopy(incomingArray);
	}

	private initPostSave() : void {
		if (this.didSave$ != null) {
			this.didSave$.pipe(
				takeUntil(this.stop$),
			)
				.subscribe(this.postSave.bind(this));
		}
	}

	private initPostDelete() : void {
		if (this.didDelete$ != null) {
			this.didDelete$.pipe(
				takeUntil(this.stop$),
			)
				.subscribe(this.postDelete.bind(this));
		}
	}

	private async showAlert(header : string, message : string, confirmHandler : Function) : Promise<void> {
		const alert = await this.alert.create({
			header,
			message,
			buttons : [
				{
					text     : 'Cancel',
					role     : 'cancel',
					cssClass : 'secondary',
				},
				{
					text    : 'Confirm',
					handler : confirmHandler.bind(this),
				},
			],
		});

		await alert.present();
	}

	private showDeleteAlert(deletee : string, confirmHandler : Function) : void {
		this.showAlert(
			'Confirm Delete',
			`Are you sure you want to delete ${ deletee }? There's no going back.`,
			confirmHandler
		);
	}

	private deleteField() : void {
		this.delete.emit();
	}

	private postDelete(deletedValue : any) : void {
		this.deleteFiles(Array.isArray(deletedValue) ? deletedValue : [ deletedValue ]);
	}

	private saveNewValue() : void {
		this.preSaveValue = Helper.deepCopy(this.field.value);

		let output : ImageValue | ImageValue[] = this.currentImages;

		if (!this.field.inputOptions.multiple)
			output = output[0];

		this.save.emit(output);

		if (this.immediateUpload === true)
			this.postSave();
	}

	private postSave() : void {
		this.saveClicked = false;
	}

	getFilename(url? : string) : string {
		if (url == null)
			url = this.currentImages[0].src;

		return url && typeof url === 'string' ? Helper.getFilename(url) : '';
	}

	selectFiles() : void {
		this.fileInput.nativeElement.click();
	}

	async filesChanged(event) : Promise<void> {
		if (!this.multi)
			this.selectedImages = [];

		for (const file of event.target.files) {
			let dataUrl : string;

			try {
				dataUrl = await Helper.getDataUrlFromFile(file);
			} catch (error) {
				console.error(error);
			}

			try {
				dataUrl = await this.productImages.resizeAndConvertToMediaType(dataUrl, file.type);
			} catch (error) {
				console.error(error);
			}

			this.selectedImages.push({
				file,
				dataUrl,
			});
		}

		if (this.immediateUpload === true && this.selectedImages.length)
			this.saveImages();
	}

	async saveImages() : Promise<void> {
		this.saveClicked = true;

		let saveCount: number = 0;
		const imageSrcs : string[] = [];

		for (const image of this.selectedImages) {
			let url : string;

			try {
				url = await this.uploadImage(
					image,
					this.imageUpload.parseFilename(image, this.defaultFilename),
				);
			} catch (error) {
				console.error(error);
			}

			imageSrcs.push(this.outputImgixUrl ? this.imgix.url(url) : url);

			image['uploaded'] = true;

			saveCount++;

			if (saveCount === this.selectedImages.length)
				this.saveImage(imageSrcs);
		}
	}

	private uploadImage(image : SelectedImage, filename : string) : Promise<string> {
		const task = this.fileStorage.uploadDataUrl(
			image.dataUrl,
			filename,
		);

		image['percentageChanges'] = task.percentageChanges();
		image['uploading'] = true;

		return task.getDownloadURL().toPromise();
	}

	private saveImage(imageSrcs : string[]) : void {
		if (this.multi)
			this.currentImages = this.currentImages.concat(imageSrcs.map(src => ({ src })));
		else
			this.currentImages = imageSrcs.map(src => ({ src }));

		this.selectedImages = [];

		this.saveNewValue();
	}

	async showEditMenuPopover(event: Event) : Promise<any> {
		event.stopPropagation();

		const popover = await this.popover.create({
			component      : EditMenuPopoverComponent,
			componentProps : {
				parent : this,
			},
			event,
		});

		return await popover.present();
	}

	replaceImage() : void {
		this.selectFiles();
	}

	deleteImage(event? : any, index? : number, filename? : string) : void {
		if (event && event.stopPropagation)
			event.stopPropagation();

		if ((!this.multi || this.currentImages.length === 1) && this.field.inputClass === 'meta')
			this.deleteField();
		else if (index != null) {
			this.showDeleteAlert(`this image (${ this.getFilename(filename) })`, () => {
				this.currentImages.splice(index, 1);

				this.saveNewValue();
			});
		}
	}

	private deleteFiles(deletee : string[]) : void {
		for (const filename of deletee)
			this.deleteFile(filename);
	}

	private deleteFile(filename : string) : void {
		this.fileStorage.delete(this.getFilename(filename))
			.pipe(
				take(1)
			)
			.subscribe();
	}

	removeSelectedImage(index : number) : void {
		this.selectedImages.splice(index, 1);
	}

	async reorderImages(reorderElement, reorderGroup : any[], immediateSave : boolean = false) : Promise<void> {
		const reordered = await reorderElement.complete(reorderGroup);

		if (immediateSave) {
			this.currentImages = reordered;

			this.saveNewValue();
		}
	}
}
