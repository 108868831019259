import { SentenceFragment } from '../types/sentence-fragment';


export function DescriptionDefinition(grouping : 'category' | 'subcategory' = 'category') : SentenceFragment[] {
	return [
		{
			key : 'era',
		},
		{
			key : 'brand',
		},
		{
			key : 'material',
		},
		{
			key : grouping,
		},
		{
			preConnector : 'in',
			key          : 'color',
		},
	];
}
