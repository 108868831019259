import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of, combineLatest, throwError, asyncScheduler } from 'rxjs';
import { map, switchMap, take, tap, debounceTime, throttleTime } from 'rxjs/operators';
import { AngularFirestoreCollection, QueryFn } from '@angular/fire/compat/firestore';
import { sortedUniqBy, sortBy as _sortBy, uniqBy } from 'lodash';
import { Product, ProductImage, ProductMetafield, ProductMetafieldKey, ProductMetafieldMap, ListingType, Query } from '@shopthrilling/thrilling-shared';

import { environment } from '../../../environments/environment';
import { EventContext } from '../../constants/EventContext';
import { HelperService as Helper } from '../../services/helper/helper.service';
import { ImgixService } from '../../services/imgix/imgix.service';
import { ShopifyAdminService, ShopifyProductImageInput, ShopifyMetafieldInput } from '../../modules/shopify-admin/shopify-admin.service';
import { StoreService } from '../../services/store/store.service';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { CurrentUserService } from '../../services/current-user/current-user.service';
import { ProductFields, GetMetafieldNamespaceFromKey } from '../../models/product-fields.model';
import { NewBasicProductFields } from '../../models/new-basic-product-fields.model';
import { NewDetailedProductFields } from '../../models/new-detailed-product-fields.model';
import { NewWholesaleProductFields } from '../../models/new-wholesale-product-fields.model';
import { SizeTagToDisplaySize, GetSizeList } from '../../models/size.model';
import { GetPriceBuckets } from '../../models/price.model';
import { StringMap, AnyMap, BooleanMap } from '../../types/basics';
import { ProductOld, ProductWithFields, ProductPrimaryInputs, ProductSecondaryFields, StructuredTag, ShopifyProductInput, PostPublishWarningCheck, PostPublishWarning } from '../../types/product';
import { Metafield, MetafieldInput , ImageValue } from '../../types/input-fields';
import { ProductFieldGrouping } from '../../types/product-input-fields';
import { NewBasicProductFieldGrouping } from '../../types/new-basic-product-input-fields';
import { NewDetailedProductFieldGrouping } from '../../types/new-detailed-product-input-fields';
import { NewWholesaleProductFieldGrouping } from '../../types/new-wholesale-product-input-fields';
import { FirestoreQuery } from '../../types/firestore';
import { EventIssuer } from '../../constants/EventIssuer';
import { LogService } from '../../services/log/log.service';


type AuditQueuePayload = {
	products            : Product[];
	pendingImageEditMap : BooleanMap;
};


@Injectable({
	providedIn : 'root',
})
export class ProductService {
	private readonly postPublishWarningChecks : PostPublishWarningCheck[] = [
		(product) => product.graphqlAdminId == null
			? {
				icon : 'cloud-upload',
				text : 'product does not exist in Shopify',
			}
			: false,
		(product) => product.containsProblematicTerms
			? {
				icon       : 'warning',
				text       : `listing contains the following problematic term${ product.containsProblematicTerms.length === 1 ? '' : 's' }:`,
				strongText : product.containsProblematicTerms.join(', '),
			}
			: false,
		(product) => product.images?.[0] == null
			? {
				icon : 'image',
				text : 'product lacks front image',
			}
			: false,
		(product) => product.images?.[0] != null && product.images?.some(image => image?.graphqlAdminId == null)
			? {
				icon : 'images',
				text : 'an image has not uploaded to Shopify',
			}
			: false,
		(product) => product.images?.[0] != null && product.images?.some(image => image?.deleted == true)
			? {
				icon : 'trash',
				text : 'an image has been deleted from Shopify',
			}
			: false,
		(product) => this.isPendingImageEdit(product)
			? {
				icon : 'layers',
				text : 'front image is pending manual edit',
			}
			: false,
	];

	private productsRef : AngularFirestoreCollection<Product>;
	private cache       : any = {};

	query$       : BehaviorSubject<string> = new BehaviorSubject<string>('');
	hideSoldOut$ : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	loading      : boolean = false;


	constructor(
		private imgix          : ImgixService,
		private shopifyGraphql : ShopifyAdminService,
		private store          : StoreService,
		private firestore      : FirestoreService,
		private currentUser    : CurrentUserService,
		private log            : LogService,
		private sharedProduct  : Product,
	) {
		this.productsRef = this.firestore.getRef('products');
	}


	/*
	 * Read Methods - new
	 * pull from Firestore
	 */
	getAll() : Observable<Product[]> {
		return this.productsRef.valueChanges();
	}

	getById(id : string) : Observable<Product> {
		return this.productsRef.doc<Product>(id).valueChanges();
	}

	getByIdPromise(id : string) : Promise<Product> {
		return this.getById(id).pipe(
			take(1),
		)
			.toPromise();
	}

	getByShopifyId(id : string, returnFirst : boolean = true) : Observable<Product | Product[]> {
		return this.getByKeyValue('graphqlAdminId', id, returnFirst);
	}

	getBySkuForPdp(sku : string, includeStores : boolean = true) : Observable<ProductWithFields> {
		return this.getByKeyValue('sku', sku, false, false, true).pipe(
			map((list : Product[]) => {
				if (list?.length > 1)
					this.logDupes(list, 'handle');

				return Helper.first(list);
			}),
			map(this.firestoreShopifyCompat.bind(this)),
			// tap(data => console.debug(data)),
			map((product : Product) => this.getProductFields(product)),
			take(1),
			// tap(data => console.debug(data)),
		);
	}

	private firestoreShopifyCompat(product : Product) : Product {
		if (!product?.id) {
			return product;
		}

		// patch description/descriptionHtml naming inconsistency
		if (product.descriptionHtml == null) {
			product.descriptionHtml = product.description;
		}

		if (product.weight == null && product.variants?.[0]?.weight != null) {
			product.weight = product.variants[0].weight;
		}

		return product;
	}

	private logDupes(list : Product[], queryType : 'handle' | 'GraphQl id' | 'sku') : void {
		this.log.error(EventIssuer.ProductService, {
			handle       : list[0].handle,
			subject      : `duplicate products found with the same ${ queryType }`,
			eventContext : EventContext.ProductDupe,
			details      : {
				list,
				count : `expected 1; found ${ list.length }`,
			},
		});
	}

	getBySku(sku : string, returnFirst : boolean = true) : Observable<Product | Product[]> {
		return this.getByKeyValue('sku', sku, returnFirst);
	}

	getDetailedProducts(limit? : number) : Observable<Product[]> {
		const queryArr : FirestoreQuery[] = [
			{ where : {
				FilterOp : 'array-contains',
				tags     : 'meta:detailed product',
			}},
			{ orderBy : [ 'createdAt', 'desc' ] },
		];

		if (limit)
			queryArr.push({ limit });

		return this.queryFirestore(
			queryArr,
			false,
			false,
		);
	}

	getByKeyValue(
		key           : string,
		value         : string,
		returnFirst   : boolean = false,
		sortResults   : boolean = false,
		parseProducts : boolean = false,
	) : Observable<Product | Product[]> {
		return this.queryFirestore(
			{ where : { [key] : value }},
			!returnFirst && sortResults,
			parseProducts,
		).pipe(
			map(data => returnFirst
				? Helper.first(data) as Product
				: data as Product[]
			),
		);
	}

	queryFirestore(
		queriesOrFn   : FirestoreQuery | FirestoreQuery[] | QueryFn,
		sortResults   : boolean = true,
		parseProducts : boolean = true,
	) : Observable<Product[]> {
		return this.firestore.query('products', queriesOrFn).pipe(
			map((products : Product[]) => {
				if (parseProducts) {
					products = products
						.map(this.parseFirestoreProduct.bind(this));
				}

				return sortResults
					? _sortBy(products, ['title'])
					: products;
			}),
		);
	}

	getByCollection(handle : string, limit : number = 100000, hideSoldOut? : boolean) : Observable<Product[]> {
		return (hideSoldOut == null ? this.hideSoldOut$ : of(hideSoldOut)).pipe(
			// tap(data => console.debug(data)),
			map((excludeSoldOut : boolean) : FirestoreQuery[] => {
				const queries : FirestoreQuery[] = [{ where : {
					FilterOp    : 'array-contains',
					collections : handle,
				}}];

				this.firestore.excludeSoldOutQuery(queries, excludeSoldOut);

				queries.push({ limit });

				return queries;
			}),
			switchMap(this.queryFirestore.bind(this)),
			// tap(data => console.debug(data)),
		);
	}

	getByCategory(category : string) : Observable<Product[]> {
		return this.getByKeyValue('category', category) as Observable<Product[]>;
	}

	getBySubcategory(subcategory : string) : Observable<Product[]> {
		return this.queryFirestore(
			{ where : {
				FilterOp : 'array-contains',
				tags     : `subcategory:${ subcategory }`,
			}},
			false,
		);
	}

	getByCollectionAndQuery(handle : string) : Observable<Product[]> {
		return this.getByCollection(handle).pipe(
			// tap(data => console.debug(data)),
			switchMap((items : Product[]) => this.query$.pipe(
				debounceTime(250),
				tap(() => this.toggleLoading(true)),
				map(query => Query.matchObject(
					items,
					query,
					[ 'handle', 'title', 'sku' ],
				)),
			)),
			tap(() => this.toggleLoading(false)),
			// tap(data => console.debug(data)),
		);
	}

	getByStore(vendor : string, hideSoldOut? : boolean) : Observable<Product[]> {
		return (hideSoldOut == null ? this.hideSoldOut$ : of(hideSoldOut)).pipe(
			// tap(data => console.debug(data)),
			map((excludeSoldOut : boolean) : FirestoreQuery[] => {
				const queries : FirestoreQuery[] = [{ where : { vendor } }];

				this.firestore.excludeSoldOutQuery(queries, excludeSoldOut);

				queries.push({ orderBy : [ 'createdAt', 'desc' ] });

				return queries;
			}),
			switchMap(this.queryFirestore.bind(this)),
			// tap(data => console.debug(data)),
		);
	}

	getFirstProductByStoreHandle(vendor : string) : Observable<Product> {
		const queries : FirestoreQuery[] = [
			{
				where : { vendor },
			},
			{
				where : {
					isDraft : false,
				},
			},
			{
				orderBy : [ 'createdAt' ],
			},
			{
				limit : 1,
			},
		];

		return this.queryFirestore(queries, false, false)
			.pipe(
				take(1),
				map(list => Helper.first(list)),
			);
	}

	getByStoreAndQuery(handle : string) : Observable<Product[]> {
		return this.getByStore(handle).pipe(
			// tap(data => console.debug(data)),
			switchMap((items : Product[]) => this.query$.pipe(
				debounceTime(250),
				tap(() => this.toggleLoading(true)),
				map(query => Query.matchObject(
					items,
					query,
					[ 'handle', 'title', 'sku' ],
				)),
			)),
			map(products => uniqBy(products, 'sku')),
			tap(() => this.toggleLoading(false)),
			// tap(data => console.debug(data)),
		);
	}

	private parseFirestoreProduct(product : Product) : Product {
		// fill in handle if missing
		if (product.handle == null && product.graphqlAdminId != null)
			product.handle = Helper.parseGraphQlNumericalId(product.graphqlAdminId);

		// fill in totalInventory
		if (product['totalInventory'] == null && typeof product['availability'] === 'number') {
			product['totalInventory'] = product['availability'];
		}

		if (product.inventory == null && typeof product['totalInventory'] === 'number') {
			product.inventory = product['totalInventory'];
		}

		this.parseVariants(product);
		this.parseMisc(product);

		return product;
	}

	getByEditRequested(excludeSoldOut : boolean = true) : Observable<Product[]> {
		const queries : FirestoreQuery[] = [{ where : { editRequested : true } }];

		this.firestore.excludeSoldOutQuery(queries, excludeSoldOut);

		return this.queryFirestore(queries, false, false);
	}

	async getImportedWithOriginalTitleByStore(
		vendor           : string,
		starting?        : string,
		ending?          : string,
		ownProducts      : boolean = true,
		hasOriginalTitle : boolean = true,
	) : Promise<Product[]> {
		const queries : FirestoreQuery[] = [
			{
				where : { vendor },
			},
			{
				where : {
					FilterOp : 'array-contains',
					tags     : 'meta:imported product',
				},
			},
		];

		if (ownProducts) {
			const createdBy = await this.currentUser.id();

			queries.push({
				where : { createdBy },
			});
		}

		if (starting) {
			queries.push({
				where : {
					FilterOp  : '>=',
					createdAt : starting,
				},
			});
		}

		if (ending) {
			queries.push({
				where : {
					FilterOp  : '<=',
					createdAt : ending,
				},
			});
		}

		queries.push({
			orderBy : [ 'createdAt' ],
		});

		let list = await this.queryFirestore(queries, false, false).pipe(
			throttleTime(1500, asyncScheduler, { trailing : true }),
			take(1),
		)
			.toPromise();

		if (hasOriginalTitle) {
			list = list.filter(item => item.importedOriginalTitle?.trim());
		}

		return list;
	}

	getByCreatedAtDate(date : string) : Observable<Product[]> {
		// in case an ISO date was passed, take just the date portion
		const justDate : string = date.split('T')[0];
		const starting : string = new Date(justDate).toISOString();
		const endingDate : Date = new Date(starting);

		endingDate.setDate(endingDate.getDate() + 1);

		const ending : string = endingDate.toISOString();

		const queries : FirestoreQuery[] = [
			{
				where : {
					FilterOp  : '>=',
					createdAt : starting,
				},
			},
			{
				where : {
					FilterOp  : '<',
					createdAt : ending,
				},
			},
			{
				where : {
					isDraft : false,
				},
			},
		];

		return this.queryFirestore(queries, false, false);
	}

	getAuditQueue(
		hideSoldOut?         : boolean,
		showFlaggedListings? : boolean,
		hideFlaggedListings? : boolean,
		vendor?              : string,
		starting?            : string,
		ending?              : string,
	) : Observable<AuditQueuePayload> {
		const queries : FirestoreQuery[] = [{
			where : { requiresPostPublishCheck : true },
		}];

		if (hideSoldOut) {
			queries.push({
				where : { available : true },
			});
		}

		if (vendor) {
			queries.push({
				where : { vendor },
			});
		}

		if (starting) {
			queries.push({
				where : {
					FilterOp  : '>=',
					createdAt : starting,
				},
			});
		}

		if (ending) {
			queries.push({
				where : {
					FilterOp  : '<=',
					createdAt : ending,
				},
			});
		}

		queries.push(
			{
				orderBy : [ 'createdAt' ],
			},
		);

		return this.queryFirestore(queries, false, false).pipe(
			debounceTime(750),
			map(products => showFlaggedListings || hideFlaggedListings
				?	this.filterFlaggedListings(!!showFlaggedListings, products)
				: products
			),
			map(products => ({
				products,
				pendingImageEditMap : this.parsePendingImageEditMap(products),
			})),
		);
	}

	private filterFlaggedListings(show : boolean, products : Product[]) : Product[] {
		return products.filter(product =>
			show === this.postPublishWarningChecks.some(check => !!check(product))
		);
	}

	private parsePendingImageEditMap(products : Product[]) : BooleanMap {
		const map : BooleanMap = {};

		products
			.filter(this.isPendingImageEdit.bind(this))
			.forEach(item => map[item.id] = true);

		return map;
	}

	isPendingImageEdit(product : Product | ProductWithFields) : boolean {
		if (!product.editRequested) {
			return false;
		}

		const image = product.images?.[0];

		return image?.editRequestedAt && !image?.editCompletedAt;
	}

	getNewSku() : Observable<string> {
		return of(Helper.createId('sku')).pipe(
			switchMap(this.testSku.bind(this)),
			take(1),
		);
	}

	private testSku(sku : string) : Observable<string> {
		return this.isSkuAssigned(sku).pipe(
			take(1),
			switchMap(isAssigned => isAssigned ? this.getNewSku() : of(sku)),
			take(1),
		);
	}

	isSkuAssigned(sku : string) : Observable<boolean> {
		if (sku == null) {
			return throwError('Can\'t check if SKU is assigned, because SKU argument is null');
		}

		return this.getBySku(sku).pipe(
			take(1),
			// tap(data => console.debug(data)),
			map(product => !!product),
		);
	}

	getDupeImportedProducts(importedOriginalTitle : string) : Observable<Product[]> {
		const queries : FirestoreQuery[] = [{
			where : { importedOriginalTitle },
		}];

		return this.queryFirestore(queries, true, false);
	}


	/*
	 * Write Methods
	 * push to Firestore
	 */
	async create(data : any) : Promise<Product> {
		const product = {
			...new Product(),
			...data,
		};

		product.id = this.firestore.createId();

		if (product.createdAt == null) {
			product.createdAt = new Date().toISOString();
		}

		if (product.createdBy == null) {
			product.createdBy = await this.currentUser.id();
		}

		// coerce data to JSON and back to remove undefined values and custom class objects, which Firestore will choke on
		await this.productsRef.doc(product.id).set(Helper.deepCopy(product));

		return product;
	}

	async update(id : string, updates : AnyMap) : Promise<any> {
		updates.updatedAt = new Date().toISOString();
		updates.updatedBy = await this.currentUser.id();

		if (Array.isArray(updates?.images)) {
			let previousProduct : Product;

			try {
				previousProduct = await this.getById(id).pipe(
					take(1),
				)
					.toPromise();
			} catch (error) {
				console.error(error);
			}

			const previousImages = (Array.isArray(previousProduct?.images) && previousProduct?.images) ?? [];

			updates.images = this.sharedProduct.mergeProductImages(previousImages, updates.images);
		}

		try {
			// coerce data to JSON and back to remove undefined values and custom class objects, which Firestore will choke on
			await this.productsRef.doc(id).update(Helper.deepCopy(updates));
		} catch (error) {
			console.error(error);
		}

		return this.getByIdPromise(id);
	}


	/**
	 * This is reserved for image reAdding for now (TT-1424)
	 * @param id the Firestore document ID
	 * @param updates the updates to be applied to the product
	 * @returns a fresh Product
	 */
	async simpleImageUpdate(id : string, updates : AnyMap) : Promise<Product> {
		updates.updatedAt = new Date().toISOString();
		updates.updatedBy = await this.currentUser.id();
			
		try {
			// coerce data to JSON and back to remove undefined values and custom class objects, which Firestore will choke on
			await this.productsRef.doc(id).update(Helper.deepCopy(updates));
		} catch (error) {
			console.error(error);
		}

		return this.getByIdPromise(id);
	}

	delete(id : string) : Promise<void> {
		return this.productsRef.doc(id).delete();
	}

	async updateInFirestoreAndShopify(id : string, updates : ProductPrimaryInputs) : Promise<Product> {
		// augment updated store data with secondary fields
		const updatePayload : ProductSecondaryFields = this.parseProductSecondaryFields(updates);

		// the product must be retreieved from Firestore so the data can be merged
		const currentProduct = await this.getByIdPromise(id);

		const updatedData = await this.mergeUpdatedDataWithFirestoreProduct(updatePayload, currentProduct) as Product;

		// save updated data to Firestore
		let updatedProduct = await this.update(id, updatedData);

		const shopifyResponse = await this.updateInShopify(updatedProduct);

		const parsedShopifyResponse = this.parseShopifyResponse(shopifyResponse, updatedProduct, false);

		if (parsedShopifyResponse.isFirestoreUpdateNeeded) {
			// save updated store data to Firestore
			updatedProduct = await this.update(id, parsedShopifyResponse.updates);
		}

		return this.firestoreShopifyCompat(updatedProduct);
	}

	private parseProductSecondaryFields(updates : AnyMap) : ProductSecondaryFields {
		// iterate through all keys/values, passing each to the metafield parsing function
		// to determine if descendent fields should be derived from the primary field
		for (const [ key, value ] of Object.entries(updates)) {
			this.parseTagFromUpdate(updates, key, value);
			this.parseMetafieldFromUpdate(updates, key, value);
		}

		return updates;
	}

	/**
	 * A static method used to parse all secondary tag fields that descend from primary fields.
	 * Based on the primary field key, determines what tag prefix and value is needed.
	 * @param updates: the ProductSecondaryFields object
	 * @param key: the primary field key
	 * @param value: the primary field value
	 */
	private parseTagFromUpdate(updates : ProductSecondaryFields, key : string, value : any) : void {
		switch (key) {
			case 'price':
				this.stashTagUpdate(updates, key, GetPriceBuckets(value as number));

				break;

			case 'division':
			case 'department':
			case 'category':
			case 'subcategory':
			case 'brand':
				this.stashTagUpdate(updates, key, value as string);

				break;

			case 'sizes':
			case 'colors':
			case 'eras':
			case 'materials':
				this.stashTagUpdate(updates, key.replace(/s$/, '') as StructuredTag, value as string[]);

				break;

			case 'dressSkirtLength':
				this.stashTagUpdate(updates, 'dress/skirt length', value as string);

				break;

			case 'sleeveLength':
				this.stashTagUpdate(updates, 'sleeve length', value as string);

				break;

			case 'listingType':
				this.stashTagUpdate(updates, 'listing type', value as string);

				break;
		}
	}

	/**
	 * A static method used to store updates for a single tag prefix
	 * @param updates: the ProductSecondaryFields object
	 * @param key: the structured tag prefix to update
	 * @param value: the updated tag value, as a single string value or array of values
	 */
	private stashTagUpdate(updates : ProductSecondaryFields, key : StructuredTag, value : string | string[]) : void {
		// if the updated value is "empty" - nullish, an empty string, or a zero length array - then
		// the tag should be deleted rather than updated
		if (Helper.isEmpty(value, { includeEmptyString : true })) {
			return this.stashTagDelete(updates, key);
		}

		// insure the updates.update.tag object exists before attempting to update it
		if (!updates.update?.tags) {
			if (!updates.update) {
				updates.update = {};
			}

			updates.update.tags = {};
		}

		updates.update.tags[key] = Helper.listify(value);
	}

	/**
	 * A static method used to store deletes for a single tag prefix
	 * @param updates: the ProductSecondaryFields object
	 * @param key: the structured tag prefix to delete
	 */
	private stashTagDelete(updates : ProductSecondaryFields, key : StructuredTag) : void {
		// insure the updates.delete.tag array exists before attempting to push to it
		if (!updates.delete?.tags) {
			if (!updates.delete) {
				updates.delete = {};
			}

			updates.delete.tags = [];
		}

		updates.delete.tags.push(key);
	}

	/**
	 * A static method used to parse all secondary metafields that descend from primary fields.
	 * Based on the primary field key, determines what metafield key, value, and value type is needed.
	 * @param updates: the ProductSecondaryFields object
	 * @param key: the primary field key
	 * @param value: the primary field value
	 */
	private parseMetafieldFromUpdate(updates : ProductSecondaryFields, key : string, value : any) : void {
		switch (key) {
			case 'id':
				this.stashMetafieldUpdate(updates, 'firestoreId', value as string);

				break;

			case 'brand':
				this.stashMetafieldUpdate(updates, 'displayBrand', value as string);

				break;

			case 'tagSize':
			case 'condition':
			case 'displaySize':
			case 'displayColor':
			case 'displayMaterial':
			case 'displayTitle':
				this.stashMetafieldUpdate(updates, key, value as string);

				break;

			case 'measurements':
				for (const [ measurementKey, measurementValue ] of Object.entries(value)) {
					this.stashMetafieldUpdate(updates, measurementKey as ProductMetafieldKey, measurementValue as number);
				}

				break;
		}
	}

	/**
	 * A static method used to store updates for a single metafield
	 * @param updates: the ProductSecondaryFields object
	 * @param key: the metafield key to update
	 * @param value: the updated value
	 * @param valueType: the metafield value type
	 */
	private stashMetafieldUpdate(
		updates   : ProductSecondaryFields,
		key       : ProductMetafieldKey,
		value     : string | string[] | number,
		valueType : 'STRING' | 'INTEGER' | 'JSON_STRING' = 'STRING',
	) : void {
		// if the updated value is "empty" - nullish, an empty string, or a zero length array - then
		// the metafield should be deleted rather than updated
		if (Helper.isEmpty(value, { includeEmptyString : true })) {
			return this.stashMetafieldDelete(updates, key);
		}

		// retrieve the namespace that corresponds to the given key from the metafield namespace map
		const namespace : string | undefined = GetMetafieldNamespaceFromKey(key);

		if (!namespace) {
			return;
		}

		// metafields can only store values in string format
		// if the current value is not a string, make it so
		if (typeof value !== 'string') {
			value = JSON.stringify(value);
		}

		// insure the updates.update.metafield object exists before attempting to update it
		if (!updates.update?.metafields) {
			if (!updates.update) {
				updates.update = {};
			}

			updates.update.metafields = {};
		}

		updates.update.metafields[key] = {
			key,
			namespace,
			valueType,
			value,
		};
	}

	/**
	 * A static method used to store deletes for a single metafield
	 * @param updates: the ProductSecondaryFields object
	 * @param key: the metafield key to delete
	 */
	private stashMetafieldDelete(updates : ProductSecondaryFields, key : ProductMetafieldKey) : void {
		// insure the updates.delete.metafield array exists before attempting to push to it
		if (!updates.delete?.metafields) {
			if (!updates.delete) {
				updates.delete = {};
			}

			updates.delete.metafields = [];
		}

		updates.delete.metafields.push(key);
	}

	/**
	 * A static method used to merge updated data with current product data from Firestore
	 * @param updatePayload: the ProductSecondaryFields object
	 * @param currentProduct: product data retrieved from Firestore
	 * Returns: a Promise containing a map of updates
	 */
	private async mergeUpdatedDataWithFirestoreProduct(updatePayload : ProductSecondaryFields, currentProduct : Product) : Promise<AnyMap> {
		// if tags need to be updated or deleted, perform the needed action and merge with current product tags
		if (updatePayload.update?.tags || updatePayload.delete?.tags) {
			this.filterDeletedTagsFromFirestoreProductTags(updatePayload, currentProduct);

			if (updatePayload.update?.tags) {
				this.addUpdatedTagsToFirestoreProductTags(updatePayload);
			}
		}

		// if metafields need to be updated or deleted, perform the needed action and merge with current product metafields
		if (updatePayload.update?.metafields || updatePayload.delete?.metafields) {
			await this.mergeUpdatedMetafieldsWithFirestoreProductMetafields(updatePayload, currentProduct);
		}

		// delete the "update" and "delete" objects from updatePayload
		// so only a key/value map of updated fields are left
		delete updatePayload.update;
		delete updatePayload.delete;

		return updatePayload;
	}

	/**
	 * A static method used to filter deleted and updated tags from current product tags
	 * @param updatePayload: the ProductSecondaryFields object
	 * @param currentProduct: the current Product object
	 */
	private filterDeletedTagsFromFirestoreProductTags(updatePayload : ProductSecondaryFields, currentProduct : Product) : void {
		// gather a list of tag prefixes to filter out of the current product tags
		// this would be all prefixes to delete as well as those to be updated
		const prefixesToDelete : string[] = [
			...(updatePayload.delete?.tags ?? []),
			...Object.keys(updatePayload.update?.tags ?? {}),
		];

		// parse a regex object to identify all targeted prefixes
		// e.g. /^(?:price|brand|size):/
		const deleteRegex = new RegExp(`^(?:${ prefixesToDelete.join('|') }):`);

		// gather a list of tags to keep by starting with the current product tags
		// and filtering out any structured tag containing an unwanted prefix
		updatePayload.tags = (currentProduct.tags ?? [])
			.filter(tag => !deleteRegex.test(tag));
	}

	/**
	 * A static method used to add updated tags to current product tags
	 * @param updatePayload: the ProductSecondaryFields object
	 */
	private addUpdatedTagsToFirestoreProductTags(updatePayload : ProductSecondaryFields) : void {
		updatePayload.tags
			.push(...this.parseStructuredTagsFromProductSecondaryFields(updatePayload));
	}

	/**
	 * A static method used to parse a list of all structured tags
	 * from a map of tag prefixes and value arrays
	 * @param updatePayload: the ProductSecondaryFields object
	 */
	private parseStructuredTagsFromProductSecondaryFields(updatePayload : ProductSecondaryFields) : string[] {
		const allTags : string[] = [];

		// iterate through the tag update map of tag prefixes and corresponding value array
		for (const [ prefix, tags ] of Object.entries(updatePayload?.update?.tags ?? {})) {
			// create structured tags by combining the tag prefix and value
			const structuredTags = tags
				.map(tag => `${ prefix }:${ tag }`);

			allTags.push(...structuredTags);
		}

		return allTags;
	}

	/**
	 * A static method used to merge updated metafields with current product metafields
	 * @param updatePayload: the ProductSecondaryFields object
	 * @param currentProduct: the current Product object
	 */
	private async mergeUpdatedMetafieldsWithFirestoreProductMetafields(updatePayload : ProductSecondaryFields, currentProduct : Product) : Promise<void> {
		if (!currentProduct.metafieldMap) {
			currentProduct.metafieldMap = {};
		}

		// if there are metafields to delete, do so
		if (updatePayload.delete?.metafields) {
			await this.deleteUpdatedMetafields(updatePayload, currentProduct);
		}

		// if there are metafields to update, retrieve the respective metafield id
		// from the current product metafield map to allow the metafield to be updated in Shopify
		if (updatePayload.update?.metafields) {
			for (const [ key, metafield ] of Object.entries(updatePayload.update.metafields)) {
				const id = currentProduct.metafieldMap?.[key as ProductMetafieldKey]?.id;

				if (id) {
					metafield.id = id;
				}

				currentProduct.metafieldMap[key as ProductMetafieldKey] = metafield;
			}
		}

		updatePayload.metafieldMap = currentProduct.metafieldMap;
		updatePayload.metafields = Object.values(updatePayload.metafieldMap);
	}

	/**
	 * A static method used to delete updated metafields and remove them from the update payload
	 * @param updatePayload: the ProductSecondaryFields object
	 * @param currentProduct: the current Product object
	 */
	private async deleteUpdatedMetafields(updatePayload : ProductSecondaryFields, currentProduct : Product) : Promise<void> {
		// map the list of deletable metafields to the respective metafield id
		// retrieved from the current product metafield map
		const metafieldsToDelete = (updatePayload.delete?.metafields ?? [])
			.map(key => ({
				key,
				id : currentProduct.metafieldMap?.[key]?.id,
			}))
			.filter(item => item.id);

		if (metafieldsToDelete.length) {
			for (const { key, id } of metafieldsToDelete) {
				delete currentProduct.metafieldMap?.[key];

				try {
					await this.deleteMetafield({ id } as Metafield).pipe(
						take(1),
					)
						.toPromise();
				} catch (error) {
					console.error(error);
				}
			}
		}
	}

	/**
	 * A static function that saves updated product info to Shopify
	 * @param updatedProduct: the response from the Firestore document update
	 * Returns: Promise<any> the raw info returned from the request to Shopify
	 */
	private async updateInShopify(updatedProduct : Product) : Promise<any> {
		// Shopify lacks a dev sandbox
		// only save to Shopify in prod
		if (!environment.production) {
			return;
		}

		const shopifyInput = this.parseShopifyInput(updatedProduct, true);

		return this.shopifyGraphql.updateProductGetMetafieldsAndImages(shopifyInput).pipe(
			take(1),
		)
			.toPromise();
	}

	/**
	 *
	 * @param store: the document data from a firestore response
	 * @param isUpdate: A boolean for handling differences between the update and create objects
	 */
	private parseShopifyInput(product : Product, isUpdate : boolean = false) : ShopifyProductInput {
		const input : ShopifyProductInput = {
			title           : product.title,
			descriptionHtml : product.descriptionHtml as string,
			vendor          : product.vendor,
			productType     : product.category,
			status          : 'ACTIVE',
			tags            : product.tags ?? [],
			variants        : [
				{
					sku           : product.sku as string,
					price         : product.price,
					weight        : product.weight,
					weightUnit    : 'OUNCES',
					inventoryItem : {
						tracked : true,
					},
					inventoryQuantities : [
						{
							locationId        : 'gid://shopify/Location/18620514363',
							availableQuantity : product.inventory,
						},
					],
				},
			],
		};

		if (isUpdate) {
			input.id = product.graphqlAdminId;

			input.images = this.parseShopifyImageInput(product);
		}

		const metafields = this.parseProductMetafields(product, isUpdate);

		if (metafields?.length) {
			input.metafields = metafields;
		}

		return input;
	}

	/**
	 * @param firestoreData: Firestore data
	 */
	private parseShopifyImageInput(product : Product) : ShopifyProductImageInput[] {
		return (product.images ?? [])
			.filter(image => image.src && !image.detachedFromShopify)
			.map(image => new ShopifyProductImageInput(image));
	}

	/**
	 * @param firestoreData: Firestore data
	 */
	private parseProductMetafields(product : Product, isUpdate : boolean = false) : ProductMetafield[] {
		const metafields = isUpdate
			? product.metafields ?? []
			: this.parseNewProductMetafields(product);

		return metafields
			.map(metafield => new ShopifyMetafieldInput(metafield) as ProductMetafield);
	}

	private parseNewProductMetafields(product : Product) : ProductMetafield[] {
		const metafields = product.metafields ?? [];

		metafields.push({
			key       : 'firestoreId',
			namespace : 'sync',
			valueType : 'STRING',
			value     : product.id,
		});

		return metafields;
	}

	/**
	 * A static function that parses the data returned from Shopify and
	 * determines if it needs to be saved back to Firestore
	 * @param shopifyResponse: the raw info returned from the request to Shopify
	 * @param product: the product info returned from the create or update mutation into Firestore
	 * @param isCreate: whether this is for a create or update action
	 * Returns: a custom payload with updated data
	 * and info about whether an update is needed
	 */
	private parseShopifyResponse(shopifyResponse : any, product : Product, isCreate : boolean = true) : { updates : AnyMap, isFirestoreUpdateNeeded : boolean } {
		let didParse   : boolean = false;
		let isUpToDate : boolean = false;
		let updates    : AnyMap;

		if (shopifyResponse?.id) {
			didParse = true;

			const { id, handle, metafields, images } = shopifyResponse;
			const metafieldMap : ProductMetafieldMap = {};

			Helper.flattenEdgeNodeArray(metafields)
				.forEach((metafield : ProductMetafield) => {
					metafieldMap[metafield.key] = metafield;
				});

			updates = {
				metafieldMap,
				metafields : Object.values(metafieldMap),
			};

			if (isCreate) {
				updates.graphqlAdminId = id;
				updates.handle = handle;

				updates.id = product.id;
			} else {
				// check to see if there's a mismatch between metafields in Firestore & Shopify
				// and if all Firestore metafields have IDs
				const areMetafieldsUpToDate = updates.metafields.length === product.metafields?.length
					&& updates.metafields.every((metafield : ProductMetafield) =>
						product.metafieldMap?.[metafield.key]?.id
					);

				const mergedImages : ProductImage[] = this.mergeFirestoreAndShopifyImages(product.images, images);
				const areImagesUpToDate = Helper.areEqual(product.images, mergedImages);

				if (!areImagesUpToDate) {
					updates.images = mergedImages;
				}

				isUpToDate = areMetafieldsUpToDate && areImagesUpToDate;
			}
		}

		return {
			updates,
			isFirestoreUpdateNeeded : didParse && (isCreate || !isUpToDate),
		};
	}

	/**
	 * A static function that coerces images returned from Shopify into ProductImages,
	 * then merges the images with the previous ProductImages saved in Firestore
	 * @param firestoreImages: the ProductImage array saved in Firestore
	 * @param shopifyRawImages: the raw image object returned from Shopify
	 * Returns: ProductImage array of merged image data
	 */
	private mergeFirestoreAndShopifyImages(firestoreImages : ProductImage[] = [], shopifyRawImages : any) : ProductImage[] {
		const shopifyImages : ProductImage[] = Helper.flattenEdgeNodeArray(shopifyRawImages)
			.map(image => Helper.deepCopy(new ProductImage(image)));

		return new Product()
			.mergeProductImages(firestoreImages, shopifyImages);
	}







	/*
	 * Read Methods - old
	 * pull from Shopify
	 */
	getCollectionProductsOld(
		handle     : string,
		withPrice  : boolean = false,
		first?     : number,
		increment  : number = 50,
		throttle   : number = 250,
	) : Observable<ProductOld[]> {
		return this.shopifyGraphql.getCollectionProducts(
			handle,
			withPrice,
			first,
			increment,
			throttle,
		).pipe(
			// tap(data => console.debug(data)),
			map(data => Helper.get(data, ['data', 'collectionByHandle', 'products', 'edges'])),
			map((data : any[]) => Array.isArray(data) && data.length
				? this.parseProductsFromShopify(data)
				: []
			),
			// tap(data => console.debug(data)),
		);
	}

	getCollectionProductsCustomFields(handle : string, increment : number = 50, throttle : number = 250, customFields : string, incremental  : boolean = true) : Observable<ProductOld[]> {
		return this.shopifyGraphql.getCollectionProductsCustomFields(handle, increment, throttle, customFields, incremental).pipe(
			// tap(data => console.debug(data)),
			map(data => Helper.get(data, ['data', 'collectionByHandle', 'products', 'edges'])),
			map((data : any[]) => Array.isArray(data) && data.length
				? this.parseProductsFromShopify(data)
				: []
			),
			// tap(data => console.debug(data)),
		);
	}

	getCollectionProductsWithImages(handle : string, increment : number = 50, throttle : number = 250) : Observable<ProductOld[]> {
		return this.getCollectionProductsCustomFields(handle, increment, throttle, `
			title
			images(first: 15) {
				edges {
					node {
						id
						transformedSrc
					}
				}
			}
			variants(first: 5) {
				edges {
					node {
						id
						sku
					}
				}
			}
		`);
	}

	adminAllFeed(first? : number) : Observable<ProductOld[]> {
		return combineLatest(
			this.query$,
			this.hideSoldOut$,
		).pipe(
			// tap(data => console.debug(data)),
			debounceTime(750),
			tap(() => this.toggleLoading(true)),
			switchMap(([ query, hideSoldOut ]) => {
				const hasQuery : boolean = query && !!query.trim();

				if (!hasQuery) {
					return of(null);
				}

				const inventoryQuery : string = 'inventory_total:>0';
				const sortBy         : string = hasQuery ? 'RELEVANCE' : 'UPDATED_AT';

				if (hideSoldOut) {
					query += ` AND ${ inventoryQuery }`;
				}

				return this.shopifyGraphql.getProductsByQuery(
					query,
					sortBy,
					first,
					hasQuery,
				).pipe(
					// tap(data => console.debug(data)),
					take(1),
					map(data => Helper.get(data, ['data', 'products', 'edges'])),
					// tap(data => console.debug(data)),
				);
			}),
			// tap(data => console.debug(data)),
			map((data : any[]) => Array.isArray(data) && data.length
				? this.parseProductsFromShopify(data)
				: []
			),
			// tap(data => console.debug(data)),
			tap(() => this.toggleLoading(false)),
			// tap(data => console.debug(data)),
		);
	}

	getByIdFromShopify(id : string, customInfo : boolean = false) : Observable<ProductOld> {
		return this.shopifyGraphql.getProductByID(id, customInfo).pipe(
			// tap(data => console.debug(data)),
			map(data => Helper.get(data, ['data', 'product'])),
			map(data => data ? data : { noResults : true }),
			// tap(data => console.debug(data)),
		);
	}

	getInventoryLevelIdByVariantId(variantId : string) : Observable<string> {
		return this.shopifyGraphql.getInventoryLevelIdByVariantId(variantId).pipe(
			take(1),
			map(data => this.getInventoryInfoFromVariant(data?.data?.productVariant)?.inventoryLevelId),
		);
	}

	private logNoResults(data : any) : void {
		if (data.noResults) {
			this.log.error(EventIssuer.ProductService, {
				subject      : 'product service',
				eventContext : EventContext.CantGetProduct,
				details      : data,
			});
		}
	}

	private getVariantId(id : string) : Observable<string> {
		return this.shopifyGraphql.getProductVariants(id).pipe(
			// tap(data => console.debug(data)),
			map(data => Helper.get(data, ['data', 'product', 'variants', 'edges', 0, 'node', 'id'])),
			// tap(data => console.debug(data)),
		);
	}

	getImagesFromShopify(id : string) : Observable<any> {
		return this.shopifyGraphql.getProductImages(id).pipe(
			// tap(data => console.debug(data)),
			map(data => {
				const images = data?.data?.product?.images;

				return images
					? this.parseImagesFromShopify(images)
					: null;
			}),
			// tap(data => console.debug(data)),
		);
	}

	getNewFields(listingType : ListingType) : Observable<NewBasicProductFieldGrouping | NewDetailedProductFieldGrouping | NewWholesaleProductFieldGrouping> {
		let productFieldGrouping : NewBasicProductFieldGrouping | NewDetailedProductFieldGrouping | NewWholesaleProductFieldGrouping;

		if (listingType === 'basic')
			productFieldGrouping = NewBasicProductFields();
		else if (listingType === 'wholesale')
			productFieldGrouping = NewWholesaleProductFields();
		else
			productFieldGrouping = NewDetailedProductFields();

		return listingType === 'basic'
			? of(productFieldGrouping)
			: of(productFieldGrouping).pipe(
				switchMap(this.addStoresToShape.bind(this)),
				take(1),
			);
	}

	private addStoresToShape(inputMap : ProductFieldGrouping) : Observable<ProductFieldGrouping> {
		const vendorOptions = inputMap.groups.main.fields.vendor.inputOptions;

		if (vendorOptions.options.length) {
			return of(inputMap);
		} else {
			return this.store.getSelectOptions().pipe(
				take(1),
				tap(options => vendorOptions.options = options),
				map(() => inputMap),
			);
		}
	}

	getFieldGroupMap() : StringMap {
		const productFields : ProductFieldGrouping = ProductFields(),
			map : StringMap = {};

		for (const group in productFields.groups) {
			for (const field in productFields.groups[group].fields)
				map[field] = group;
		}

		return map;
	}

	getCollectionProductCountFromShopify(handle : string) : Observable<any> {
		return this.shopifyGraphql.getCollectionByHandleProductCount(handle).pipe(
			take(1),
			// tap(data => console.debug(data)),
			map(data => Helper.get(data, ['data', 'collectionByHandle', 'productsCount'])),
			// tap(data => console.debug(data)),
		);
	}

	private toggleLoading(forceState? : boolean) : void {
		this.loading = forceState == null ? !this.loading : forceState;
	}

	private parseProductsFromShopify(products : any[], sortBy? : string) : ProductOld[] {
		products = products.map(this.parseProductFromShopify.bind(this));

		if (sortBy) {
			return sortedUniqBy(_sortBy(products, [ sortBy ]), 'id');
		} else {
			return uniqBy(products, 'id');
		}
	}

	private parseProductFromShopify(product : any) : ProductOld {
		if (product.node && !product.id) {
			product = product.node;
		}

		product = Helper.deepCopy(product);

		product.images = this.parseImagesFromShopify(product.images);

		this.parseVariants(product);
		this.parseMisc(product);

		return product;
	}

	private parseVariants(product) : void {
		const defaultVariant : any = Helper.flattenEdgeNodeArray(product.variants)?.[0];

		if (!defaultVariant)
			return;

		const variantID : string = defaultVariant?.id;
		const inventoryInfo = this.getInventoryInfoFromVariant(defaultVariant);

		if (variantID)
			product.variantID = variantID;

		if (inventoryInfo.inventoryLevelId)
			product.inventoryLevelId = inventoryInfo.inventoryLevelId;

		if (inventoryInfo.inventory != null)
			product.inventory = inventoryInfo.inventory;

		for (const prop of ['sku', 'price', 'weight']) {
			const value = defaultVariant?.[ prop ];

			if (value && product[prop] == null)
				product[prop] = value;
		}
	}

	private getInventoryInfoFromVariant(variant : any) : { inventoryLevelId : string, inventory? : number } {
		const defaultInventory : any = Helper.flattenEdgeNodeArray(variant?.inventoryItem?.inventoryLevels)?.[0];

		const inventoryLevelId : string = defaultInventory?.id ?? null;
		const inventory        : number = defaultInventory?.available ?? null;

		return {
			inventoryLevelId,
			inventory,
		};
	}

	private parseMisc(product) : void {
		if (product.totalInventory != null) {
			product.soldOut = (product.inventory ?? product.totalInventory) === 0;
		}
	}

	private getProductFields(productWithoutFields : Product) : ProductWithFields {
		if (!productWithoutFields) {
			return null;
		}

		const product = {
			...productWithoutFields,
			fields : ProductFields(),
		};

		this.parseFields(product);

		return product;
	}

	private parseFields(product : ProductWithFields) : void {
		for (const groupKey in product.fields.groups) {
			for (const fieldKey in product.fields.groups[groupKey].fields) {
				this.parseField(product, groupKey, fieldKey);
			}
		}
	}

	private parseField(product : ProductWithFields, groupKey : string, fieldKey : string) : void {
		const field = product.fields.groups[groupKey].fields[fieldKey];
		const fieldValue = product[field.key] ?? null;

		field.value = fieldValue;
	}


	/*
	 * Write Methods
	 * push to Shopify
	 */
	reorderSizes(sizes : string[]) : string[] {
		if (!sizes)
			return;

		const sizeList : string[] = GetSizeList();

		return sizes.sort((a, b) => {
			const A = sizeList.indexOf(a),
				B = sizeList.indexOf(b);

			if (A < B) {
				return -1;
			} else if (A > B) {
				return 1;
			}

			// values must be equal
			return 0;
		});
	}

	getDisplaySizeFromSize(sizeTags : string[]) : string {
		if (!sizeTags) {
			return;
		}

		// ensure sizes are in smallest to biggest order
		this.reorderSizes(sizeTags);

		// map size tags to display sizes
		const displayTags : string[] = sizeTags.map(this.parseSizeTagToDisplaySize.bind(this)),
			tagLength : number = displayTags.length;

		let displaySize : string;

		if (tagLength === 1) {
			displaySize = displayTags[0];
		} else if (tagLength > 1) {
			displaySize = `${ displayTags[0] } - ${ displayTags[tagLength - 1] }`;
		}

		return displaySize;
	}

	/**
	 *  resolve a size tag to its corresponding display size
	 *  if no display size is found return the original tag
	 *  e.g.
	 *      L (10 - 12) -> large
	 *      9 -> 9
	**/
	private parseSizeTagToDisplaySize(tag : string) : string {
		if (!this.cache.sizeTagToDisplaySize)
			this.cache['sizeTagToDisplaySize'] = SizeTagToDisplaySize();

		return this.cache.sizeTagToDisplaySize[tag] ?? tag;
	}

	getListFromOptionsArray(arr : string[], useAmpersand : boolean = true) : string {
		const length : number = !!arr && Array.isArray(arr) ? arr.length : 0;

		let value : string;

		if (length === 1)
			value = arr[0];
		else if (length > 1)
			value = `${ arr.slice(0, -1).join(', ') } ${ useAmpersand ? '&' : 'and' } ${ arr[length - 1] }`;

		return value;
	}

	updateShopify(id : string, updates : any, handle? : string, returnImages? : boolean) : Observable<any> {
		// Shopify lacks a dev sandbox
		// only save to Shopify in prod
		if (!environment.production) {
			return of(null);
		}

		const sanitizedInput = this.sanitizeShopifyInput(updates);

		return this.shopifyGraphql.updateProduct(id, sanitizedInput, handle, returnImages).pipe(
			take(1),
			// tap(data => console.debug(data)),
			map(data => Helper.get(data, ['data', 'productUpdate', 'product'])),
		);
	}

	private sanitizeShopifyInput(input : any) : any {
		if (input.tags) {
			input.tags = this.sanitizeTags(input.tags);
		}

		if (input.images) {
			input.images = this.sanitizeImages(input.images);
		}

		return input;
	}

	private sanitizeTags(tags : string[]) : string[] {
		const regex : RegExp = /[+=.,]/g;
		const replacer = (match) : string => Helper.encodeHTML(match, true);

		return tags.map(tag => tag.replace(regex, replacer));
	}

	private sanitizeImages(images : ProductImage[] | ImageValue[]) : ImageValue[] {
		return (images as any[])
			.map(image => !!image.id || !!image.graphqlAdminId
				?   {
					altText : image.altText ?? null,
					id      : image.id ?? image.graphqlAdminId,
				}
				:   {
					altText : image.altText ?? null,
					src     : image.src ? this.imgix.url(image.src) : null,
				}
			);
	}

	updateImage(productId : string, image : ProductImage) : Observable<any> {
		// Shopify lacks a dev sandbox
		// only save to Shopify in prod
		if (!environment.production) {
			return of(null);
		}

		return this.shopifyGraphql.updateProductImage(
			productId,
			image.graphqlAdminId,
			this.imgix.url(image.src),
			image.altText
		).pipe(
			take(1),
			map(data => Helper.get(data, ['data', 'productImageUpdate', 'image'])),
		);
	}

	updateVariantByProductId(id : string, updates, handle? : string) : Observable<any> {
		return this.getVariantId(id).pipe(
			switchMap(variantId => this.updateVariant(variantId, updates, handle)),
		);
	}

	private updateVariant(id : string, updates, handle? : string) : Observable<any> {
		// Shopify lacks a dev sandbox
		// only save to Shopify in prod
		if (!environment.production) {
			return of(null);
		}

		return this.shopifyGraphql.updateProductVariant(id, updates, handle).pipe(
			// tap(data => console.debug(data)),
		);
	}

	updateStructuredTags(id : string, rawPrefix : string, newTags : string[], handle? : string) : Observable<any> {
		return this.getByIdFromShopify(id).pipe(
			take(1),
			switchMap(product => {
				if (product.noResults)
					return of(false);

				const { prefix, regex } = Helper.getTagPrefixAndRegex(rawPrefix),
					currentTags : string[] = product.tags.filter(tag => !regex.test(tag));

				if (!regex.test(newTags[0]))
					newTags = newTags.map(tag => prefix + tag);

				const tags : string[] = currentTags.concat(newTags);

				return this.updateShopify(id, { tags }, handle);
			}),
			take(1),
		);
	}

	createInShopify(input : ShopifyProductInput) : Observable<any> {
		// Shopify lacks a dev sandbox
		// only save to Shopify in prod
		if (!environment.production) {
			return of({
				product : {},
				errors  : [],
			});
		}

		const sanitizedInput = this.sanitizeShopifyInput(input);

		return this.shopifyGraphql.createProduct(sanitizedInput).pipe(
			take(1),
			// tap(data => console.debug(data)),
			map(data => {
				const productCreate = data?.data?.productCreate;
				const product = productCreate?.product;
				const errors = productCreate?.userErrors;

				if (!product && errors?.length) {
					for (const error of errors) {
						this.log.error(EventIssuer.ProductService, {
							sku          : input?.variants?.[0]?.sku,
							handle       : product?.handle,
							subject      : error.message,
							eventContext : EventContext.NewProduct,
							details      : error,
						});
					}
				}

				if (product?.images) {
					product.images = this.parseImagesFromShopify(product.images);
				}

				return {
					product,
					errors,
				};
			}),
		);
	}

	private parseImagesFromShopify(rawImages : any) : ImageValue[] {
		return Helper.flattenEdgeNodeArray(rawImages)
			.map(this.parseImageFromShopify);
	}

	private parseImageFromShopify(image : any) : ImageValue {
		return {
			id      : image.id,
			src     : image.transformedSrc,
			altText : image.altText ?? null,
		};
	}

	updateInventoryQuantity(inventoryLevelId : string, inventoryAdjust : number) : Observable<any> {
		// Shopify lacks a dev sandbox
		// only save to Shopify in prod
		if (!environment.production) {
			return of(null);
		}

		return this.shopifyGraphql.updateProductInventoryQuantity(inventoryLevelId, inventoryAdjust);
	}

	upsertMetafield(id : string, metafield : Metafield, updates : any = {}) : Observable<Metafield> {
		// Shopify lacks a dev sandbox
		// only save to Shopify in prod
		if (!environment.production) {
			return of(null);
		}

		const metafieldInput : MetafieldInput = this.getMetafieldInput({
			...metafield,
			...updates,
		});

		return this.shopifyGraphql.upsertProductMetafield(id, metafieldInput).pipe(
			take(1),
			map(item => item ? this.parseMetaFieldValue({ ...metafield, ...item }) : null),
			// tap(data => console.debug(data)),
		);
	}

	private getMetafieldInput(metafield : Metafield) : MetafieldInput {
		const value : string = typeof metafield.value === 'string' ? metafield.value : JSON.stringify(metafield.value);

		return {
			value,
			id          : metafield.id || null,
			key         : metafield.key || null,
			namespace   : metafield.namespace || null,
			valueType   : metafield.valueType || null,
			description : metafield.description || null,
		};
	}

	private parseMetaFieldValue(metafield : Metafield) : Metafield {
		if (metafield.valueType === 'JSON_STRING')
			metafield.value = JSON.parse(metafield.value as string ?? null);

		return metafield;
	}

	deleteMetafield(metafield : Metafield) : Observable<Metafield> {
		// Shopify lacks a dev sandbox
		// only save to Shopify in prod
		if (!environment.production) {
			return of(null);
		}

		return this.shopifyGraphql.deleteMetafield(metafield.id).pipe(
			take(1),
			map(() => {
				delete metafield.id;
				delete metafield.value;

				return metafield;
			}),
		);
	}

	unpublishFromShopify(shopifyId : string) : Observable<any> {
		// Shopify lacks a dev sandbox
		// only save to Shopify in prod
		if (!environment.production) {
			return of(null);
		}

		return this.shopifyGraphql.unpublish(shopifyId).pipe(
			take(1),
			// tap(data => console.debug(data)),
		);
	}

	publishToShopify(shopifyId : string) : Observable<any> {
		// Shopify lacks a dev sandbox
		// only save to Shopify in prod
		if (!environment.production) {
			return of(null);
		}

		return this.shopifyGraphql.publish(shopifyId).pipe(
			take(1),
			// tap(data => console.debug(data)),
		);
	}

	getPostPublishCheckWarnings(product : Product | ProductWithFields) : PostPublishWarning[] {
		return this.postPublishWarningChecks
			.map(check => check(product) as PostPublishWarning)
			.filter((warning : PostPublishWarning) => warning);
	}
}
