import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { take, tap, map, switchMap, catchError, last } from 'rxjs/operators';
import * as firebase from 'firebase/compat/app';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { ParsedPath } from '@shopthrilling/thrilling-shared';

import { LogService } from '../../services/log/log.service';
import { HelperService as Helper } from '../../services/helper/helper.service';
import { EventIssuer } from '../../constants/EventIssuer';
import { EventContext } from '../../constants/EventContext';
import { firebaseConfig } from '../../../environments/environment';


interface UploadTask {
	snapshotChanges()   : Observable<any>;
	percentageChanges() : Observable<number>;
	getDownloadURL()    : Observable<string>;
}


@Injectable({
	providedIn : 'root',
})
export class FileStorageService {
	constructor(
		private storage : AngularFireStorage,
		private log     : LogService,
	) {}


	uploadFile(file : File, filePath? : string | string[], addUniqueHash : boolean = true) : UploadTask {
		let parsedFilePath : string = this.getFilePath(file.name, filePath);

		if (addUniqueHash)
			parsedFilePath = this.addUniqueHash(parsedFilePath);

		return this.parseUploadTask(parsedFilePath, file);
	}

	uploadBlob(blob : Blob, filename : string, filePath? : string | string[], addUniqueHash : boolean = true) : UploadTask {
		const file : File = Helper.getFileFromBlob(blob, filename);

		return this.uploadFile(file, filePath, addUniqueHash);
	}

	private parseUploadTask(filePath : string, data : string | File) : UploadTask {
		const fileRef = this.storage.ref(filePath),
			task : AngularFireUploadTask = typeof data === 'string' ? fileRef.putString(data, 'data_url') : fileRef.put(data),
			getDownloadURL = () : Observable<string> => task.snapshotChanges().pipe(
				last(),
				map(() => Helper.getCloudStorageDownloadUrl(firebaseConfig.storageBucket, filePath)),
				tap(details => {
					this.log.create(EventIssuer.FileStorageService, {
						subject      : `file "${ filePath }"`,
						eventContext : EventContext.NewFile,
						details,
					});
				}),
			);

		return {
			snapshotChanges   : task.snapshotChanges,
			percentageChanges : task.percentageChanges,
			getDownloadURL,
		};
	}

	uploadDataUrl(dataUrl : string, filename : string, filePath? : string | string[], addUniqueHash : boolean = true) : UploadTask {
		if (!Helper.parsePath(filename).fileExtension)
			filename = `${ filename }.${ dataUrl.replace(/^data:\w+\/(\w+);.*$/, '$1') }`;

		let parsedFilePath : string = this.getFilePath(filename, filePath);

		if (addUniqueHash)
			parsedFilePath = this.addUniqueHash(parsedFilePath);

		return this.parseUploadTask(parsedFilePath, dataUrl);
	}

	delete(filename : string) : Observable<boolean> {
		const fileRef = this.storage.ref(filename);

		return fileRef.delete()
			.pipe(
				map(data => ({
					success : true,
					data,
				})),
				catchError(data => of({
					success : false,
					data,
				})),
				map(payload => {
					this.log[payload.success ? 'delete' : 'error'](
						EventIssuer.FileStorageService,
						{
							subject : `${ payload.success ? '' : 'unable to delete ' }file "${ filename }"`,
							details : payload.data,
						},
					);

					return payload.success;
				}),
			);
	}

	exists(filename : string) : Observable<boolean> {
		const fileRef = this.storage.ref(filename);

		return fileRef.getDownloadURL()
			.pipe(
				take(1),
				map(data => !!data),
				catchError(error => of(!(error && error.code && error.code === 'storage/object-not-found'))),
			);
	}

	private getFilePath(filenameAndType : string, filePath : string | string[] = []) : string {
		if (!Helper.parsePath(filenameAndType).fileExtension)
			throw new Error(`Argument "filenameAndType" was passed without a file type: ${ filenameAndType }`);

		if (typeof filePath === 'string')
			filePath = filePath.split('/');

		filePath.push(Helper.getFilename(filenameAndType));

		return filePath.join('/');
	}

	private addUniqueHash(filePath : string) : string {
		const parsedPath : ParsedPath = Helper.parsePath(filePath);
		const filenameSansExternalHash : string = parsedPath.filename.replace(parsedPath.externalHash, '');

		return [
			parsedPath.path,
			filenameSansExternalHash,
			`__${ Helper.createId() }`,
			parsedPath.externalHash,
			parsedPath.fileExtension,
		]
			.join('');
	}

	async reSaveFileByUrl(url : string) : Promise<string> {
		let blob : Blob;

		try {
			blob = await Helper.getBlobFromUrl(url);
		} catch (error) {
			console.error(error);
		}

		if (!blob)
			return;

		return this.uploadBlob(
			blob,
			Helper.getFilename(url),
		)
			.getDownloadURL()
			.toPromise();
	}

	async reSaveFileByDataUrl(dataUrl : string, filename : string) : Promise<string> {
		return this.uploadDataUrl(
			dataUrl,
			filename,
		)
			.getDownloadURL()
			.toPromise();
	}
}
