import { Component, OnInit, OnDestroy, OnChanges, Input, Output, EventEmitter, SimpleChanges, SimpleChange, ViewChild } from '@angular/core';
import { ToastController, AlertController, ModalController, IonModal } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProductImage, ImageSource, ListingType } from '@shopthrilling/thrilling-shared';
import { ImageCropperComponent } from 'ngx-image-cropper';

import { HelperService as Helper } from '../../services/helper/helper.service';
import { LogService } from '../../services/log/log.service';
import { ProductImagesService } from '../../services/product-images/product-images.service';
import { ImgixService } from '../../services/imgix/imgix.service';
import { BooleanMap } from '../../types/basics';
import { ImageValue, ProductImages } from '../../types/input-fields';
import { ProductImagesChange, ProductImagesChangeEvent } from '../../types/product';
import { ZoomMenuOption } from '../../components/zoom-image/zoom-image.component';
import { EventIssuer } from '../../constants/EventIssuer';
import { EventContext } from '../../constants/EventContext';

import { ImageVersionsModalComponent } from './image-versions-modal/image-versions-modal.component';


export type GroupName =
	| 'currentImages'
	| 'selectedImages'
	| 'deletedImages'
	| 'uploadFailures'
	;

type GroupBooleanMap = {
	[ key in GroupName ]? : boolean;
}

type Requirement = {
	description : string;
	check()     : boolean;
};

type RequirementGroup =
	| 'Required'
	| 'Optional'
	;

type RequirementListMap = {
	[ key in RequirementGroup ] : Requirement[];
};

type CropInfo = {
	index?       : number;
	groupName?   : GroupName;
	blob?        : Blob | File;
	saveClicked? : boolean;
};


@Component({
	selector    : 'app-product-images',
	templateUrl : './product-images.component.html',
	styleUrls   : ['./product-images.component.scss'],
})
export class ProductImagesComponent implements OnInit, OnDestroy, OnChanges {
	@ViewChild('cropModal') cropModal : IonModal;
	@ViewChild(ImageCropperComponent) imageCropper : ImageCropperComponent;

	@Input() field           : ProductImages;
	@Input() defaultFilename : string;
	@Input() listingType     : ListingType;
	@Input() isAdmin         : boolean = false;
	@Input() resetSelected$  : Subject<boolean>;
	@Input() savingSelected$ : Subject<boolean>;

	@Output() update = new EventEmitter<ProductImagesChange>();

	private stop$           : Subject<boolean> = new Subject<boolean>();
	private currentValue    : ProductImage[];
	private loadingMap      : BooleanMap = {};
	private zoomMenuOptions : ZoomMenuOption[] = [
		{
			title        : 'Crop',
			icon         : 'crop',
			dismissModal : true,
			handler      : (src, meta) => this.showCropModal(meta.groupName, meta.index),
		},
		{
			title        : 'Rotate Left',
			icon         : 'arrow-undo',
			dismissModal : true,
			handler      : (src, meta) => this.rotate(meta.groupName, meta.index, false),
		},
		{
			title        : 'Rotate Right',
			icon         : 'arrow-redo',
			dismissModal : true,
			handler      : (src, meta) => this.rotate(meta.groupName, meta.index, true),
		},
	];

	helper            = Helper;
	currentImages     : ProductImage[] = [];
	selectedImages    : ProductImage[] = [];
	deletedImages     : ProductImage[] = [];
	uploadFailures    : ProductImage[] = [];
	hasChanged        : GroupBooleanMap = {};
	versionsModal     : HTMLIonModalElement;
	cropInfo          : CropInfo = {};
	requirementGroups : RequirementListMap = {
		Required : [],
		Optional : [],
	};
	collapseMap       : GroupBooleanMap = {
		deletedImages  : true,
		uploadFailures : true,
	};


	constructor(
		private toast        : ToastController,
		private alert        : AlertController,
		private log          : LogService,
		public productImages : ProductImagesService,
		public imgix         : ImgixService,
		public modal         : ModalController,
	) {}


	ngOnInit() : void {
		this.saveFieldValue();

		this.initRequirements();

		this.initSavingSelected();
		this.initResetSelected();
	}

	ngOnDestroy() : void {
		this.stop$.next(true);
		this.stop$.unsubscribe();
	}

	ngOnChanges(changes: SimpleChanges) : void {
		if (changes.field && changes.field.firstChange === false) {
			this.saveFieldValue(changes.field.currentValue.value);
		}

		if (changes.listingType && changes.listingType.firstChange === false) {
			this.initRequirements();
		}
	}

	private initRequirements() : void {
		this.requirementGroups.Required.push(
			{
				description : `at least ${ this.imagesRequired() } image${ this.imagesRequired() === 1 ? '' : 's' }`,
				check       : () : boolean => this.getCombinedImages().length >= this.imagesRequired(),
			},
			{
				description : 'the 1st image shows the front of the item',
				check       : () : boolean => this.getActiveGroup().length >= 1,
			},
		);

		this.requirementGroups[this.imageEditRequired() ? 'Required' : 'Optional'].push(
			{
				description : 'the front image is edited',
				check       : () : boolean => this.productImages.isEditedOrPending(this.getActiveGroup()[0]),
			},
		);

		if (!this.isImported()) {
			this.requirementGroups.Required.push(
				{
					description : 'the 2nd image shows the back of the item',
					check       : () : boolean => this.getActiveGroup().length >= 2,
				},
			);
		}

		this.requirementGroups.Optional.push(
			{
				description : 'the back image is edited',
				check       : () : boolean => this.productImages.isEditedOrPending(this.getActiveGroup()[1]),
			},
			{
				description : 'add detail images (e.g. brand or union labels, fabric close-up, zippers, flaws)',
				check       : () : boolean => this.getCombinedImages().length > this.imagesRequired(),
			},
		);
	}

	private isWholesale() : boolean {
		return this.listingType === 'wholesale';
	}

	private isImported() : boolean {
		return this.listingType === 'imported';
	}

	imagesRequired() : 1 | 2 {
		return this.isImported() ? 1 : 2;
	}

	private imageEditRequired() : boolean {
		return !this.isWholesale();
	}

	private saveFieldValue(value? : ProductImage[]) : void {
		this.currentValue = Helper.deepCopy(value ?? (this.field && this.field.value) ?? [])
			.filter(image => !!image.src);

		this.updateCurrentImages();
	}

	private updateCurrentImages() : void {
		const groups : { [ key in GroupName ]? : ProductImage[] } = {
			currentImages  : [],
			deletedImages  : [],
			uploadFailures : [],
		};

		for (const image of this.currentValue) {
			if (image.detachedFromShopify) {
				if (image.deleted == null && image.failedToUpload == null) {
					image[image.graphqlAdminId ? 'deleted' : 'failedToUpload'] = true;
				}

				groups[image.deleted ? 'deletedImages' : 'uploadFailures'].push(image);
			} else {
				groups.currentImages.push(image);
			}
		}

		for (const groupName in groups) {
			this[groupName] = groups[groupName];
		}
	}

	private async showToast(message : string, isError : boolean = false, isWarning : boolean = false, duration : number = 3000) : Promise<void> {
		const toast = await this.toast.create({
			message,
			duration,
			color : isError ? 'danger' : isWarning ? 'warning' : 'dark',
		});

		toast.present();
	}

	private getCombinedImages() : ProductImage[] {
		return this.currentImages.concat(this.selectedImages);
	}

	private initSavingSelected() : void {
		if (this.savingSelected$ != null) {
			this.savingSelected$.pipe(
				takeUntil(this.stop$),
			)
				.subscribe(this.savingSelected.bind(this));
		}
	}

	private savingSelected() : void {
		this.selectedImages.forEach(image => this.loadingMap[image.src] = true);
	}

	private initResetSelected() : void {
		if (this.resetSelected$ != null) {
			this.resetSelected$.pipe(
				takeUntil(this.stop$),
			)
				.subscribe(this.resetSelected.bind(this));
		}
	}

	private resetSelected() : void {
		this.updateGroup('reset', 'selectedImages', [], false);

		this.updateCurrentImages();

		this.loadingMap = {};
	}

	hasCurrentImages() : boolean {
		return !!this.currentImages.length;
	}

	hasMultipleGroups() : boolean {
		const populatedGroups = ['currentImages', 'selectedImages', 'deletedImages', 'uploadFailures']
			.map(groupName => !!this[groupName].length)
			.filter(populated => populated);

		return populatedGroups.length > 1;
	}

	hasInactiveGroups() : boolean {
		const inactiveGroups = ['deletedImages', 'uploadFailures']
			.map(groupName => !!this[groupName].length)
			.filter(populated => populated);

		return !!inactiveGroups.length;
	}

	showFieldTitle() : boolean {
		return this.listingType !== 'detailed';
	}

	showRequirementGroup(group : RequirementGroup) : boolean {
		// don't show optional requirement group on imported products
		return !(group === 'Optional' && this.listingType === 'imported');
	}

	isLoading(groupName : GroupName, image : ProductImage) : boolean {
		return !this.isInactiveGroup(groupName) && this.loadingMap[image.src] === true;
	}

	private getActiveGroup() : ProductImage[] {
		return this.hasCurrentImages() ? this.currentImages : this.selectedImages;
	}

	onUpload(images : ImageValue[]) : void {
		const productImages : ProductImage[] = images
			.map(image => new ProductImage({
				...image,
				uneditedSrc   : image.src,
				currentSource : 'unedited',
			}));

		this.updateGroup('add', 'selectedImages', this.selectedImages.concat(productImages));
	}

	reAdd(groupName : GroupName, index : number) : void {
		const newIndex = this.selectedImages.length;
		const group = this.selectedImages.concat(this[groupName][index]);

		this.doDelete(groupName, index, false);

		// When an image is deleted, the value of src is stale.
		// So, we need to update it to the current source.
		const currentSrc = group[newIndex][`${ group[newIndex].currentSource }Src`];

		if (currentSrc) {
			group[newIndex].src = currentSrc;
		}

		// remove graphqlAdminId and detachedFromShopify to allow image to be resaved in Shopify
		delete group[newIndex].graphqlAdminId;
		delete group[newIndex].detachedFromShopify;

		// add temporary reAdding flag for local processing
		group[newIndex]['reAdding'] = true;

		this.updateGroup('reAdd', 'selectedImages', group);
	}

	private updateGroup(event : ProductImagesChangeEvent, groupName : GroupName, changes : ProductImage[], emit : boolean = true) : void {
		const productImagesChange : ProductImagesChange = {
			changes : {},
			event,
		};

		productImagesChange.changes[groupName] = new SimpleChange(
			this[groupName],
			changes,
			!this.hasChanged[groupName],
		);

		this[groupName] = changes;

		this.hasChanged[groupName] = true;

		if (emit) {
			this.update.emit(productImagesChange);
		}
	}

	isPrimaryGroup(groupName : GroupName) : boolean {
		return groupName === 'currentImages'
			|| (
				groupName === 'selectedImages'
				&& !this.hasCurrentImages()
			);
	}

	isInactiveGroup(groupName : GroupName) : boolean {
		return ['deletedImages', 'uploadFailures'].includes(groupName);
	}

	isDeletable(groupName : GroupName, index : number) : boolean {
		return this.isPrimaryGroup(groupName)
			? index >= this.imagesRequired()
			: !this.isInactiveGroup(groupName);
	}

	getGroupHeaderText(groupName : GroupName) : string {
		const groupMap = {
			currentImages  : 'Current Image',
			selectedImages : 'Selected Image',
			deletedImages  : 'Deleted Image',
			uploadFailures : 'Upload Failure',
		};

		return `${ groupMap[groupName] }${ this[groupName].length === 1 ? '' : 's' } (${ this[groupName].length })`;
	}

	isEditedOrPending(image : ProductImage) : boolean {
		return this.isImported() || this.isWholesale()
			? this.productImages.isEditedOrPending(image)
			: this.productImages.isEditedPendingOrCropped(image);
	}

	getEditedStatusColor(image : ProductImage) : string {
		if (this.productImages.isPending(image)) {
			return 'warning';
		} else if (this.isEditedOrPending(image)) {
			return 'success';
		} else {
			return 'medium';
		}
	}

	getZoomMenuOptions(groupName : GroupName, image : ProductImage) : ZoomMenuOption[] {
		if (this.isInactiveGroup(groupName) || this.productImages.isPending(image)) {
			return;
		}

		return this.zoomMenuOptions;
	}

	async showImageVersions(groupName : GroupName, index : number) : Promise<void> {
		this.versionsModal = await this.modal.create({
			component      : ImageVersionsModalComponent,
			cssClass       : 'image-versions-modal',
			componentProps : {
				groupName,
				index,
				parent : this,
			},
		});

		await this.versionsModal.present();

		await this.versionsModal.onDidDismiss();

		this.versionsModal = null;
	}

	async autoEdit(groupName : GroupName, index : number) : Promise<void> {
		const image : ProductImage = this[groupName][index];

		this.loadingMap[image.src] = true;

		if (image.autoEditedSrc) {
			this.postAutoEdit(groupName, index);

			return;
		}

		let editedSrc : string;

		try {
			editedSrc = await this.productImages.autoEdit(image.src);
		} catch (error) {
			const errorSansTrace : string = error.toString().replace('Error:', '').trim();

			this.log.error(EventIssuer.ProductImagesComponent, {
				eventContext : EventContext.ProductImage,
				details      : errorSansTrace,
			});

			return this.autoEditFailed(groupName, index, errorSansTrace);
		}

		this.postAutoEdit(groupName, index, editedSrc);
	}

	private async postAutoEdit(groupName : GroupName, index : number, editedSrc? : string) : Promise<void> {
		const imageCopy = Helper.deepCopy(this[groupName][index]);

		if (!imageCopy.autoEditedSrc && editedSrc) {
			imageCopy.autoEditedSrc = editedSrc;
		}

		await this.showAutoEditAlert(groupName, index, imageCopy);

		this.loadingMap[this[groupName][index].src] = false;
	}

	private async autoEditFailed(groupName : GroupName, index : number, error : string) : Promise<void> {
		const isForegroundIssue = error === 'unknown_foreground',
			imageCopy = Helper.deepCopy(this[groupName][index]);

		this.showToast(`Uh oh! ${ isForegroundIssue ? 'Image subject could not be determined' : 'An unexpected error occurred' }. Unable to automatically remove background.`, true, null, 5000);

		await this.showAutoEditAlert(groupName, index, imageCopy, false);

		this.loadingMap[this[groupName][index].src] = false;
	}

	private async showAutoEditAlert(groupName : GroupName, index : number, imageCopy : ProductImage, autoEditSuccess : boolean = true) : Promise<any> {
		const cssClass = 'secondary';

		const buttons : any[] = [{
			cssClass,
			text    : 'Cancel',
			role    : 'cancel',
			handler : () => this.postAutoEditDecision(groupName, index, imageCopy, 'cancel'),
		}];

		if (index === 0) {
			if (autoEditSuccess) {
				buttons.push({
					cssClass,
					text    : `Reject${ this.isAdmin ? ' - Edit from Original' : '' }`,
					handler : () => this.postAutoEditDecision(groupName, index, imageCopy, 'reject'),
				});

				if (this.isAdmin) {
					buttons.push({
						cssClass,
						text    : 'Reject - Edit from AutoEdit',
						handler : () => this.postAutoEditDecision(groupName, index, imageCopy, 'reject', true),
					});
				}
			} else {
				buttons.push({
					text    : 'Manually Edit',
					handler : () => this.postAutoEditDecision(groupName, index, imageCopy, 'reject'),
				});
			}
		}

		if (autoEditSuccess) {
			buttons.push({
				text    : 'Accept',
				handler : () => this.postAutoEditDecision(groupName, index, imageCopy, 'accept'),
			});
		}

		const alert = await this.alert.create({
			cssClass  : 'auto-edit-alert',
			header    : `AutoEdit${ autoEditSuccess ? 'ed Image' : ' Unavailable' }`,
			subHeader : autoEditSuccess ? 'Is this image okay?' : null,
			message   : `<img src="${ autoEditSuccess ? imageCopy.autoEditedSrc : imageCopy.src }">`,
			buttons,
		});

		await alert.present();

		return alert.onDidDismiss();
	}

	private postAutoEditDecision(groupName : GroupName, index : number, imageCopy : ProductImage, decision : 'accept' | 'cancel' | 'reject', editFromAutoEdit : boolean = false) : void {
		let event : ProductImagesChangeEvent;

		if (decision === 'accept') {
			return this.updateSrc(groupName, index, 'autoEdited', imageCopy);
		} else if (decision === 'cancel') {
			event = 'autoEdit';
		} else {
			event = 'edit';

			imageCopy.editRequestedAt = new Date().toISOString();

			imageCopy.editSource = editFromAutoEdit
				? 'autoEdited'
				: 'unedited';

			this.showToast('This image background will be manually removed. It should be ready within 48 hours.', null, null, 5000);
		}

		const group = Helper.deepCopy(this[groupName]);

		group[index] = imageCopy;

		this.updateGroup(event, groupName, group);
	}

	reorderImages(event : any, groupName : GroupName) : void {
		let group = Helper.deepCopy(this[groupName]);

		event.detail.complete(group);

		group = this.enforceEditedPositions(event, group);

		this.updateGroup('reorder', groupName, group);
	}

	private enforceEditedPositions(event : any, group : ProductImage[]) : ProductImage[] {
		const from : number = event.detail.from;
		const to   : number = event.detail.to;

		let auditIndex : number;

		if (from < 2 && to >= 2) {
			auditIndex = to;
		} else if (from >= 2 && to < 2) {
			auditIndex = 2;
		}

		if (auditIndex != null) {
			group[auditIndex] = this.coerceToUnedited(group[auditIndex]);
		}

		return group;
	}

	private coerceToUnedited(image : ProductImage) : ProductImage {
		if (this.productImages.isEditedOrPending(image)) {
			if (this.productImages.isEdited(image) && image.uneditedSrc) {
				image.currentSource = 'unedited';

				image.src = image.uneditedSrc;

				// remove graphqlAdminId to allow image to be resaved in Shopify
				delete image.graphqlAdminId;
			} else
				image.editRequestedAt = null;
		}

		return image;
	}

	delete(event : any, groupName : GroupName, index : number) : void {
		event.stopPropagation();

		this.showDeleteAlert(
			`this image (${ Helper.getFilename(this[groupName][index].src, false, false, false) })`,
			() => this.doDelete(groupName, index),
		);
	}

	private doDelete(groupName : GroupName, index : number, emit : boolean = true) : void {
		const group = Helper.deepCopy(this[groupName]);

		group.splice(index, 1);

		this.updateGroup('delete', groupName, group, emit);
	}

	private async showAlert(header : string, message : string, confirmHandler : Function) : Promise<void> {
		const alert = await this.alert.create({
			header,
			message,
			buttons : [
				{
					text     : 'Cancel',
					role     : 'cancel',
					cssClass : 'secondary',
				},
				{
					text    : 'Confirm',
					handler : confirmHandler.bind(this),
				},
			],
		});

		await alert.present();
	}

	private showDeleteAlert(deletee : string, confirmHandler : Function) : void {
		this.showAlert(
			'Confirm Delete',
			`Are you sure you want to delete ${ deletee }? There's no going back.`,
			confirmHandler
		);
	}

	updateSrc(groupName : GroupName, index : number, newSrc : ImageSource, imageCopy? : ProductImage) : void {
		const group = Helper.deepCopy(this[groupName]);

		group[index] = {
			...group[index],
			...imageCopy,
			currentSource : newSrc,
		};

		group[index].src = group[index][`${ newSrc }Src`];

		// if resetting back to unedited, also reset manual edit data
		// this allows the image to be manually edited again
		if (newSrc === 'unedited') {
			group[index].editRequestedAt = null;
			group[index].editStartedAt   = null;
			group[index].editCompletedAt = null;
		}

		// remove graphqlAdminId to allow image to be resaved in Shopify
		delete group[index].graphqlAdminId;

		this.updateGroup('updateSrc', groupName, group);
	}

	getImageSrc(groupName : GroupName, image : ProductImage) : string {
		if (!this.isInactiveGroup(groupName))
			return image && image.src;

		if (!Helper.regex.noCorsCdn.test(image.src))
			return image.src;

		const currentSrc : string = image.currentSource && image[`${ image.currentSource }Src`];

		if (currentSrc)
			return currentSrc;

		return image.src;
	}

	private async rotate(groupName : GroupName, index : number, clockwise : boolean) : Promise<void> {
		this.loadingMap[this[groupName][index].src] = true;

		const rotatedImage : ProductImage = await this.productImages.rotate(this[groupName][index], clockwise);

		return this.updateSrc(groupName, index, rotatedImage.currentSource, rotatedImage);
	}

	private showCropModal(groupName : GroupName, index : number) : void {
		this.cropInfo.groupName = groupName;
		this.cropInfo.index = index;

		this.getPreCropBlob(groupName, index);

		this.cropModal.present();
	}

	private async getPreCropBlob(groupName : GroupName, index : number) : Promise<void> {
		let blob : Blob;

		try {
			blob = await Helper.getBlobFromUrl(this[groupName][index].src, true);
		} catch (error) {
			this.log.error(EventIssuer.ProductImagesComponent, {
				eventContext : EventContext.ProductImageCrop,
				subject      : 'getPreCropBlob error',
				details      : {
					error,
					groupName,
					index,
				},
			});
		}

		if (blob) {
			this.cropInfo.blob = blob;
		}
	}

	dismissCropModal() : void {
		this.cropModal.dismiss();

		this.cropInfo = {};
	}

	loadCropImageFailed(event : any) : void {
		this.dismissCropModal();

		this.log.error(EventIssuer.ProductImagesComponent, {
			eventContext : EventContext.ProductImageCrop,
			subject      : 'ngx-image-cropper loadImageFailed',
			details      : {
				event,
				groupName : this.cropInfo.groupName,
				index     : this.cropInfo.index,
			},
		});
	}

	saveCrop() : void {
		this.cropInfo.saveClicked = true;

		const groupName = this.cropInfo.groupName;
		const index = this.cropInfo.index;

		this.loadingMap[this[groupName]?.[index]?.src] = true;

		this.imageCropper.crop();
	}

	private async imageCropped(event : any) : Promise<void> {
		const groupName = this.cropInfo.groupName;
		const index = this.cropInfo.index;

		if (groupName == null || index == null) {
			return;
		}

		const croppedImage : ProductImage = await this.productImages.crop(this[groupName][index], event.base64);

		this.dismissCropModal();

		return this.updateSrc(groupName, index, 'cropped', croppedImage);
	}
}
