import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';

import { HelperService as Helper } from '../../../services/helper/helper.service';
import { Radio, MetaRadio } from '../../../types/input-fields';


@Component({
	selector    : 'app-radio-input',
	templateUrl : './radio-input.component.html',
	styleUrls   : ['./radio-input.component.scss'],
})
export class RadioInputComponent implements OnInit, OnChanges {
	@Input() field         : Radio | MetaRadio;
	@Input() loading       : boolean;
	@Input() buttonsHidden : boolean;

	@Output() valueChange = new EventEmitter<string | number>();
	@Output() save        = new EventEmitter<string | number>();

	private disabled : boolean;

	newValue : string | number;


	constructor() {}


	ngOnInit() {
		this.newValue = Helper.deepCopy(this.field?.value);
	}

	ngOnChanges(changes: SimpleChanges) : void {
		if (changes.field && changes.field.firstChange === false) {
			this.disabled = null;

			this.newValue = Helper.deepCopy(changes.field.currentValue.value);
		}
	}

	isDisabled(): boolean {
		if (this.disabled == null) {
			const optionCount = parseInt(Helper.get(this.field, ['inputOptions', 'options', 'length']));

			if (isNaN(optionCount))
				return this.disabled = false;

			if (
				this.field.readOnly
				|| optionCount === 0
				|| (
					this.newValue
					&& optionCount === 1
					&& this.newValue === this.field.inputOptions.options[0].value
				)
			)
				return this.disabled = true;
			else
				return this.disabled = false;
		} else
			return this.disabled;
		
	}

	isSelected(option) : string {
		let iconName : string = 'radio-button-';

		iconName += this.newValue === option.value ? 'on' : 'off';

		return iconName;
	}

	updateValue(newValue : string | number) : void {
		this.newValue = newValue;

		this.valueChange.emit(newValue);
	}

	saveNewValue() : void {
		this.save.emit(this.newValue);
	}
}
