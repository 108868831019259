import { Component, Input } from '@angular/core';

import { HelperService as Helper } from '../../services/helper/helper.service';
import { FirestoreTimestamp } from '../../types/firestore';


@Component({
	selector    : 'app-created-updated-ago',
	templateUrl : './created-updated-ago.component.html',
	styleUrls   : ['./created-updated-ago.component.scss'],
})
export class CreatedUpdatedAgoComponent {
	private cachedMsg : string;

	@Input() created : FirestoreTimestamp | string;
	@Input() updated : FirestoreTimestamp | string;
	@Input() static  : boolean;


	constructor() {}


	parse() : string {
		if (this.cachedMsg)
			return this.cachedMsg;

		if (this.updated == null)
			this.updated = this.created;

		const createdDate : Date = Helper.parseDate(this.created),
			updatedDate : Date = Helper.parseDate(this.updated);

		if (!createdDate && !updatedDate)
			return;

		let action : string;

		if (createdDate && updatedDate)
			action = createdDate.getTime() === updatedDate.getTime() ? 'created' : 'updated';
		else if (createdDate)
			action = 'created';
		else
			action = 'updated';
		
		const msg : string = `${ action } ${ Helper.ago(updatedDate ?? createdDate) }`;

		if (this.static !== false)
			this.cachedMsg = msg;

		return msg;
	}
}
