import { Component, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';

import { HelperService as Helper } from '../../../services/helper/helper.service';
import { InputField, Metafield } from '../../../types/input-fields';


@Component({
	selector    : 'app-input',
	templateUrl : './input.component.html',
	styleUrls   : ['./input.component.scss'],
})
export class InputComponent implements OnChanges {
	@Input() field         : InputField | Metafield;
	@Input() loading       : boolean;
	@Input() buttonsHidden : boolean;

	@Output() focus       = new EventEmitter<void>();
	@Output() blur        = new EventEmitter<void>();
	@Output() valueChange = new EventEmitter<string | string[] | number>();
	@Output() save        = new EventEmitter<string | string[] | number>();
	@Output() delete      = new EventEmitter<void>();

	helper   = Helper;
	newValue : string | string[] | number;
	active   : boolean = false;


	constructor() {}


	ngOnChanges(changes : SimpleChanges) : void {
		if (changes.field) {
			const currentField = changes.field.currentValue;
			const previousField = changes.field.previousValue;

			const currentValue = currentField.value;

			if (!Helper.areEqual(currentValue, previousField?.value)) {
				this.updateNewValue(currentValue);
			}

			if (changes.field.firstChange === false) {
				this.checkSelectOptionsUpdate(currentField, currentValue, previousField);
			}
		}
	}

	private updateNewValue(newValue : string | string[] | number) : void {
		this.newValue = newValue;

		this.checkSanitize();
	}

	private checkSanitize() : void {
		if (this.newValue && this.field.inputType === 'textarea') {
			this.newValue = Helper.sanitizeHtmlToDisplay(this.newValue as string);
		}
	}

	private checkSelectOptionsUpdate(
		currentField  : InputField | Metafield,
		currentValue  : string | string[] | number,
		previousField : InputField | Metafield,
	) : void {
		if (Array.isArray(currentField.inputOptions?.options)) {
			const currentOptionsLength = currentField.inputOptions?.options?.length;
			const previousOptionsLength = previousField?.inputOptions?.options?.length;

			if (currentOptionsLength !== previousOptionsLength) {
				this.newValue = null;

				setTimeout(() => this.updateNewValue(currentValue), 0);
			}
		}
	}

	onFocus() : void {
		if (this.field.readOnly !== true) {
			this.focus.emit();
		}
	}

	onBlur() : void {
		if (this.field.readOnly !== true) {
			this.blur.emit();
		}
	}

	onTextareaFocus() : void {
		this.active = true;

		this.onFocus();
	}

	onTextareaBlur() : void {
		this.active = false;

		this.onBlur();
	}

	onChange() : void {
		if (this.field.readOnly !== true) {
			this.valueChange.emit(this.newValue);
		}
	}

	saveNewValue() : void {
		if (this.field.readOnly !== true && this.loading !== true) {
			if (this.newValue === '') {
				this.deleteField();
			} else {
				this.save.emit(this.newValue);
			}
		}
	}

	deleteField() : void {
		if (this.field.readOnly !== true && this.loading !== true) {
			this.delete.emit();
		}
	}

	showSave() : boolean {
		if (this.newValue == null) {
			return false;
		}

		return !Helper.areEqual(this.newValue, this.field.value);
	}
}
