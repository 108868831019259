import { Division, Department } from '@shopthrilling/thrilling-shared';

import { HelperService as Helper } from '../services/helper/helper.service';
import { StringListMap } from '../types/basics';

import * as ExportedTaxonomy from './exported-data/taxonomy_2022-07-29T16_34_51.json';


type TaxonomyByCategoryOrSubcategory = {
	division?    : Division;
	divisions    : Division[];
	department   : Department;
	category     : string;
	subcategory? : string;
};


const cache : any = {};

function getTaxonomy() : any {
	return (ExportedTaxonomy as any)?.default;
}

function uniqueSortedArr(arr : string[]) : string[] {
	return Helper.uniqueArray(arr).sort();
}

function concatArr(arrA : string[], arrB : string[]) : string[] {
	const concatArr = (arrA ?? []).concat(arrB ?? []);

	return uniqueSortedArr(concatArr);
}

function parseTaxonomy() : any {
	const parse : any = {
		divisionDepartmentsMap   : {},
		departmentCategoriesMap  : {},
		categorySubcategoriesMap : {},
	};

	const flatArr = [
		'divisions',
		'departments',
		'categories',
		'subcategories',
	];

	// init flat arrays
	for (const key of flatArr) {
		parse[key] = [];
	}

	for (const [ division, departments ] of Object.entries(getTaxonomy())) {
		parse.divisions.push(division);

		parse.divisionDepartmentsMap[division] = Object.keys(departments);

		for (const [ department, categories ] of Object.entries(departments)) {
			parse.departments.push(department);

			parse.departmentCategoriesMap[department] = concatArr(parse.departmentCategoriesMap[department], Object.keys(categories));

			for (const [ category, subcategories ] of Object.entries(categories)) {
				const flatSubcats = Object.keys(subcategories)
					.filter(item => item);

				parse.categories.push(category);

				if (flatSubcats.length) {
					parse.subcategories.push(...flatSubcats);

					parse.categorySubcategoriesMap[category] = concatArr(parse.categorySubcategoriesMap[category], flatSubcats);
				}
			}
		}
	}

	for (const key in parse) {
		if (flatArr.includes(key)) {
			parse[key] = uniqueSortedArr(parse[key]);
		}

		cache[key] = parse[key];
	}

	return parse;
}

export function Divisions() : Division[] {
	return cache.divisions ?? parseTaxonomy().divisions;
}

export function Departments() : Department[] {
	return cache.departments ?? parseTaxonomy().departments;
}

export function Categories() : string[] {
	return cache.categories ?? parseTaxonomy().categories;
}

export function Subcategories() : string[] {
	return cache.subcategories ?? parseTaxonomy().subcategories;
}

export function GetDepartmentsByDivision(division : Division) : Department[] {
	return getDivisionDepartmentsMap()[division] ?? [];
}

export function GetPossibleDivisionsByDepartment(department : Department) : Division[] {
	return Object.entries(getDivisionDepartmentsMap())
		.filter(([ division, departments ]) => departments.includes(department))
		.map(([ division, departments ]) => division as Division);
}

function getDepartmentCategoryBySubcategory(subcategory : string) : { department : Department, category : string } {
	const category   : string = GetCategoryBySubcategory(subcategory);
	const department : Department = GetDepartmentByCategory(category);

	return {
		department,
		category,
	};
}

export function GetPossibleDivisionsByTaxonomy(department : Department, category : string, subcategory? : string) : Division[] {
	const taxonomy : any = getTaxonomy();

	return GetPossibleDivisionsByDepartment(department)
		.filter(division => subcategory
			? taxonomy[division]?.[department]?.[category]?.[subcategory]
			: taxonomy[division]?.[department]?.[category]
		);
}

function getDivisionDepartmentsMap() : { [ key : string ] : Department[] } {
	return cache.divisionDepartmentsMap ?? parseTaxonomy().divisionDepartmentsMap;
}

export function GetDepartmentByCategory(category : string) : Department {
	const foundEntry = Object.entries(getDepartmentCategoriesMap())
		.find(([ department, categories ]) => categories.includes(category));

	return foundEntry?.[0] as Department;
}

export function GetCategoriesByTaxonomy(division? : Division, department? : Department) : string[] {
	if (department == null) {
		return Categories();
	} else if (division == null) {
		return GetCategoriesByDepartment(department);
	} else {
		return Object.keys(getTaxonomy()[division]?.[department] ?? {});
	}
}

export function GetCategoriesByDepartment(department : Department) : string[] {
	return getDepartmentCategoriesMap()[department] ?? [];
}

function getDepartmentCategoriesMap() : StringListMap {
	return cache.departmentCategoriesMap ?? parseTaxonomy().departmentCategoriesMap;
}

export function GetTaxonomyByCategory(category : string) : TaxonomyByCategoryOrSubcategory {
	const taxonomy   : any = getTaxonomy();
	const department : Department = GetDepartmentByCategory(category);
	const divisions  : Division[] = GetPossibleDivisionsByDepartment(department)
		.filter(division => taxonomy[division]?.[department]?.[category]);

	const data : TaxonomyByCategoryOrSubcategory = {
		divisions,
		department,
		category,
	};

	if (divisions?.length === 1) {
		data.division = divisions[0];
	}

	return data;
}

export function GetCategoryBySubcategory(subcategory : string) : string {
	const foundEntry = Object.entries(getCategorySubcategoriesMap())
		.find(([ category, subcategories ]) => subcategories.includes(subcategory));

	return foundEntry?.[0];
}

export function GetSubcategoriesByCategory(category : string) : string[] {
	return getCategorySubcategoriesMap()[category] ?? [];
}

function getCategorySubcategoriesMap() : StringListMap {
	return cache.categorySubcategoriesMap ?? parseTaxonomy().categorySubcategoriesMap;
}

export function GetSubcategoriesByTaxonomy(division? : Division, department? : Department, category? : string) : string[] {
	if (category == null) {
		return Subcategories();
	} else if (division == null) {
		return GetSubcategoriesByCategory(category);
	} else if (department == null) {
		department = GetDepartmentByCategory(category);
	}

	return Object.keys(getTaxonomy()[division]?.[department]?.[category] ?? {})
		.filter(item => item);
}

export function GetTaxonomyBySubcategory(subcategory : string) : TaxonomyByCategoryOrSubcategory {
	const { department, category } = getDepartmentCategoryBySubcategory(subcategory);
	const divisions = GetPossibleDivisionsByTaxonomy(department, category, subcategory);

	const data : TaxonomyByCategoryOrSubcategory = {
		divisions,
		department,
		category,
		subcategory,
	};

	if (divisions?.length === 1) {
		data.division = divisions[0];
	}

	return data;
}
