import { Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';


@Component({
	selector    : 'app-char-counter',
	templateUrl : './char-counter.component.html',
	styleUrls   : ['./char-counter.component.scss'],
})
export class CharCounterComponent implements OnChanges {
	@Input() text     : string;
	@Input() max      : number;
	@Input() warning? : number;

	@Output() maxExceeded     = new EventEmitter<boolean>();
	@Output() warningExceeded = new EventEmitter<boolean>();

	count  : string;
	status : 'warning' | 'danger' | undefined;


	constructor() {}


	ngOnChanges() : void {
		if (this.max == null)
			return;

		const charCount : number = (this.text ?? '').length,
			maxExceeded : boolean = charCount > this.max;

		this.updateCount(charCount);

		this.doEmit('max', maxExceeded);

		let warningExceeded : boolean;

		if (this.warning != null) {
			warningExceeded = charCount > this.warning;

			this.doEmit('warning', warningExceeded);
		}

		this.updateStatus(maxExceeded, warningExceeded);
	}

	private updateCount(count : number) : void {
		this.count = `${ count } / ${ this.max }`;
	}

	private doEmit(type : 'max' | 'warning', value : boolean) : void {
		this[`${ type }Exceeded`].emit(value);
	}

	private updateStatus(maxExceeded : boolean, warningExceeded? : boolean) : void {
		if (maxExceeded)
			this.status = 'danger';
		else if (warningExceeded != null && warningExceeded)
			this.status = 'warning';
		else
			this.status = undefined;
	}
}
