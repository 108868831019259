import { Injectable } from '@angular/core';
import {
	CanActivate,
	Router,
	UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { take, switchMap } from 'rxjs/operators';

import { CurrentUserService } from '../../services/current-user/current-user.service';


// protect the /login page from already logged in users
@Injectable({
	providedIn : 'root',
})
export class SignedInGuard implements CanActivate {
	constructor(
		private router      : Router,
		private currentUser : CurrentUserService,
	) {}

	canActivate() : Observable<boolean | UrlTree> {
		return this.currentUser.isSignedIn().pipe(
			take(1),
			switchMap(signedIn => {
				if (signedIn) {
					console.info('already signed in');

					return of(this.router.parseUrl('/pages/home'));
				} else
					return of(true);
			}),
		);
	}
}
