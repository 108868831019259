import { NewWholesaleProductFieldGrouping } from '../types/new-wholesale-product-input-fields';

import { SizeOptions } from './product-options.model';


export function NewWholesaleProductFields() : NewWholesaleProductFieldGrouping {
	return {
		order : [
			'main',
			'optional',
		],
		groups : {
			main : {
				key   : 'main',
				title : 'Required Info',
				order : [
					'vendor',
					'images',
					'price',
				],
				fields : {
					vendor : {
						key          : 'vendor',
						title        : 'Store',
						inputType    : 'select',
						inputOptions : {
							options  : [],
							multiple : false,
						},
					},
					images : {
						key          : 'images',
						title        : 'Images',
						inputType    : 'image',
						deletable    : true,
						inputOptions : {
							multiple  : true,
							orderable : true,
						},
					},
					price : {
						key          : 'price',
						title        : 'Price',
						inputType    : 'radio',
						inputOptions : {
							options : [
								{
									value : 5,
									title : '$5',
								},
								{
									value : 10,
									title : '$10',
								},
								{
									value : 15,
									title : '$15',
								},
								{
									value : 20,
									title : '$20',
								},
								{
									value : 25,
									title : '$25',
								},
								{
									value : 30,
									title : '$30',
								},
							],
						},
					},
				},
			},
			optional : {
				key   : 'optional',
				title : 'Optional Fields',
				order : [
					'size',
					'descriptionHtml',
				],
				fields : {
					size : {
						key          : 'size',
						title        : 'Size/s',
						description  : 'Select up to 2 consecutive sizes',
						inputType    : 'select',
						inputOptions : {
							options  : SizeOptions(),
							multiple : true,
						},
					},
					descriptionHtml : {
						key       : 'descriptionHtml',
						title     : 'Description',
						inputType : 'textarea',
					},
				},
			},
			hidden : {
				key   : 'hidden',
				title : 'Hidden Fields',
				order : [
					'tags',
				],
				fields : {
					tags : {
						key        : 'tags',
						title      : 'Tags',
						inputClass : 'tag',
						inputType  : 'text',
					},
				},
			},
		},
	};
}
