import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { HelperService as Helper } from '../../services/helper/helper.service';
import { ImgixService } from '../../services/imgix/imgix.service';

import { ZoomImageModalComponent } from './zoom-image-modal/zoom-image-modal.component';


export type ZoomMenuOption = {
	title         : string;
	handler       : Function;
	icon?         : string;
	dismissModal? : boolean;
};


@Component({
	selector    : 'app-zoom-image',
	templateUrl : './zoom-image.component.html',
	styleUrls   : ['./zoom-image.component.scss'],
})
export class ZoomImageComponent {
	@Input() src         : string;
	@Input() width       : number = 56;
	@Input() menuOptions : ZoomMenuOption[];
	@Input() meta        : any;

	helper = Helper;


	constructor(
		public modal  : ModalController,
		public imgix  : ImgixService,
	) {}


	async zoomImage(event : Event) : Promise<any> {
		event.preventDefault();

		const modal = await this.modal.create({
			component      : ZoomImageModalComponent,
			cssClass       : 'zoom-image-modal',
			componentProps : {
				parent : this,
			},
		});

		return await modal.present();
	}
}
