import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as Papa from 'papaparse';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';

import { corsProxy } from '../../../environments/environment';
import { HelperService as Helper } from '../../services/helper/helper.service';
import { ProductService } from '../../services/product/product.service';
import { EditedImagesService } from '../../services/edited-images/edited-images.service';
import { AnyMap } from '../../types/basics';
import { ImageValue } from '../../types/input-fields';


@Injectable({
	providedIn : 'root',
})
export class DownloadService {
	loading : boolean = false;
	query$  : BehaviorSubject<string> = new BehaviorSubject<string>('');


	constructor(
		private product      : ProductService,
		private editedImages : EditedImagesService,
	) {}


	private toggleLoading(forceState? : boolean) : void {
		this.loading = forceState == null ? !this.loading : forceState;
	}

	async zipOfImages(images : ImageValue[], zipFilename : string, defaultFiletype? : string, callback : Function = () => {}) : Promise<void> {
		const zip : JSZip = new JSZip();

		for (const image of images) {
			let filename : string = Helper.getFilename(image.src);

			if (defaultFiletype != null && !Helper.parsePath(filename).fileExtension)
				filename += `.${ defaultFiletype }`;

			zip.file(filename, this.getBlobPromise(image.src), { binary : true });
		}

		const blob : Blob = await zip.generateAsync({ type : 'blob' }, callback);

		return this.saveFile(blob, zipFilename, 'zip');
	}

	private getBlobPromise(url : string) : Promise<Blob> {
		return new Promise(function (resolve, reject) {
			const xhr = new XMLHttpRequest();

			xhr.open('get', `https://${ corsProxy.proxyDomain }/${ url }`, true);

			xhr.setRequestHeader(corsProxy.proxyRequestHeader, 'true');

			xhr.responseType = 'blob';

			xhr.onload = function() {
				if (this.status >= 200 && this.status < 300) 
					resolve(xhr.response);
				else {
					reject({
						status     : this.status,
						statusText : xhr.statusText,
					});
				}
			};

			xhr.onerror = function() {
				reject({
					status     : this.status,
					statusText : xhr.statusText,
				});
			};

			xhr.send();
		});
	}

	saveFile(file : string | Blob, filename : string = 'download', filetype : string = 'txt') : void {
		if (!Helper.parsePath(filename).fileExtension) {
			// in case the passed filetype is prefixed with a period
			// such as with HelperClass.parseUrl(…).fileExtension
			// remove it
			filetype = filetype.replace(/^\./, '');

			filename = `${ filename }.${ filetype }`;
		}

		return saveAs(file, filename);
	}

	async zipOfCsv(data : any = {}, csvFilename : string = 'export', zipFilename : string = 'export') : Promise<void> {
		const zip : JSZip = new JSZip(),
			csvBlob : Blob = this.getCsvBlob(data);

		zip.file(`${ csvFilename }.csv`, csvBlob, { binary : true });

		const zipBlob = await zip.generateAsync({ type : 'blob' });

		return this.saveFile(zipBlob, zipFilename, 'zip');
	}

	csv(data : AnyMap, filename? : string) : void {
		return this.csvOrJson(data, 'csv', filename);
	}

	json(data : string | AnyMap, filename? : string) : void {
		return this.csvOrJson(data, 'json', filename);
	}

	private csvOrJson(data : string | AnyMap, filetype : 'csv' | 'json', filename : string = 'export') : void {
		const blob = filetype === 'csv' ? this.getCsvBlob(data) : this.getJsonBlob(data);

		return this.saveFile(blob, filename, filetype);
	}

	private getCsvBlob(data : any = {}) : Blob {
		return this.getBlob(Papa.unparse(data), 'text/csv;charset=utf-8;');
	}

	private getJsonBlob(data : string | AnyMap = '') : Blob {
		return this.getBlob(
			typeof data === 'string' ? data : JSON.stringify(data, null, 2),
			'application/json',
		);
	}

	private getBlob(data : any, type : string) : Blob {
		return new Blob([ data ], { type });
	}
}
