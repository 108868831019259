import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SocialLink } from '@shopthrilling/thrilling-shared';

import { HelperService as Helper } from '../../services/helper/helper.service';
import { MetaSocialLinks, SocialKey, SocialOption } from '../../types/input-fields';


interface SocialOptionWithEntry extends SocialOption {
	entry? : string;
}


@Component({
	selector    : 'app-store-social-links',
	templateUrl : './store-social-links.component.html',
	styleUrls   : ['./store-social-links.component.scss'],
})
export class StoreSocialLinksComponent implements OnInit {
	@Input() field   : MetaSocialLinks;
	@Input() loading : boolean;

	@Output() save = new EventEmitter<any>();

	private flatValue    : string;
	private flatNewValue : string;

	newValue : SocialOptionWithEntry[];


	constructor() {}


	ngOnInit() : void {
		this.init();
	}

	private init() : void {
		this.flatValue = this.getFlatValue(this.field.value);

		this.newValue = [];

		const indexMap : { [key : string] : { value : string, index : number } } = {},
			unusedArr : SocialOption[] = [];

		for (const index in this.field.value) {
			if (this.field.value[index].value != null) {
				indexMap[this.field.value[index].key] = {
					value : this.field.value[index].value,
					index : parseInt(index),
				};
			}
		}

		for (const option of Helper.deepCopy(this.field.inputOptions.options)) {
			const match = indexMap[option.value];

			if (match) {
				this.newValue[match.index] = {
					...option,
					entry : match.value,
				};
			} else
				unusedArr.push(option);
		}

		this.newValue = this.newValue.concat(unusedArr);

		this.updateComparisonValues();
	}

	private updateComparisonValues(): void {
		this.flatNewValue = this.getFlatValue(this.getSocialLinksFromSocialOptions());
	}

	private getSocialLinksFromSocialOptions(): SocialLink[] {
		return (this.newValue || [])
			.filter(item => !!item.entry)
			.map(item => ({
				key   : item.value as SocialKey,
				value : item.entry,
			}));
	}

	private getFlatValue(socialLinks: SocialLink[]): string {
		return (socialLinks || [])
			.filter(item => !!item.value.trim())
			.map(item => `${ item.key }${ item.value }`)
			.join('');
	}

	saveDisabled() : boolean {
		return this.loading || this.flatValue === this.flatNewValue;
	}

	doReorder(event : any) : void {
		event.detail.complete(this.newValue);

		this.updateComparisonValues();
	}

	change(entry : string, index : number) : void {
		const regex : RegExp = /^@/;

		if (regex.test(entry)) {
			entry = entry.replace(regex, '');

			this.newValue[index] = {
				...this.newValue[index],
				entry,
			};
		}

		this.updateComparisonValues();
	}

	delete(index : number) : void {
		this.newValue[index].entry = null;

		this.updateComparisonValues();
	}

	cancel() : void {
		this.init();
	}

	onSave() : void {
		const value : SocialLink[] = this.getSocialLinksFromSocialOptions();

		this.save.emit(value);
	}
}
