import { Component, Input, Output, EventEmitter } from '@angular/core';

import { TagInput } from '../../../types/input-fields';


@Component({
	selector    : 'app-tag-class-text-input',
	templateUrl : './tag-class-text-input.component.html',
	styleUrls   : ['./tag-class-text-input.component.scss'],
})
export class TagClassTextInputComponent {
	@Input() field        : TagInput;
	@Input() loading      : boolean;
	@Input() itemCategory : string;

	@Output() save = new EventEmitter<string[]>();


	constructor() {}


	onSave(newTags : string[]) : void {
		this.save.emit(newTags);
	}
}
