import { Component, Input } from '@angular/core';


@Component({
	selector    : 'app-spinner',
	templateUrl : './spinner.component.html',
	styleUrls   : ['./spinner.component.scss'],
})
export class SpinnerComponent {
	@Input() textTop    : string;
	@Input() textBottom : string;
	@Input() overlay    : boolean;
	@Input() fixed      : boolean;
	@Input() size       : 'small' | 'medium' | 'large';


	constructor() {}
}
