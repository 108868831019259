export enum EventIssuer {
	EditedImagesService    = 'EditedImagesService',
	FileStorageService     = 'FileStorageService',
	NewProductPage         = 'NewProductPage',
	NewProductService      = 'NewProductService',
	NewStorePage           = 'NewStorePage',
	ProductImagesComponent = 'ProductImagesComponent',
	ProductImporterPage    = 'ProductImporterPage',
	ProductPage            = 'ProductPage',
	ProductService         = 'ProductService',
	PublishToggleComponent = 'PublishToggleComponent',
	ShopifyAdminService    = 'ShopifyAdminService',
	SignInPage             = 'SignInPage',
	StoreService           = 'StoreService',
	UserService            = 'UserService',
}
