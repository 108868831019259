import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { take, map, switchMap, tap } from 'rxjs/operators';
import { Product, ProductImage, ImageSource } from '@shopthrilling/thrilling-shared';

import { firebaseConfig } from '../../../environments/environment';
import { LogService } from '../../services/log/log.service';
import { RemoteConfigService } from '../../services/remote-config/remote-config.service';
import { HelperService as Helper } from '../../services/helper/helper.service';
import { ProductService } from '../../services/product/product.service';
import { FileStorageService } from '../../services/file-storage/file-storage.service';
import { EventIssuer } from '../../constants/EventIssuer';
import { EventContext } from '../../constants/EventContext';


@Injectable({
	providedIn : 'root',
})
export class ProductImagesService {
	constructor(
		private log           : LogService,
		private remoteConfig  : RemoteConfigService,
		private product       : ProductService,
		private fileStorage   : FileStorageService,
		private sharedProduct : Product,
	) {}


	isEdited(image : ProductImage) : boolean {
		return image.currentSource === 'autoEdited' || image.currentSource === 'manuallyEdited';
	}

	isCropped(image : ProductImage) : boolean {
		return image.currentSource === 'cropped';
	}

	isPending(image : ProductImage) : boolean {
		return image.editRequestedAt && !image.editCompletedAt;
	}

	isEditedOrPending(image : ProductImage) : boolean {
		return image && (this.isEdited(image) || this.isPending(image));
	}

	isEditedPendingOrCropped(image : ProductImage) : boolean {
		return image && (this.isEdited(image) || this.isPending(image) || this.isCropped(image));
	}

	getByShopifyId(graphqlAdminId : string) : Observable<ProductImage[]> {
		return this.product.getByShopifyId(graphqlAdminId).pipe(
			map((product : Product) => product?.images ?? null),
		);
	}

	updateInShopifyAndFirestore(id : string, sku : string, images : ProductImage[]) : Observable<ProductImage[]> {

		return this.updateInShopify(id, images).pipe(
			// tap(data => console.debug(data)),
			switchMap(product => this.upsertProduct({
				sku,
				graphqlAdminId : id,
				images         : product.images,
				handle         : product.handle ?? null,
				title          : product.title ?? null,
			})),
			// tap(data => console.debug(data)),
		);
	}

	updateInShopify(id : string, images : ProductImage[]) : Observable<any> {
		const attachedImages : ProductImage[] = this.parseAttachedImages(images),
			newImages : any[] = [],
			savedImages : ProductImage[] = [];

		let update$ : Observable<any>;

		attachedImages.forEach((image, index) => {
			if (image.graphqlAdminId)
				savedImages.push(image);
			else {
				newImages.push({
					image,
					index,
				});
			}
		});

		if (newImages.length <= 1)
			update$ = this.doUpdateInShopify(id, attachedImages);
		else
			update$ = from(this.loopThroughImages(id, newImages, savedImages));

		return update$.pipe(
			// tap(data => console.debug(data)),
			map(product => ({
				...product,
				images : this.sharedProduct.mergeProductImages(
					images,
					product.images,
				),
			})),
			// tap(data => console.debug(data)),
		);
	}

	/**
	 * Push images to Shopify
	 * This is reserved for image reAdding for now (TT-1424)
	 * 
	 * @param id graphQl Admin ID of the product
	 * @param images a list of images to be attached to the product
	 * @returns an Observable of the updated product
	 * @throws an error if the id is undefined aka the product doesn't exist in Shopify
	 */
	pushImagesToShopify(id : string | undefined, images : ProductImage[]) : Observable<Product> {
		if (!id) {
			throw new Error('This product does not exist in Shopify');
		}

		const attachedImages : ProductImage[] = this.parseAttachedImages(images);

		return this.product.updateShopify(id, { images : attachedImages }, null, true);
	}

	private doUpdateInShopify(id : string, images : ProductImage[]) : Observable<any> {
		const attachedImages : ProductImage[] = this.parseAttachedImages(images);

		return this.product.updateShopify(id, { images : attachedImages }, null, true).pipe(
			// tap(data => console.debug(data)),
			map(product => ({
				...product,
				images : this.sharedProduct.mergeProductImages(
					images,
					this.parseImagesFromShopify(product.images),
				),
			})),
			// tap(data => console.debug(data)),
		);
	}

	private parseImagesFromShopify(rawImages : any) : ProductImage[] {
		const flatImages = Helper.flattenEdgeNodeArray(rawImages)
			.map(image => new ProductImage(image));

		return flatImages;
	}

	private async loopThroughImages(id : string, newImages : any[], savedImages : ProductImage[]) : Promise<any> {
		let product : any = {
			images : savedImages,
		};

		for (const item of newImages) {
			product.images.splice(item.index, 0, item.image);

			product = await this.doUpdateInShopify(id, product.images).toPromise();
		}

		return product;
	}

	parseAttachedImages(images : ProductImage[]) : ProductImage[] {
		return images.filter(image => !image.detachedFromShopify);
	}

	upsertProduct(newProduct : any) : Observable<ProductImage[]> {
		let id : string;

		// check if a record exists for this product ID
		return this.product.getByShopifyId(newProduct.graphqlAdminId).pipe(
			take(1),
			map((product : Product) => {
				id = product?.id;

				return id
					? this.sharedProduct.mergeProductImages(product.images, newProduct.images)
					: newProduct.images;
			}),
			map(this.filterReAddedImages.bind(this)),
			take(1),
			switchMap((images : ProductImage[]) => {
				const editRequested = this.hasManualEditItems(images);

				let upsert$ : Observable<any>;

				if (id) {
					upsert$ = from(this.product.update(id, {
						images,
						editRequested,
					}));
				} else {
					upsert$ = from(this.product.create({
						...newProduct,
						images,
						editRequested,
					}));
				}

				return upsert$.pipe(
					map(() => images),
				);
			}),
		);
	}

	private filterReAddedImages(images : ProductImage[]) : ProductImage[] {
		const reAddingList : string[] = images
			.filter(image => image.reAdding)
			.map(image => Helper.getFilename(image.src, false, true, false));

		return reAddingList.length
			? images
				.filter(image =>
					!(image.detachedFromShopify && !image.reAdding && reAddingList.includes(Helper.getFilename(image.src, false, true, false)))
				)
				.map(image => {
					delete image.reAdding;

					return image;
				})
			: images;
	}

	/**
	 * Push image updates to Firestore
	 * This is reserved for image reAdding for now (TT-1424)
	 * 
	 * @param shopifyId graphQl Admin ID of the product
	 * @param shopifyImages images recently pushed to Shopify
	 * @param localImages images that include local updates (e.g. reAdding)
	 * @returns Observable of the updated product
	 */
	pushImageUpdatesToFirestore(shopifyId: string, shopifyImages : ProductImage[], localImages : ProductImage[]) : Observable<Product> {
		const normalizedShopifyImages = this.parseImagesFromShopify(shopifyImages);
		const editRequested = this.hasManualEditItems(localImages);

		const mergedImages = this.sharedProduct.mergeProductImages(localImages, normalizedShopifyImages);
		const filteredImages = this.filterReAddedImages(mergedImages);

		return this.product.getByShopifyId(shopifyId).pipe(
			take(1),
			switchMap((product: Product) => this.product.simpleImageUpdate(product.id, {
				images : filteredImages,
				editRequested,
			})),
			take(1),
			// tap(data => console.debug(data))
		);
	}

	private hasManualEditItems(images : ProductImage[]) : boolean {
		return !!images.filter(image => !image.detachedFromShopify && !!image.editRequestedAt).length;
	}

	createProduct(product : Product) : Observable<Product> {
		product.editRequested = this.hasManualEditItems(product.images);

		return from(this.product.create(product));
	}

	/**
	 * Auto-edit an image.
	 *
	 * @param src - the source image.
	 * @returns the edited image data.
	 */
	async autoEdit(src : string) : Promise<string> {
		let useAutoEditEndpoint;

		try {
			useAutoEditEndpoint = await this.remoteConfig.getByKeyPromise('use_autoedit_endpoint');
		} catch (error) {
			this.handleAutoEditError(src, error, 'remote config');
		}

		if (useAutoEditEndpoint === false) {
			return this.autoEditOld(src);
		}

		const autoEditEndpoint = '/image/edit/remove-bg';
		const url = firebaseConfig.apiURL + autoEditEndpoint;
		const response : Response = await window.fetch(url, {
			method : 'POST',
			body   : JSON.stringify({ src }),
		});
		const responseBody = await response.json();

		if (response.status !== 200) {
			throw new Error(this.handleAutoEditError(src, responseBody.errors?.details?.title));
		}

		return responseBody.data?.autoEditedSrc;
	}

	private async autoEditOld(src : string) : Promise<string> {
		let jsonString : string;

		try {
			jsonString = await this.requestAutoEditOld(src);
		} catch (error) {
			throw new Error(this.handleAutoEditError(src, error));
		}

		const upload = await this.uploadAutoEditedImageOld(src, jsonString);

		if (!upload) {
			throw new Error(this.handleAutoEditError(src, 'unexpected response returned fom remove.bg'));
		}

		return upload;
	}

	private requestAutoEditOld(src : string) : Promise<any> {
		return new Promise((resolve, reject) => {
			const xhr = new XMLHttpRequest();
			const formData = new FormData();

			formData.append('format', 'png');
			formData.append('size', 'full');
			formData.append('crop', 'true');
			formData.append('image_url', src);

			xhr.open('POST', 'https://api.remove.bg/v1.0/removebg', true);

			xhr.setRequestHeader('X-Api-Key', 'sQPXAcVAjQSoKxmQwWTbpLzp');
			xhr.setRequestHeader('Accept', 'application/json');

			xhr.onload = () => {
				if (xhr.status >= 200 && xhr.status < 300) {
					resolve(xhr.response);
				} else {
					reject(xhr);
				}
			};

			xhr.onerror = () => reject(xhr);

			xhr.send(formData);
		});
	}

	private uploadAutoEditedImageOld(filename : string, jsonString : string) : Promise<string> {
		const data : any = JSON.parse(jsonString);

		if (!(data && data.data && data.data.result_b64)) {
			return null;
		}

		const dataUrl : string = 'data:image/png;base64,' + data.data.result_b64;
		const uniqueFilename : string = `${ Helper.getFilename(filename, false) }.png`;
		const task = this.fileStorage.uploadDataUrl(dataUrl, uniqueFilename);

		return task.getDownloadURL().toPromise();
	}

	private handleAutoEditError(itemId : string, details : any, context : 'autoedit' | 'remote config' = 'autoedit') : string {
		const subject : string = `Unable to automatically ${ context === 'remote config' ? 'retreive remote config variable' : 'remove background from image' }`;

		if (details.response && typeof details.response === 'string') {
			details['responseParsed'] = JSON.parse(details.response);
		}

		this.log.error(EventIssuer.EditedImagesService, {
			subject,
			eventContext : context === 'remote config'
				? EventContext.CantGetRemoteConfig
				: EventContext.ProductImage,
			itemId,
			details,
		});

		return details?.responseParsed?.errors?.[0]?.code ?? 'unknown error';
	}

	getProductsByEditRequested(excludeSoldOut : boolean = true) : Observable<Product[]> {
		return this.product.getByEditRequested(excludeSoldOut);
	}

	getProductByShopifyId(id : string) : Observable<Product> {
		return this.product.getByShopifyId(id) as Observable<Product>;
	}

	/**
	 * Resize and convert to the given media type.
	 *
	 * @param dataUrl The original data Url.
	 * @param mimeType The media type to convert to.
	 *
	 * @returns a data url of type @type {mimeType}.
	 */
	resizeAndConvertToMediaType(dataUrl : string, mimeType : string) : Promise<string> {
		return new Promise((resolve, reject) => {
			const maxWidth : number = 4000,
				maxHeight : number = 4000,
				image = new Image();

			image.onload = () => {
				const width : number = image.width,
					height : number = image.height,
					canvas = document.createElement('canvas'),
					context = canvas.getContext('2d');

				// Initialize canvas size
				canvas.width = width,
				canvas.height = height;

				if (width > maxWidth || height > maxHeight) {
					let newWidth : number,
						newHeight : number;

					if (width > height) {
						newHeight = height * (maxWidth / width);

						newWidth = maxWidth;
					} else {
						newWidth = width * (maxHeight / height);

						newHeight = maxHeight;
					}

					canvas.width = newWidth;

					canvas.height = newHeight;

					context.drawImage(image, 0, 0, newWidth, newHeight);
				} else
					context.drawImage(image, 0, 0, width, height);

				resolve(canvas.toDataURL(mimeType));
			};

			image.onerror = reject;

			image.src = dataUrl;
		});
	}

	async rotate(image : ProductImage, clockwise : boolean) : Promise<ProductImage> {
		const currentSource : ImageSource = image.currentSource ?? 'unedited';
		const dataUrl : string = await Helper.getDataUrlFromUrl(image.src);
		const fileType : string = dataUrl.replace(/^data:(.*);.*$/, '$1');
		const filename : string = Helper.getFilename(image.src);
		const rotatedDataUrl : string = await this.doRotate(dataUrl, clockwise, fileType);
		const rotatedSrc : string = await this.getUploadedSrcFromDataUrl(rotatedDataUrl, filename);
		const rotatedImage = new ProductImage({
			...image,
			currentSource,
			src : rotatedSrc,
		});

		rotatedImage[`${ currentSource }Src`] = rotatedSrc;

		return rotatedImage;
	}

	private doRotate(dataUrl : string, clockwise : boolean = true, fileType? : string) : Promise<string> {
		return new Promise((resolve, reject) => {
			const image = new Image();

			image.onload = function () {
				const canvas = document.createElement('canvas'),
					context = canvas.getContext('2d');

				canvas.width = image.height;
				canvas.height = image.width;

				if (clockwise) {
					context.rotate(90 * Math.PI / 180);
					context.drawImage(image, 0, -image.height);
				} else {
					context.rotate(-90 * Math.PI / 180);
					context.drawImage(image, -image.width, 0);
				}

				resolve(canvas.toDataURL(fileType));
			};

			image.onerror = reject;

			image.src = dataUrl;
		});
	}

	private getUploadedSrcFromDataUrl(dataUrl : string, filename? : string) : Promise<string> {
		return this.fileStorage.uploadDataUrl(
			dataUrl,
			filename ?? 'product image',
		)
			.getDownloadURL()
			.toPromise();
	}

	getCroppedImageSrcFromDataUrl(dataUrl : string, currentSrc : string = 'product image') : Promise<string> {
		return this.getUploadedSrcFromDataUrl(
			dataUrl,
			this.getCroppedImageFilename(currentSrc),
		);
	}

	private getCroppedImageFilename(src : string) : string {
		let filename = Helper.getFilename(src, false, false, false);

		if (filename) {
			filename += '-';
		}

		return `${ filename }cropped.png`;
	}

	async crop(image : ProductImage, dataUrl : string) : Promise<ProductImage> {
		const croppedSrc = await this.getCroppedImageSrcFromDataUrl(dataUrl, image.src);

		return {
			...image,
			croppedSrc,
			src           : croppedSrc,
			currentSource : 'cropped',
		};
	}
}
