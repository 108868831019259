import { Component, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { debounce } from 'lodash';
import { ProductMeasurementFieldKey } from '@shopthrilling/thrilling-shared';

import { HelperService as Helper } from '../../../services/helper/helper.service';
import { GetMeasurements } from '../../../models/size.model';
import { MeasurementsField, NumberField } from '../../../types/input-fields';
import { MeasurementsMap } from '../../../types/measurements';


@Component({
	selector    : 'app-measurements-input',
	templateUrl : './measurements-input.component.html',
	styleUrls   : ['./measurements-input.component.scss'],
})
export class MeasurementsInputComponent implements OnChanges {
	private catSubCatChange : Function = debounce(this.initMeasurements.bind(this), 250);

	@Input() field       : MeasurementsField;
	@Input() category    : string;
	@Input() subcategory : string;
	@Input() loading     : boolean;

	@Output() save = new EventEmitter<MeasurementsMap>();

	measurements : ProductMeasurementFieldKey[];


	constructor() {}


	ngOnChanges(changes : SimpleChanges) : void {
		if (changes.category || changes.subcategory) {
			this.catSubCatChange();
		}
	}

	private initMeasurements() : void {
		const value : string = this.subcategory || this.category;
		const type = this.subcategory ? 'subcategory' : 'category';

		this.updateMeasurements(value, type);
	}

	private updateMeasurements(value : string, type : 'category' | 'subcategory') : void {
		const oldMeasurements = this.measurements;

		this.measurements = GetMeasurements(value, type);
	}

	getField(key : string) : NumberField {
		let title = key.replace(/([a-z])([A-Z])/g, '$1 $2');

		title = Helper.titleCase(title);

		title += ' (in)';

		return {
			key,
			title,
			value        : this.field.value?.[key],
			inputType    : 'number',
			inputOptions : {
				decimalPlaces : 2,
			},
		};
	}

	onSave(key : string, value : string) : void {
		const measurements = Helper.deepCopy(this.field.value);

		measurements[key] = value;

		this.save.emit(measurements);
	}
}
