import he from 'he';
import { Helper } from '@shopthrilling/thrilling-shared';
import { Config, sanitize } from 'dompurify';

import { corsProxy } from '../../../environments/environment';
import { FirestoreTimestamp } from '../../types/firestore';


export class HelperService extends Helper {
	public static sanitizeHtmlToSave(desc : string) : string {
		if (desc == null) {
			return null;
		}

		const rawArr : string[] = HelperService.encodeHTML(desc.replace(/\n{3,}/g, '\n\n')).split(/\n/);
		const cleanArr : string[] = [];

		if (rawArr.length === 1) {
			return HelperService.sanitizeHtml(rawArr[0]);
		}

		for (const paragraph of rawArr) {
			cleanArr.push(`${ paragraph }<br>`);
		}

		const cleanInput = cleanArr.join('');
		const sanitizedInput = HelperService.sanitizeHtml(cleanInput);

		return sanitizedInput;
	}

	public static sanitizeHtmlToDisplay(desc : string) : string {
		if (desc == null) {
			return null;
		}

		return HelperService.decodeHTML(desc.replace(/<\/p><p>/g, '<br>').replace(/<p>|<\/p>/g, '').replace(/<br>/g, '\n').replace(/<br>{3,}/g, `${ '\n' }${ '\n' }`).replace(/&amp;/g, '&'));
	}

	public static sanitizeHtml = (input : string) : string => {
		if (!input) {
			return input;
		}

		const allowedTags = [ 'a', 'br' ];
		const config : Config = { ALLOWED_TAGS : allowedTags };
		const sanitizedInput = sanitize(input, config) as string;
		const legibleInput = sanitizedInput.replace(/&amp;/g, '&');

		return legibleInput;
	};

	public static titleCase(str : string, uppercaseConnectors : boolean = true, customTitleFixes : boolean = false, preserveTrailingSpace : boolean = false) : string {
		if (!(str && str.trim())) {
			return str;
		}

		str = str.toLowerCase();

		const connectors : RegExp = /^(with|by|for|of|and|a)$/i;
		const wordArr : string[] = str.split(/\s/);
		const newArr : string[] = [];

		wordArr.forEach((word, index, arr) => {
			if (word.trim()) {
				let newWord : string;

				if (
					index !== 0
					&& uppercaseConnectors === false
					&& connectors.test(word)
				) {
					newWord = word.replace(connectors, match => match.toLowerCase());
				} else {
					newWord = word.charAt(0).toUpperCase() + word.slice(1);
				}

				if (customTitleFixes) {
					newWord = HelperService.customTitleFixes(newWord);
				}

				newArr.push(newWord);
			} else if (index === arr.length - 1 && preserveTrailingSpace) {
				newArr.push('');
			}
		});

		return newArr.join(' ');
	}

	private static customTitleFixes(input : string) : string {
		const pattern : RegExp = /(^.+[-&])(.)/;
		const replacer = (match, p1, p2) => p1 + p2.toUpperCase();
		const fixMap = {};

		if (pattern.test(input)) {
			return input.replace(pattern, replacer);
		} else if (fixMap[input]) {
			return fixMap[input];
		} else {
			return input;
		}
	}

	public static nextLastLetter(original : string) : string {
		const regex : RegExp = /([A-Z])$/,
			addA = (srcStr : string) : string => srcStr.trim() + 'A';

		if (!regex.test(original))
			return addA(original);

		return original.replace(regex, lastLetter => {
			const char : number = lastLetter.charCodeAt(0);

			if (char === 90)
				return addA(lastLetter);
			else
				return String.fromCharCode(char + 1);
		});
	}

	public static parseDate(timestamp : FirestoreTimestamp | string) : Date {
		if (!timestamp)
			return;

		let date : Date;

		if (typeof timestamp === 'string')
			date = new Date(timestamp);
		else if (timestamp.seconds)
			date = new Date(timestamp.seconds * 1000);

		if (!date || isNaN(date.getTime()))
			return;

		return date;
	}

	public static formatTimestamp(timestamp : FirestoreTimestamp | string) : string {
		const date : Date = HelperService.parseDate(timestamp),
			months : string[] = [
				'Jan',
				'Feb',
				'Mar',
				'Apr',
				'May',
				'Jun',
				'Jul',
				'Aug',
				'Sep',
				'Oct',
				'Nov',
				'Dec',
			];

		if (!date)
			return;

		let hour : number = date.getHours(),
			middayDivide : string = 'AM';

		if (hour > 12) {
			hour = hour - 12;

			middayDivide = 'PM';
		}

		return `${ hour }:${ ('0' + date.getMinutes()).slice(-2) } ${ middayDivide } ${ months[date.getMonth()] } ${ date.getDate() }, ${ date.getFullYear() }`;
	}

	public static prettyFileSize(bytes : number) : string {
		return bytes >= 1000000 ? (bytes / 1000000).toFixed(1) + 'MB' : (bytes / 1000).toFixed(0) + 'KB';
	}

	public static encodeHTML(input : string, encodeEverything : boolean = false, allowUnsafeSymbols : boolean = false) : string {
		return he.encode(input, {
			encodeEverything,
			allowUnsafeSymbols,
		});
	}

	public static decodeHTML(input : string) : string {
		return he.decode(input);
	}

	public static getTextFromFile(file : File | Blob) : Promise<string> {
		return HelperService.readFile(file, 'Text');
	}

	public static getDataUrlFromFile(file : File | Blob) : Promise<string> {
		return HelperService.readFile(file, 'DataURL');
	}

	public static async getBlobFromUrl(url : string, useCorsProxy : boolean = false) : Promise<Blob> {
		let init : any = {};

		if (useCorsProxy || HelperService.regex.noCorsCdn.test(url)) {
			url = `https://${ corsProxy.proxyDomain }/${ url }`;

			const headers = new Headers();

			headers.append(corsProxy.proxyRequestHeader, 'true');

			init = { headers };
		}

		let response : Response;

		try {
			response = await fetch(url, init);
		} catch (error) {
			console.error(error);

			return;
		}

		if (!(response.status >= 200 && response.status < 300)) {
			console.error(response);

			return;
		}

		return await response.blob();
	}

	public static async getDataUrlFromUrl(url : string) : Promise<string> {
		let	blob : Blob;

		try {
			blob = await HelperService.getBlobFromUrl(url);
		} catch (error) {
			console.error(error);

			return;
		}

		let dataUrl : string;

		try {
			dataUrl = await HelperService.getDataUrlFromFile(blob);
		} catch (error) {
			console.error(error);

			return;
		}

		return dataUrl;
	}

	private static readFile(file : File | Blob, output : 'DataURL' | 'Text') : Promise<string> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader(),
				method = `readAs${ output }`;

			reader.onload = (event : any) => resolve(event.target.result);

			reader.onerror = reject;

			reader[method](file);
		});
	}

	public static getTagPrefixAndRegex(prefix : string) : { prefix : string, regex : RegExp } {
		prefix = `${ prefix }:`;

		return {
			prefix,
			regex : new RegExp(`^${ prefix }`),
		};
	}

	public static getFileFromBlob(blob : Blob, filename : string) : File {
		// sanitize filename, in case it's a full url or contains a file extension
		filename = Helper.parsePath(filename).filename;

		const filetype : string = blob.type.split('/')[1],
			name : string = `${ filename }.${ filetype }`;

		return new File([ blob ], name, { type : blob.type });
	}
}
