import { NewBasicProductFieldGrouping } from '../types/new-basic-product-input-fields';

import { CategoryOptions, SizeOptions, Colors, GetMaterialOptionsByDepartment, Eras, DressSkirtLengths } from './product-options.model';


export function NewBasicProductFields() : NewBasicProductFieldGrouping {
	return {
		order : [
			'main',
			'optional',
		],
		groups : {
			main : {
				key   : 'main',
				title : 'Required Info',
				order : [
					'images',
					'category',
					'subcategory',
					'dressSkirtLength',
					'title',
					'price',
					'size',
					'descriptionHtml',
				],
				fields : {
					images : {
						key          : 'images',
						title        : 'Images',
						inputType    : 'image',
						deletable    : true,
						inputOptions : {
							multiple  : true,
							orderable : true,
						},
					},
					category : {
						key          : 'category',
						title        : 'Category',
						inputType    : 'select',
						inputOptions : {
							options  : CategoryOptions(),
							multiple : false,
						},
					},
					subcategory : {
						key          : 'subcategory',
						title        : 'Subcategory',
						inputType    : 'select',
						readOnly     : true,
						deletable    : true,
						inputOptions : {
							options  : [],
							multiple : false,
						},
					},
					dressSkirtLength : {
						key          : 'dressSkirtLength',
						title        : 'Dress/Skirt Length',
						inputType    : 'select',
						deletable    : true,
						hidden       : true,
						inputOptions : {
							options  : DressSkirtLengths(),
							multiple : false,
						},
					},
					title : {
						key       : 'title',
						title     : 'Title',
						inputType : 'text',
					},
					price : {
						key          : 'price',
						title        : 'Price',
						inputType    : 'number',
						inputOptions : {
							decimalPlaces : 2,
						},
					},
					size : {
						key          : 'size',
						title        : 'Size/s',
						description  : 'Select up to 2 consecutive sizes',
						inputType    : 'select',
						inputOptions : {
							options  : SizeOptions(),
							multiple : true,
						},
					},
					descriptionHtml : {
						key       : 'descriptionHtml',
						title     : 'Description',
						inputType : 'textarea',
					},
				},
			},
			optional : {
				key   : 'optional',
				title : 'Optional Info',
				order : [
					'brand',
					'era',
					'color',
					'material',
				],
				fields : {
					brand : {
						key       : 'brand',
						title     : 'Brand (leave blank if unknown)',
						inputType : 'text',
					},
					era : {
						key          : 'era',
						title        : 'Era/s',
						description  : 'Select up to 2 consecutive eras',
						inputType    : 'select',
						inputOptions : {
							options  : Eras(),
							multiple : true,
						},
					},
					color : {
						key          : 'color',
						title        : 'Color/s',
						description  : 'Select up to 3 colors',
						inputType    : 'select',
						inputOptions : {
							options  : Colors(),
							multiple : true,
						},
					},
					material : {
						key          : 'material',
						title        : 'Material/s',
						description  : 'Select up to 3 materials',
						inputType    : 'select',
						inputOptions : {
							options  : GetMaterialOptionsByDepartment(),
							multiple : true,
						},
					},
				},
			},
			hidden : {
				key   : 'hidden',
				title : 'Hidden Fields',
				order : [
					'vendor',
					'displaySize',
					'displayColor',
					'displayMaterial',
					'tags',
				],
				fields : {
					vendor : {
						key          : 'vendor',
						title        : 'Store',
						inputType    : 'select',
						inputOptions : {
							options  : [],
							multiple : false,
						},
					},
					displaySize : {
						key       : 'displaySize',
						title     : 'Display Size',
						inputType : 'text',
					},
					displayColor : {
						key       : 'displayColor',
						title     : 'Display Color',
						inputType : 'text',
					},
					displayMaterial : {
						key       : 'displayMaterial',
						title     : 'Display Material',
						inputType : 'text',
					},
					tags : {
						key        : 'tags',
						title      : 'Tags',
						inputClass : 'tag',
						inputType  : 'text',
					},
				},
			},
		},
	};
}
