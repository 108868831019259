import { Injectable, OnDestroy } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Plugins, NetworkStatus, PluginListenerHandle } from '@capacitor/core';
import { BehaviorSubject } from 'rxjs';

const { Network } = Plugins;


@Injectable({
	providedIn : 'root',
})
export class ContextService implements OnDestroy {
	private connectionListener : PluginListenerHandle;
	private connectionInterval;

	ready      : () => Promise<string>;
	isHybrid   : boolean; // native installed
	isPWA      : boolean;
	isMobile   : boolean;
	isTablet   : boolean;
	isDesktop  : boolean;
	isIos      : boolean;
	isAndroid  : boolean;
	connected$ : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);


	constructor(
		private platform : Platform,
	) {
		this.initPlatform();
		this.initNetwork();
	}


	ngOnDestroy() : void {
		this.connectionListener.remove();

		clearInterval(this.connectionInterval);
	}

	private initPlatform() : void {
		this.ready = this.platform.ready;

		this.isHybrid  = this.platform.is('hybrid');
		this.isPWA     = this.platform.is('pwa');
		this.isMobile  = this.platform.is('mobile');
		this.isTablet  = this.platform.is('tablet');
		this.isDesktop = this.platform.is('desktop');
		this.isIos     = this.platform.is('ios');
		this.isAndroid = this.platform.is('android');
	}

	private async initNetwork() : Promise<void> {
		this.updateConnectionStatus();

		this.connectionInterval = setInterval(this.updateConnectionStatus.bind(this), 15000);

		this.connectionListener = Network.addListener('networkStatusChange', this.updateConnectionStatus.bind(this));
	}

	private async updateConnectionStatus(networkStatus? : NetworkStatus) : Promise<void> {
		if (networkStatus == null)
			networkStatus = await Network.getStatus();

		this.connected$.next(networkStatus.connected);
	}
}
