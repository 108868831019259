import * as ExportedWeights from './exported-data/weights_2022-04-27T11_13_21.json';


function getWeightMap() : any {
	return (ExportedWeights as any)?.default;
}

// return product weight in ounces based on supplied category
// default to 12 ounces
export function GetWeightByCategory(category : string) : number {
	return getWeightMap()[category] ?? 12;
}
