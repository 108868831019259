import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ToastController } from '@ionic/angular';
import _ from 'lodash';

import { HelperService as Helper } from '../../../services/helper/helper.service';
import { TagInput } from '../../../types/input-fields';


@Component({
	selector    : 'app-tag-class-select-input',
	templateUrl : './tag-class-select-input.component.html',
	styleUrls   : ['./tag-class-select-input.component.scss'],
})
export class TagClassSelectInputComponent implements OnInit, OnChanges {
	@Input() field   : TagInput;
	@Input() loading : boolean;

	@Output() save = new EventEmitter<string[]>();

	newValue : string | string[];


	constructor(
		private toast : ToastController,
	) {}


	ngOnInit() : void {
		this.newValue = Helper.deepCopy(this.field.value);
	}

	ngOnChanges(changes : SimpleChanges) : void{
		if (changes.field && changes.field.firstChange === false) {
			const currentValue = changes.field.currentValue.value;

			if (currentValue !== changes.field.previousValue.value) {
				this.newValue = Helper.deepCopy(currentValue);
			}
		}
	}

	private async showToast(message : string, isError : boolean = false, isWarning : boolean = false) {
		const toast = await this.toast.create({
			message,
			duration : 3000,
			color    : isError ? 'danger' : isWarning ? 'warning' : 'dark',
		});

		toast.present();
	}

	onSubmit() : void {
		this.save.emit(this.newValue as string[]);
	}

	private nonDeleteableField() : void {
		this.newValue = Helper.deepCopy(this.field.value);

		this.showToast('You must select at least 1 option', null, true);
	}

	onChange() : void {
		if (!Array.isArray(this.newValue))
			this.newValue = Helper.listify(this.newValue);
	}

	showSave() : boolean {
		return !this.isEqual(this.field.value, this.newValue);
	}

	private isEqual(sourceArr : string[], newArr : string | string[]) : boolean {
		if (!(Array.isArray(sourceArr) && Array.isArray(newArr)))
			return false;

		return _.isEqual(sourceArr.sort(), (newArr as string[]).sort());
	}

	showReset() : boolean {
		return !this.field.inputOptions.multiple && this.field.deletable === true && !Helper.isEmpty(this.newValue);
	}
}
