/**
 * Slots are a feature of web components that are used to make the components more dynamic. 
 * It allows additional content/elements to be supplied when using the component that can 
 * then be displayed inside of the component.
 * "end" Content is placed to the right of the toolbar text in LTR, and to the left in RTL.
 * "primary" Content is placed to the right of the toolbar text in `ios` mode, and to the far right in `md` mode.
 * "secondary" Content is placed to the left of the toolbar text in `ios` mode, and directly to the right in `md` mode.
 * "start" Content is placed to the left of the toolbar text in LTR, and to the right in RTL.
 * PS: Content is placed between the named slots if provided without a slot.
 */
export enum Slots {
    END='end',
    PRIMARY='primary',
    SECONDARY='secondary',
    START='start',
}