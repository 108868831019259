import { Component, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Plugins } from '@capacitor/core';
const { SplashScreen } = Plugins;

import { LogService } from './services/log/log.service';
import { ContextService } from './services/context/context.service';


@Component({
	selector    : 'app-root',
	templateUrl : 'app.component.html',
})
export class AppComponent implements OnDestroy {
	private stop$ : Subject<boolean> = new Subject<boolean>();


	constructor(
		private router  : Router,
		private log     : LogService,
		private context : ContextService,
	) {
		this.initSplashScreen();
		this.initPageTracking();
	}


	ngOnDestroy() : void {
		this.stop$.next(true);
		this.stop$.unsubscribe();
	}

	private async initSplashScreen() : Promise<void> {
		await this.context.ready();

		if (this.context.isHybrid)
			SplashScreen.hide();
	}

	private initPageTracking() : void {
		this.router.events.pipe(
			takeUntil(this.stop$),
		).subscribe(event => {
			if (event instanceof NavigationEnd)
				this.log.pageLoad(event.urlAfterRedirects);
		});
	}
}
