import { Component, Input, Output, EventEmitter } from '@angular/core';

import { TagInput } from '../../../types/input-fields';


@Component({
	selector    : 'app-tag-class-input',
	templateUrl : './tag-class-input.component.html',
	styleUrls   : ['./tag-class-input.component.scss'],
})
export class TagClassInputComponent {
	@Input() field        : TagInput;
	@Input() loading      : boolean;
	@Input() itemCategory : string;

	@Output() save = new EventEmitter<any>();


	constructor() {}


	onSave(value : any) : void {
		this.save.emit(value);
	}
}
