import { NewDetailedProductFieldGrouping } from '../types/new-detailed-product-input-fields';

import { CategoryOptions, SizeOptions, Colors, GetMaterialOptionsByDepartment, Eras, Conditions, DressSkirtLengths } from './product-options.model';


export function NewDetailedProductFields() : NewDetailedProductFieldGrouping {
	return {
		order : [
			'main',
			'images',
			'size',
			'common',
			'condition',
			'tags',
			'description',
		],
		groups : {
			main : {
				key   : 'main',
				title : 'Main Info',
				order : [
					'vendor',
					'category',
					'subcategory',
					'dressSkirtLength',
					'title',
					'brand',
					'price',
				],
				fields : {
					vendor : {
						key          : 'vendor',
						title        : 'Store',
						inputType    : 'select',
						inputOptions : {
							options  : [],
							multiple : false,
						},
					},
					category : {
						key          : 'category',
						title        : 'Category',
						inputType    : 'select',
						inputOptions : {
							options  : CategoryOptions(),
							multiple : false,
						},
					},
					subcategory : {
						key          : 'subcategory',
						title        : 'Subcategory',
						inputType    : 'select',
						readOnly     : true,
						deletable    : true,
						inputOptions : {
							options  : [],
							multiple : false,
						},
					},
					dressSkirtLength : {
						key          : 'dressSkirtLength',
						title        : 'Dress/Skirt Length',
						inputType    : 'select',
						deletable    : true,
						hidden       : true,
						inputOptions : {
							options  : DressSkirtLengths(),
							multiple : false,
						},
					},
					title : {
						key       : 'title',
						title     : 'Title',
						inputType : 'text',
					},
					price : {
						key          : 'price',
						title        : 'Price',
						inputType    : 'number',
						inputOptions : {
							decimalPlaces : 2,
						},
					},
				},
			},
			images : {
				key   : 'images',
				title : 'Images',
				order : [
					'images',
				],
				fields : {
					images : {
						key          : 'images',
						title        : 'Images',
						inputType    : 'image',
						deletable    : true,
						inputOptions : {
							multiple  : true,
							orderable : true,
						},
					},
				},
			},
			size : {
				key   : 'size',
				title : 'Size',
				order : [
					'armLength',
					'bodyLength',
					'bootHeight',
					'braceletLength',
					'bust',
					'depth',
					'eyeToEyeLensLength',
					'faceDiameter',
					'height',
					'heightOfLens',
					'hem',
					'heelHeight',
					'hip',
					'inseam',
					'insideCircumference',
					'legOpening',
					'length',
					'lengthOfSole',
					'maxSizeLength',
					'minSizeLength',
					'overallLength',
					'pendantDiameter',
					'pendantLength',
					'rise',
					'shoulder',
					'strapLength',
					'waist',
					'width',
					'size',
					'sizeDiagram',
					'tagSize',
				],
				fields : {
					armLength : {
						key          : 'armLength',
						title        : 'Arm Length (in)',
						inputType    : 'number',
						hidden     	 : true,
						inputOptions : {
							decimalPlaces : 2,
						},
					},
					bodyLength : {
						key          : 'bodyLength',
						title        : 'Body Length (in)',
						inputType    : 'number',
						hidden     	 : true,
						inputOptions : {
							decimalPlaces : 2,
						},
					},
					bootHeight : {
						key          : 'bootHeight',
						title        : 'Boot Height (in)',
						inputType    : 'number',
						hidden     	 : true,
						inputOptions : {
							decimalPlaces : 2,
						},
					},
					braceletLength : {
						key          : 'braceletLength',
						title        : 'Bracelet Length (in)',
						inputType    : 'number',
						hidden     	 : true,
						inputOptions : {
							decimalPlaces : 2,
						},
					},
					bust : {
						key          : 'bust',
						title        : 'Bust (in)',
						inputType    : 'number',
						hidden     	 : true,
						inputOptions : {
							decimalPlaces : 2,
						},
					},
					depth : {
						key          : 'depth',
						title        : 'Depth (in)',
						inputType    : 'number',
						hidden     	 : true,
						inputOptions : {
							decimalPlaces : 2,
						},
					},
					eyeToEyeLensLength : {
						key          : 'eyeToEyeLensLength',
						title        : 'Eye to Eye Lens Length (in)',
						inputType    : 'number',
						hidden     	 : true,
						inputOptions : {
							decimalPlaces : 2,
						},
					},
					faceDiameter : {
						key          : 'faceDiameter',
						title        : 'Face Diameter (in)',
						inputType    : 'number',
						hidden     	 : true,
						inputOptions : {
							decimalPlaces : 2,
						},
					},
					height : {
						key          : 'height',
						title        : 'Height (in)',
						inputType    : 'number',
						hidden     	 : true,
						inputOptions : {
							decimalPlaces : 2,
						},
					},
					heightOfLens : {
						key          : 'heightOfLens',
						title        : 'Height of Lens (in)',
						inputType    : 'number',
						hidden     	 : true,
						inputOptions : {
							decimalPlaces : 2,
						},
					},
					hem : {
						key          : 'hem',
						title        : 'Hem (in)',
						inputType    : 'number',
						hidden     	 : true,
						inputOptions : {
							decimalPlaces : 2,
						},
					},
					heelHeight : {
						key          : 'heelHeight',
						title        : 'Heel Height (in)',
						inputType    : 'number',
						hidden     	 : true,
						inputOptions : {
							decimalPlaces : 2,
						},
					},
					hip : {
						key          : 'hip',
						title        : 'Hip (in)',
						inputType    : 'number',
						hidden     	 : true,
						inputOptions : {
							decimalPlaces : 2,
						},
					},
					inseam : {
						key          : 'inseam',
						title        : 'Inseam (in)',
						inputType    : 'number',
						hidden     	 : true,
						inputOptions : {
							decimalPlaces : 2,
						},
					},
					insideCircumference : {
						key          : 'insideCircumference',
						title        : 'Inside Circumference (in)',
						inputType    : 'number',
						hidden     	 : true,
						inputOptions : {
							decimalPlaces : 2,
						},
					},
					legOpening : {
						key          : 'legOpening',
						title        : 'Leg Opening (in)',
						inputType    : 'number',
						hidden     	 : true,
						inputOptions : {
							decimalPlaces : 2,
						},
					},
					length : {
						key          : 'length',
						title        : 'Length (in)',
						inputType    : 'number',
						hidden     	 : true,
						inputOptions : {
							decimalPlaces : 2,
						},
					},
					lengthOfSole : {
						key          : 'lengthOfSole',
						title        : 'Length Of Sole (in)',
						inputType    : 'number',
						hidden     	 : true,
						inputOptions : {
							decimalPlaces : 2,
						},
					},
					maxSizeLength : {
						key          : 'maxSizeLength',
						title        : 'Max Size Length (in)',
						inputType    : 'number',
						hidden     	 : true,
						inputOptions : {
							decimalPlaces : 2,
						},
					},
					minSizeLength : {
						key          : 'minSizeLength',
						title        : 'Min Size Length (in)',
						inputType    : 'number',
						hidden     	 : true,
						inputOptions : {
							decimalPlaces : 2,
						},
					},
					overallLength : {
						key          : 'overallLength',
						title        : 'Overall Length (in)',
						inputType    : 'number',
						hidden     	 : true,
						inputOptions : {
							decimalPlaces : 2,
						},
					},
					pendantDiameter : {
						key          : 'pendantDiameter',
						title        : 'Pendant Diameter (in)',
						inputType    : 'number',
						hidden     	 : true,
						inputOptions : {
							decimalPlaces : 2,
						},
					},
					pendantLength : {
						key          : 'pendantLength',
						title        : 'Pendant Length (in)',
						inputType    : 'number',
						hidden     	 : true,
						inputOptions : {
							decimalPlaces : 2,
						},
					},
					rise : {
						key          : 'rise',
						title        : 'Rise (in)',
						inputType    : 'number',
						hidden     	 : true,
						inputOptions : {
							decimalPlaces : 2,
						},
					},
					shoulder : {
						key          : 'shoulder',
						title        : 'Shoulder (in)',
						inputType    : 'number',
						hidden     	 : true,
						inputOptions : {
							decimalPlaces : 2,
						},
					},
					strapLength : {
						key          : 'strapLength',
						title        : 'Strap Length (in)',
						inputType    : 'number',
						hidden     	 : true,
						inputOptions : {
							decimalPlaces : 2,
						},
					},
					waist : {
						key          : 'waist',
						title        : 'Waist (in)',
						inputType    : 'number',
						hidden     	 : true,
						inputOptions : {
							decimalPlaces : 2,
						},
					},
					width : {
						key          : 'width',
						title        : 'Width (in)',
						inputType    : 'number',
						hidden     	 : true,
						inputOptions : {
							decimalPlaces : 2,
						},
					},
					size : {
						key          : 'size',
						title        : 'Size/s',
						description  : 'Select up to 2 consecutive sizes',
						inputType    : 'select',
						inputOptions : {
							options  : SizeOptions(),
							multiple : true,
						},
					},
					displaySize : {
						key       : 'displaySize',
						title     : 'Display Size',
						inputType : 'text',
					},
					sizeDiagram : {
						key          : 'sizeDiagram',
						title        : 'Size Diagram/s (paste Size.ly image link)',
						inputType    : 'list',
						inputOptions : {
							orderable : true,
							limit     : 4,
						},
					},
					tagSize : {
						key       : 'tagSize',
						title     : 'Size From Tag',
						inputType : 'text',
					},
				},
			},
			common : {
				key   : 'common',
				title : 'Common Info',
				order : [
					'brand',
					'material',
					'color',
					'era',
				],
				fields : {
					brand : {
						key       : 'brand',
						title     : 'Brand (leave blank if unknown)',
						inputType : 'text',
					},
					era : {
						key          : 'era',
						title        : 'Era/s',
						description  : 'Select up to 2 consecutive eras',
						inputType    : 'select',
						inputOptions : {
							options  : Eras(),
							multiple : true,
						},
					},
					color : {
						key          : 'color',
						title        : 'Color/s',
						description  : 'Select up to 3 colors',
						inputType    : 'select',
						inputOptions : {
							options  : Colors(),
							multiple : true,
						},
					},
					displayColor : {
						key       : 'displayColor',
						title     : 'Display Color',
						inputType : 'text',
					},
					material : {
						key          : 'material',
						title        : 'Material/s',
						description  : 'Select up to 3 materials',
						inputType    : 'select',
						inputOptions : {
							options  : GetMaterialOptionsByDepartment(),
							multiple : true,
						},
					},
					displayMaterial : {
						key       : 'displayMaterial',
						title     : 'Display Material',
						inputType : 'text',
					},
				},
			},
			condition : {
				key   : 'condition',
				title : 'Condition',
				order : [
					'condition',
				],
				fields : {
					condition : {
						key          : 'condition',
						title        : 'Condition',
						inputType    : 'select',
						inputOptions : {
							options  : Conditions(),
							multiple : false,
						},
					},
				},
			},
			description : {
				key   : 'description',
				title : 'Description',
				order : [
					'descriptionHtml',
				],
				fields : {
					descriptionHtml : {
						key       : 'descriptionHtml',
						title     : 'Description',
						inputType : 'textarea',
					},
				},
			},
			tags : {
				key   : 'tags',
				title : 'Tags',
				order : [
					'tags',
				],
				fields : {
					tags : {
						key        : 'tags',
						title      : 'Tags',
						inputClass : 'tag',
						inputType  : 'text',
					},
				},
			},
		},
	};
}
