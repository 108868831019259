import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { take } from 'rxjs/operators';

import { LogService } from '../../services/log/log.service';
import { HelperService as Helper } from '../../services/helper/helper.service';
import { UserService } from '../../services/user/user.service';
import { UserStatus } from '../../types/user';
import { EventIssuer } from '../../constants/EventIssuer';
import { EventContext } from '../../constants/EventContext';


@Component({
	selector    : 'app-sign-in',
	templateUrl : './sign-in.page.html',
	styleUrls   : ['./sign-in.page.scss'],
})
export class SignInPage implements OnInit {
	providers: string[] = [
		'google',
		'apple',
	];

	helper = Helper;
	isValidEmail              : boolean = false;
	showPassword              : boolean = false;
	isSignIn                  : boolean;
	email                     : string;
	emailStatus               : 'warning' | 'danger';
	password                  : string;
	passwordConfirm           : string;
	passwordStatus            : 'warning' | 'danger';
	authProvider              : string;


	constructor(
		private router : Router,
		private route  : ActivatedRoute,
		private toast  : ToastController,
		private log    : LogService,
		public user    : UserService,
	) {}


	ngOnInit() : void {
		this.handleInvalidUser(
			this.route.snapshot.queryParamMap.get('invaliduser')
		);
	}

	private handleInvalidUser(isInvalid : string) : void {
		if (isInvalid === 'true')
			return this.handleError({ code : 'custom/invalid-email' });
	}

	submit() : void {
		this.emailStatus = undefined;

		if (this.isValidEmail)
			this.emailSignIn();
		else
			this.updateEmail();
	}

	private updateEmail(): void {
		if (this.email !== null && !this.email)
			this.email = null;

		if (this.email != null && typeof this.email === 'string')
			this.email = this.email.toLowerCase();

		this.queryEmail();
	}

	private queryEmail() : void {
		this.user.getStatusByEmail(this.email).pipe(
			take(1),
		)
			.subscribe(this.handleEmailQueryResults.bind(this));
	}

	private handleEmailQueryResults(userStatus : UserStatus) : void {
		if (!userStatus)
			return this.handleError({ code : 'custom/invalid-email' });

		this.isValidEmail = true;

		this.isSignIn = !!userStatus.provider;

		if (userStatus.provider !== 'password')
			this.authProvider = userStatus.provider;
	}

	private emailSignIn() : void {
		if (!this.validate())
			return;

		this.user
			.emailPasswordSignIn(this.email, this.password, this.isSignIn)
			.then(this.handleSignIn.bind(this));
	}

	private validate() : boolean {
		this.passwordStatus = undefined;

		if (!this.isSignIn && this.password !== this.passwordConfirm) {
			this.handleError({ code : 'custom/mismatched-passwords' });

			return false;
		}

		return true;
	}

	private handleSignIn(response) : void {
		if (response?.status === 'success')
			this.handleSignInSuccess();
		else
			this.handleError(response.data);
	}

	private handleSignInSuccess() : void {
		window.location.assign('/');
	}

	private handleError(error) : void {
		let msg : string = 'Uh oh! ',
			status : 'warning' | 'danger';

		switch (error.code) {
			case 'custom/mismatched-passwords':
				msg += "Passwords don't match";

				status = 'warning';

				this.passwordStatus = status;

				break;

			case 'auth/user-not-found':
			case 'custom/invalid-email':
				msg += 'Your email address was not found';

				status = 'warning';

				break;

			case 'auth/wrong-password':
				msg += 'Your password is incorrect';

				status = 'danger';

				this.passwordStatus = status;

				break;

			case 'auth/weak-password':
				msg += 'Password must be at least 6 characters';

				status = 'danger';

				this.passwordStatus = status;

				break;

			case 'auth/network-request-failed':
				msg += 'Please check your internet connection';

				status = 'warning';

				break;

			case 'auth/too-many-requests':
				msg += 'We have detected too many requests from your device. Take a break please!';

				status = 'warning';

			default:
				msg += 'An unknown error occurred';

				status = 'danger';
		}

		this.log.error(EventIssuer.SignInPage, {
			subject      : error.code,
			eventContext : EventContext.UserLogin,
			details      : {
				error,
				message : msg,
			},
		});

		this.showToast(msg, status === 'danger', status === 'warning');
	}

	private async showToast(
		message   : string,
		isError   : boolean = false,
		isWarning : boolean = false,
	) {
		const toast = await this.toast.create({
			message,
			duration : 5000,
			color    : isError ? 'danger' : isWarning ? 'warning' : 'dark',
		});

		toast.present();
	}

	disableSubmit() : boolean {
		if (!this.isValidEmail)
			return !this.email;
		else if (this.isSignIn)
			return !this.password;
		else
			return !(this.password && this.passwordConfirm);
	}

	getSubmitText() : string {
		return this.isValidEmail ? this.isSignIn ? 'Sign In' : 'Create Account' : 'Continue with Email';
	}

	getProvider(provider : string) : string {
		const providerMap: any = {
			'google.com' : 'Google',
			'apple.com'  : 'Apple',
		};

		return providerMap[provider];
	}

	forgotPasswordClicked() : void {
		this.router.navigate([ 'forgot-password' ], {
			state : {
				email : this.email,
			},
		});
	}
}
