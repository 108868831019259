import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { AnyMap } from '../../types/basics';


export type ModalOptions = {
	innerModalComponent : any;
	innerModalProps?    : AnyMap;
	size?               : 'full' | 'half' | 'two-thirds';
	dismissable?        : boolean;
};


@Component({
	selector    : 'app-modal',
	templateUrl : './modal.component.html',
	styleUrls   : ['./modal.component.scss'],
})

export class ModalComponent {
	private modal;
	private defaults : ModalOptions = {
		innerModalComponent : null,
		innerModalProps     : {},
		size                : 'full',
		dismissable         : true,
	};


	constructor(
		public modalController : ModalController,
	) {}


	async create(options : ModalOptions) : Promise<this> {
		options = {
			...this.defaults,
			...options,
		};

		this.modal = await this.modalController.create({
			component       : options.innerModalComponent,
			cssClass        : 'app-modal app-modal--' + options.size,
			backdropDismiss : options.dismissable,
			swipeToClose    : options.dismissable,
			componentProps  : {
				...options.innerModalProps,
				dismiss : this.dismiss,
			},
		});

		return this;
	}

	present() : Promise<void> {
		return this.modal.present();
	}

	dismiss() : void {
		this.modal.dismiss();
	}

	async willDismiss() : Promise<void> {
		await this.modal.onWillDismiss();

		return;
	}

	async didDismiss() : Promise<void> {
		await this.modal.onDidDismiss();

		return;
	}
}
