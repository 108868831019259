import { Component } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { DownloadService } from '../../../../services/download/download.service';
import { ZoomImageModalComponent } from '../zoom-image-modal.component';
import { ZoomMenuOption } from '../../zoom-image.component';


@Component({
	selector    : 'app-zoom-image-menu-popover',
	templateUrl : './zoom-image-menu-popover.component.html',
	styleUrls   : ['./zoom-image-menu-popover.component.scss'],
})
export class ZoomImageMenuPopoverComponent {
	parent  : ZoomImageModalComponent;
	buttons : ZoomMenuOption[] = [
		{
			title   : 'Download',
			icon    : 'cloud-download',
			handler : this.downloadImg.bind(this),
		},
	];


	constructor(
		private navParams : NavParams,
		private download  : DownloadService,
	) {
		this.parent = navParams.get('parent');

		this.initButtons();
	}


	private initButtons() : void {
		this.buttons.push(...(this.parent.parent.menuOptions ?? []));
	}

	onClick(event : any, menuOption : ZoomMenuOption) : void {
		event.stopPropagation();

		this.parent.popover.dismiss();

		if (menuOption.dismissModal) {
			this.parent.parent.modal.dismiss();
		}

		menuOption.handler(this.parent.parent.src, this.parent.parent.meta);
	}

	downloadImg() : void {
		this.download.saveFile(
			this.parent.parent.src,
			this.parent.parent.helper.getFilename(this.parent.parent.src),
		);
	}
}
