import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ShopifyAdminService } from '../../modules/shopify-admin/shopify-admin.service';


@Injectable({
	providedIn : 'root',
})
export class OrderService {
	constructor(
		private shopifyGraphql : ShopifyAdminService,
	) {}


	/*
	 * Read Methods - new
	 * pull from Firestore
	 */


	/*
	 * Read Methods - old
	 * pull from Shopify
	 */
	getByIdFromShopify(id : string) : Observable<any> {
		return this.shopifyGraphql.getOrderById(id).pipe(
			// tap(data => console.debug(data)),
			map(data => data?.data?.order),
			// tap(data => console.debug(data)),
		);
	}


	/*
	 * Write Methods
	 * push to Firestore
	 */


	/*
	 * Write Methods
	 * push to Shopify & Firestore
	 */


	/*
	 * Write Methods
	 * push to Shopify
	 */
}
