import {
	Component,
	OnInit,
	OnDestroy,
	OnChanges,
	Input,
	Output,
	EventEmitter,
	SimpleChanges,
} from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { HelperService as Helper } from '../../../services/helper/helper.service';
import { NumberMap } from '../../../types/basics';
import { InputField, TagInput } from '../../../types/input-fields';
import { Tag, TagMap, TagCategories } from '../../../types/tag';
import { GetTagMap, GetTagCategories } from '../../../models/tags.model';


type TagGroup =
	| 'currentTags'
	| 'newTags'
	;


@Component({
	selector    : 'app-tag-input',
	templateUrl : './tag-input.component.html',
	styleUrls   : ['./tag-input.component.scss'],
})
export class TagInputComponent implements OnInit, OnDestroy, OnChanges {
	@Input() field        : InputField | TagInput;
	@Input() itemCategory : string;
	@Input() loading      : boolean;
	@Input() saveHidden   : boolean;
	@Input() didSave$     : Subject<any>;

	@Output() valueChange = new EventEmitter<string[]>();
	@Output() save        = new EventEmitter<string[]>();

	private stop$ : Subject<boolean> = new Subject<boolean>();

	currentTags            : string[];
	currentTagCount        : number;
	newTags                : string[] = [];
	tagMap                 : TagMap;
	availableTagCategories : TagCategories;
	visibleTagCategories   : TagCategories;
	activeCategoryCount    : NumberMap;
	query                  : string;


	constructor(
		private toast : ToastController,
	) {}


	ngOnInit() : void {
		this.getTagMapAndCategories();

		this.updateCurrentTags(Helper.deepCopy(this.field.value));

		this.initPostSave();
	}

	ngOnDestroy() : void {
		this.stop$.next(true);
		this.stop$.unsubscribe();
	}

	ngOnChanges(changes : SimpleChanges) : void {
		if (this.hasChanged(changes, 'field', ['value']))
			this.updateCurrentTags(Helper.deepCopy(changes.field.currentValue.value));

		if (this.hasChanged(changes, 'itemCategory')) {
			this.getTagMapAndCategories();

			this.updateActiveTags();
		}
	}

	private hasChanged(changes : any, key : string, pathArr? : string[]) : boolean {
		const item = changes[key];

		if (!(item && item.firstChange === false))
			return false;

		const current : any = pathArr ? Helper.get(item.currentValue, pathArr) : item.currentValue,
			previous : any = pathArr ? Helper.get(item.previousValue, pathArr) : item.previousValue;

		return current !== previous;
	}

	private getTagMapAndCategories() : void {
		this.tagMap = GetTagMap();

		this.availableTagCategories = GetTagCategories(this.itemCategory);

		this.resetVisibleCategories();
	}

	private resetVisibleCategories() : void {
		this.visibleTagCategories = Helper.deepCopy(this.availableTagCategories);
	}

	private updateCurrentTags(tags : string[]) : void {
		this.currentTags = tags || [];

		this.currentTagCount = this.currentTags.length;

		this.updateActiveTags();
	}

	private updateActiveTags() : void {
		this.activeCategoryCount = {};

		for (const tag of this.currentTags)
			this.toggleActive(tag, true, 'currentTags');

		for (const tag of this.newTags)
			this.toggleActive(tag, true, 'newTags');
	}

	private toggleActive(tag : string, active : boolean, tagGroup : TagGroup) : void {
		const destructedTag = this.destructTag(tag),
			tagEntry : Tag = destructedTag.category && this.tagMap[destructedTag.category] && this.tagMap[destructedTag.category].tags[destructedTag.value];

		if (tagEntry) {
			tagEntry[ tagGroup === 'currentTags' ? 'saved' : 'chosen' ] = active;

			this.updateCategoryCount(destructedTag.category, active);
		}
	}

	private updateCategoryCount(category : string, add : boolean) : void {
		if (this.activeCategoryCount[category] == null)
			this.activeCategoryCount[category] = 0;

		if (add)
			this.activeCategoryCount[category]++;
		else
			this.activeCategoryCount[category]--;
	}

	private initPostSave() : void {
		if (this.didSave$ != null) {
			this.didSave$.pipe(
				takeUntil(this.stop$),
			)
				.subscribe(this.postSave.bind(this));
		}
	}

	private postSave(value) : void {
		if (value !== false)
			this.newTags = [];
	}

	private async showToast(message : string, isError : boolean = false, isWarning : boolean = false) {
		const toast = await this.toast.create({
			message,
			duration : 3500,
			color    : isError ? 'danger' : isWarning ? 'warning' : 'dark',
		});

		toast.present();
	}

	destructTag(tag : string) : { category : string, value : string } {
		const tagArr : string[] = tag.split(/:/),
			value : string = tagArr[1] ? tagArr[1] : tagArr[0],
			category : string = tagArr[1] ? tagArr[0] : null;

		return {
			category,
			value,
		};
	}

	sortValues(map : any) : any[] {
		return Object.values(map).sort((a : any, b : any) => {
			const A = a.value.toLowerCase(),
				B = b.value.toLowerCase();

			if (A < B) 
				return -1;
			else if (A > B) 
				return 1;
			

			// values must be equal
			return 0;
		});
	}

	addTag(tag : Tag) : void {
		if (tag.saved || tag.chosen) {
			this.showToast('This tag has already been added', false, true);

			return;
		}

		this.newTags.unshift(tag.structuredValue);

		this.toggleActive(tag.structuredValue, true, 'newTags');

		this.onChange();
	}

	removeTag(tagGroup : TagGroup, indexOrName : number | string, event? : any) : void {
		if (event != null)
			event.stopPropagation();

		let index : number;

		if (typeof indexOrName === 'number')
			index = indexOrName;
		else
			index = this[tagGroup].indexOf(indexOrName);

		if (index === -1)
			return;

		const deletedArr = this[tagGroup].splice(index, 1);

		this.toggleActive(deletedArr[0], false, tagGroup);

		this.onChange();
	}

	onChange() : void {
		this.valueChange.emit(this.currentTags.concat(this.newTags));
	}

	saveNewValue() : void {
		this.save.emit(this.currentTags.concat(this.newTags));
	}

	updateFilteredTags(event : any): void {
		this.query = event.target.value.toLowerCase().trim();

		if (!this.query)
			return this.resetVisibleCategories();

		for (const group in this.availableTagCategories) {
			this.visibleTagCategories[group] = [];

			for (const categoryKey of this.availableTagCategories[group]) {
				const category = this.tagMap[categoryKey];
				let matchCount : number = 0;

				for (const tagKey in category.tags) {
					const tag = category.tags[tagKey];

					tag['hidden'] = !tag.value.toLowerCase().includes(this.query);

					if (!tag.hidden)
						matchCount++;
				}

				if (matchCount)
					this.visibleTagCategories[group].push(categoryKey);
			}
		}
	}
}
