import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import platform from 'platform';

import { HelperService as Helper } from '../../services/helper/helper.service';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { Log, OptionalLogData, LogEvent } from '../../types/log';
import { FirestoreQuery } from '../../types/firestore';
import { EventIssuer } from '../../constants/EventIssuer';
import { environment } from '../../../environments/environment';
import { version } from '../../../environments/version';


declare global {
  interface Window {
    dataLayer : any[];
  }
}

type GTagKey =
	| 'js'
	| 'config'
	| 'event'
	;

type GTagOpts = {
	eventCategory? : string;
	eventLabel?    : string;
	pagePath?      : string;
};


@Injectable({
	providedIn : 'root',
})
export class LogService {
	private logsRef      : AngularFirestoreCollection<Log>;
	private consoleTitle : string[] = [
		'%cLogService',
		'color: #1437cf; font-family:monospace; font-size: 14px',
	];


	constructor(
		private afAuth : AngularFireAuth,
		private firestore : FirestoreService,
	) {
		window.dataLayer = window.dataLayer || [];

		this.logsRef = this.firestore.getRef('logs');

		if (this.canSquawk())
			console.info(...this.consoleTitle, 'printing to console');
	}


	/*
	 * read methods
	 */
	getByRef(ref : string, returnFirst : boolean = true) : Observable<Log | Log[]> {
		return this.queryFirestore({ where : { ref } }).pipe(
			map(data => returnFirst
				? Helper.first(data) as Log
				: data as Log[]
			),
		);
	}

	private queryFirestore(queries : FirestoreQuery | FirestoreQuery[]) : Observable<Log[]> {
		return this.firestore.query('logs', queries);
	}

	getByISODate(dateString : string, limit : number = 100, precedingSeconds? : number, followingSeconds? : number) : Observable<Log[]> {
		let starting : string = dateString,
			ending : string = dateString;

		if (precedingSeconds || followingSeconds) {
			const date = new Date(dateString);

			if (precedingSeconds)
				starting = new Date(date.getTime() + precedingSeconds * -1000).toISOString();

			if (followingSeconds)
				ending = new Date(date.getTime() + followingSeconds * 1000).toISOString();
		}

		const queries : FirestoreQuery[] = [
			{
				where : {
					FilterOp  : '>=',
					createdAt : starting,
				},
			},
			{
				where : {
					FilterOp  : '<=',
					createdAt : ending,
				},
			},
			{ limit },
		];

		return this.queryFirestore(queries);
	}

	getByIssuer(issuer : string, event? : LogEvent, starting? : string, ending? : string) : Observable<Log[]> {
		const queries : FirestoreQuery[] = [{ where : { issuer } }];

		if (event)
			queries.push({ where : { event } });

		if (starting) {
			queries.push({ where : {
				FilterOp  : '>=',
				createdAt : starting,
			}});
		}

		if (ending) {
			queries.push({ where : {
				FilterOp  : '<=',
				createdAt : ending,
			}});
		}

		return this.queryFirestore(queries);
	}

	getByEvent(event : LogEvent, starting? : string, ending? : string) : Observable<Log[]> {
		const queries : FirestoreQuery[] = [{ where : { event } }];

		if (starting) {
			queries.push({ where : {
				FilterOp  : '>=',
				createdAt : starting,
			}});
		}

		if (ending) {
			queries.push({ where : {
				FilterOp  : '<=',
				createdAt : ending,
			}});
		}

		return this.queryFirestore(queries);
	}

	getBySku(sku : string) : Observable<Log[]> {
		return this.queryFirestore({ where : { sku } });
	}

	/*
	 * write methods
	 */
	create(issuer : EventIssuer, optional? : OptionalLogData) : Promise<string> {
		return this.save(issuer, 'create', optional);
	}

	update(issuer : EventIssuer, optional? : OptionalLogData) : Promise<string> {
		return this.save(issuer, 'update', optional);
	}

	delete(issuer : EventIssuer, optional? : OptionalLogData) : Promise<string> {
		return this.save(issuer, 'delete', optional);
	}

	error(issuer : EventIssuer, optional? : OptionalLogData) : Promise<string> {
		return this.save(issuer, 'error', optional);
	}

	/**
	 * When we navigate to a certain page, log it. Due to the nature of our Ionic app,
	 * GA isn't able to determine whether we've navigated to a new destination. This bit
	 * of logic helps GA log the 'page_view' event.
	 *
	 * @param pagePath Path to page.
	 */
	pageLoad(pagePath : string) : void {
		this.logToGoogleAnalytics('pageLoad', { pagePath });
	}

	/**
	 * Parameters need to be of type @type {IArguments} in order for Google Analytics (GA)
	 * to work properly.
	 *
	 * @param args - passed parameters
	 */
	private gtag(...args : [ GTagKey, Date | string | LogEvent, GTagOpts? ]) : void {
		window.dataLayer.push(arguments);
	}

	/**
	 * Log events to Google Analytics.
	 *
	 * @param eventType event type
	 * @param opts event details
	 */
	private logToGoogleAnalytics(eventType : string | 'pageLoad', opts : GTagOpts) : void {
		const isPageLoad : boolean = eventType === 'pageLoad',
			isErrorEvent   : boolean = eventType === 'error',
			eventName      : string  = isErrorEvent ? 'thrilling_error' : eventType,
			gtagKey        : GTagKey = isPageLoad ? 'config' : 'event',
			gtagValue      : string  = isPageLoad ? environment.googleAnalyticsPropertyId : eventName;

		if (isPageLoad)
			this.gtag('js', new Date());
		else if (Helper.isNullish(opts.eventCategory))
			return;

		this.gtag(gtagKey, gtagValue, opts);
	}

	/**
	 * Log an event to Google Analytics and Firestore
	 *
	 * @param issuer Where the event originated from. This is usually the name of the file where the event is triggered from.
	 * @param event The name of the event.
	 * @param optional Additional information.
	 * @returns a promise.
	 */
	private async save(
		issuer    : EventIssuer,
		event     : LogEvent,
		optional? : OptionalLogData,
	) : Promise<string> {
		// provide more context to Google Analytics events
		const eventName : string = optional?.eventContext
			? `${ event }_${ optional.eventContext }`
			: event;
		const id : string = this.firestore.createId();

		let createdBy : string;

		try {
			createdBy = await this.userId();
		} catch (error) {
			console.error(error);
		}

		const log : Log = {
			ref       : Helper.createId(),
			createdAt : new Date().toISOString(),
			platform  : platform.description,
			url       : window.location.href,
			event  	  : eventName,
			id,
			createdBy,
			version,
			issuer,
			...optional,
		};

		this.logToGoogleAnalytics(eventName, {
			eventCategory : log.subject,
			eventLabel    : JSON.stringify(log.details ?? null),
		});

		if (this.canSquawk())
			this.squawk(log);

		try {
			// coerce data to JSON and back to remove undefined values and custom class objects, which firebase will choke on
			await this.logsRef.doc(id).set(Helper.deepCopy(log));
		} catch (error) {
			console.error(error);
		}

		return log.ref;
	}

	/**
	 * Retrieve user Id.
	 *
	 * @returns user Id
	 */
	private userId() : Promise<string> {
		return this.afAuth.authState.pipe(
			map(user => user?.uid ?? null),
			take(1),
		)
			.toPromise();
	}

	private canSquawk() : boolean {
		return !environment.production || /(?:print_logs|squawk)=true/.test(window.location.search);
	}

	private squawk(log : Log) : void {
		console[ log.event.includes('error') ? 'error' : 'info' ](...this.consoleTitle, log);
	}
}
